import React, { useState, useEffect } from 'react';
import { Text, StyleSheet } from 'react-native';
import { calculateRemainingTime } from './chartUtils';

const RemainingTimeDisplay = () => {
  const [remainingTimeText, setRemainingTimeText] = useState('');

  useEffect(() => {
    updateRemainingTime();
    const interval = setInterval(() => {
      updateRemainingTime();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const updateRemainingTime = () => {
    const { text } = calculateRemainingTime();
    setRemainingTimeText(text);
  };

  return (
    <Text style={styles.subtitleTimer}>{remainingTimeText}</Text>
  );
};

const styles = StyleSheet.create({
  subtitleTimer: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'Poppins_500Medium',
    textAlign: 'center',
    marginTop: 5,
  }
});

export default RemainingTimeDisplay;
