import React from 'react';
import {View,Text,TouchableOpacity,StyleSheet} from 'react-native';
import { fontFamily } from '../helpers/fontFamily';
import { Platform } from 'react-native';
import i18n from 'i18n-js';

const PassButton = ({
  onPress,
  onLongPress,
  windowWidth,
  orderItem,
}) => {
  const getItem = () => {
    if (orderItem.child_items.length > 0) {
      return orderItem.child_items[0];
    } else {
      return orderItem;
    }
  };

  const handlePress = (e) => {
    if (Platform.OS === 'web' && e && e.nativeEvent && e.nativeEvent.shiftKey) {
      onPress(getItem(), true);
    } else {
      onPress(getItem());
    }
  };

  return (
    <View
      dataSet={{ componentName: 'passButton' }}
      style={{ flex: windowWidth > 900 ? 4 : '' }}
    >
      <TouchableOpacity
        testID='passButton'
        onPress={(e) => handlePress(e) }
        onLongPress={onLongPress}
        delayLongPress={1000}
        style={windowWidth > 900 ? styles.passButton : styles.passButtonMobile}
      >
        <Text
          style={windowWidth > 900 ? styles.passButtonText : styles.passButtonTextMobile}
        >
          {i18n.t("nextItems.passButtonLabel")}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  passButton:{
    alignSelf: 'center',
    marginLeft: 5,
    backgroundColor: '#455766',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    borderRadius: 5
  },
  passButtonMobile:{
    flex: 4,
    marginLeft: 10,
    backgroundColor: '#455766',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 7,
    borderRadius: 5
  },
  passButtonText: {
    fontFamily: fontFamily.font800,
    fontSize: 30,
    paddingLeft: 1,
    paddingRight: 1,
    color: '#fff',
    textAlign: 'center'
  },
  passButtonTextMobile: {
    fontFamily: fontFamily.font800,
    fontSize: 26,
    color: '#fff',
    textAlign: 'center'
  }

})
export default PassButton;
