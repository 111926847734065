import {Dimensions, StyleSheet} from 'react-native';
import {fontFamily} from '../helpers/fontFamily';
const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  mobileLogView: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  nextItemsMain: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 0,
    width: '100%'
  },
  RFPScreen: {
    flex: 1,
    width: '100%',
    textAlign: 'center',
    height: '100%'
  },
  nowScanningMainView: {
    padding: 5,
    backgroundColor: '#252525',
    display: 'flex',
    alignItems: 'center'
  },
  nowScanningTextView: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 20
  },
  actionButtonsView: {
    backgroundColor: '#292929',
    borderStyle: 'solid',
    borderColor: '#d3dcf3'
  },
  actionButtonInnerView: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actionButtonText: {
    fontSize: 13,
    color: '#fff',
    textShadowColor: '#fff',
    textShadowOffset: {width: 1, height: 1},
    textShadowRadius: 4
  },
  actionButtonTextDesktop: {
    fontFamily: fontFamily.font400,
    fontSize: 20,
    lineHeight: '50%',
    marginTop: 10
  },
  actionButtonTextMobile: {
    fontFamily: fontFamily.font500,
    lineHeight: '100%',
    color: '#fff'
  },
  actionButtonLabelDesktop: {
    fontFamily: fontFamily.font400,
    fontSize: 20
  },
  actionImages: {
    position: 'relative',
    width: 30,
    height: 30
  },
  restartButtonDesign: {
    alignItems: 'center',
    marginRight: 15
  },
  noteSaveButtonDesign: {
    alignItems: 'center'
  },
  orderItemNameView: {
    padding: 5,
    backgroundColor: '#ccc',
    display: 'flex',
    borderTopWidth: 5,
    borderStyle: 'solid',
    borderColor: '#aeaeae'
  },
  orderItemNameText: {
    fontWeight: 'bold',
    fontSize: 16
  },
  orderItemSKUView: {
    padding: 5,
    backgroundColor: '#3d566d',
    borderTopWidth: 5,
    borderStyle: 'solid',
    borderColor: '#7da8d0'
  },
  orderItemSKUText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16
  },
  orderItemBarcodeView: {
    padding: 5,
    backgroundColor: '#a6b1cb',
    borderTopWidth: 5,
    borderStyle: 'solid',
    borderColor: '#7f899f'
  },
  orderItemBarcodeText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 16
  },
  customerProductMainView: {
    padding: 5,
    backgroundColor: '#c4d1f0',
    borderTopWidth: 5,
    borderStyle: 'solid',
    borderColor: '#7f899f'
  },
  customProductText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 15
  },
  serialNumberNeeded: {
    padding: 5,
    backgroundColor: '#c1c9db',
    borderBottomWidth: 5,
    borderStyle: 'solid',
    borderColor: '#3f7ebd'
  },
  serialNumberNeededText: {
    color: '#000',
    fontSize: 16,
    textAlign: 'left'
  },
  sameOrderAlertTopView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start'
  },
  sameOrderAlertLinear: {
    backgroundColor: '#707070',
    padding: 5,
    borderRadius: 12,
    position: 'relative',
    border: '1px solid #D7D7D7',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  attentionIcon: {
    color: 'orange',
    fontSize: '36px',
    position: 'static'
  },
  alertInsideText: {
    color: 'white',
    fontFamily: 'Poppins_300Light',
    color: '#ffffff',
    fontSize: '20px',
    alignItems: 'center',
    width: '100%'
  },
  alertInsideTextMobile: {
    color: 'white',
    fontFamily: 'Poppins_300Light',
    color: '#ffffff',
    fontSize: '16px',
    lineHeight: '18.24px',
    alignItems: 'center',
    width: '100%'
  },
  alertBoxOutsideText: {
    fontFamily: 'Poppins_300Light',
    color: '#ffffff',
    lineHeight: '36px',
    fontSize: '24px',
    alignItems: 'center'
  },
  alertBoxOutsideTextMobile: {
    fontFamily: 'Poppins_300Light',
    color: '#ffffff',
    lineHeight: '20.7px',
    fontSize: '18px',
    alignItems: 'center',
    marginBottom: '5px'
  },
  displayLocationMainView: {
    padding: 5,
    backgroundColor: '#1c1c1c',
    borderTopWidth: 5,
    borderStyle: 'solid',
    borderColor: '#7f899f'
  },
  displayLocationText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 15
  },
  scanningInputButtonBarMainView: {
    backgroundColor: '#414141',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  scanningInputButtonBarGreenButton: {
    height: 10,
    width: 10,
    backgroundColor: '#cafe33',
    borderRadius: 30,
    marginRight: 10
  },
  scanGreaterThan375: {
    textAlign: 'center',
    fontSize: 14
  },
  scanLessThan375: {
    textAlign: 'center',
    fontSize: 10
  },
  qtyMoreToScan: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    width: 'auto',
    paddingLeft: 5,
    paddingRight: 5,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    bottom: 65,
    fontSize: 20,
    textAlign: 'center',
    fontWeight: '500',
    color: '#000'
  },
  qtyMoreToScanSecond: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    width: 'auto',
    paddingLeft: 5,
    paddingRight: 5,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    bottom: 0,
    fontSize: 20,
    textAlign: 'center',
    fontWeight: '500',
    color: '#000'
  },
  textX: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#737373'
  },
  textXTIMESMainView: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    bottom: 79,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
  },
  textTimes: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#737373'
  },
  qtyRemainText: {
    fontSize: 50,
    fontWeight: 'bold',
    display: 'flex',
    color: '#748755'
  },
  instructionContainerSecond: {
    borderTopWidth: 2,
    borderStyle: 'solid',
    borderColor: '#fff',
    position: 'absolute'
  },
  instructionContainerThree: {
    borderTopWidth: 2,
    borderStyle: 'solid',
    borderColor: '#fff'
  },
  //Next Item End//
  inputBox: {
    padding: 10,
    flex: 6,
    lineHeight: 20,
    margin: 10,
    borderWidth: 1,
    borderRadius: 30
  },
  inputBoxTracking: {
    padding: 10,
    flex: 6,
    lineHeight: 20,
    margin: 10,
    borderRadius: 8
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10
  },
  text: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    paddingRight: 10,
    paddingLeft: 10
  },
  internalNoteBar: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: '100%',
    padding: 8,
    marginTop: 3,
    marginBottom: 3
  },
  internalNoteText: {
    flex: 8,
    textAlign: 'center',
    color: 'white',
    fontSize: 15,
    fontWeight: 'bold',
    alignItems: 'center'
  },
  closeButton: {
    flex: 2,
    textAlign: 'center',
    color: 'black',
    backgroundColor: 'white',
    borderRadius: 30,
    fontSize: 12,
    fontWeight: 'bold',
    padding: 3
  },
  alertContainer: {
    flex: 1,
    position: 'absolute',
    top: 0,
    zIndex: 10,
    backgroundColor: 'rgba(14, 14 , 14 , 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  alertBox: {
    backgroundColor: 'grey',
    opacity: 1,
    width: '80%',
    padding: 20,
    borderRadius: 30
  },
  alertText: {
    flex: 8,
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 20
  },
  alertClose: {
    flex: 2,
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20
  },
  alertInput: {
    borderWidth: 2,
    borderColor: '#000',
    borderRadius: 30,
    height: '75%',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    color: '#000',
    margin: 10,
    padding: 5
  },
  alertSubmitBox: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black'
  },
  alertSubmitBtn: {
    fontWeight: 'bold',
    fontSize: 15,
    padding: 10,
    textAlign: 'center',
    color: 'black'
  },
  logContainer: {
    width: '95%',
    paddingTop: 15,
    height: '100%'
  },
  logBox: {
    margin: 10,
    height: '100%'
  },
  logHeaderText: {
    textAlign: 'center',
    padding: 5,
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white'
  },
  logIndex: {
    flex: 2,
    margin: 2,
    textAlign: 'center'
  },
  actionBox: {
    flex: 19,
    margin: 2
  },
  logDate: {
    paddingBottom: 5,
    marginRight: 4,
    marginRight: 4
  },
  logAction: {
    flexWrap: 'wrap',
    flex: 1
  },
  nextItemContainer: {
    width: '100%'
  },
  nextProductText: {
    textAlign: 'center',
    padding: 10,
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white'
  },
  iframe: {
    height: 100,
    width: '100%'
  },
  itemInfo: {
    borderWidth: 1,
    margin: 2
  },
  blueBox: {
    backgroundColor: '#d9edf7',
    borderColor: '#bce8f1',
    borderWidth: 1,
    padding: 3
  },
  blueText: {
    textAlign: 'center',
    color: '#31708f',
    fontWeight: 'bold'
  },
  unscannedItemName: {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold'
  },
  skuBox: {
    flex: 7,
    alignItems: 'flex-start',
    margin: 10
  },
  skuTextBox: {
    padding: 10,
    minWidth: 100,
    textAlign: 'center'
  },
  skuText: {
    fontWeight: 'bold'
  },
  unscannedItemQty: {
    flex: 3,
    alignItems: 'flex-end',
    margin: 10
  },
  imageContainer: {
    paddingTop: 20,
    width: '100%',
    alignItems: 'center'
  },
  imageContainerSectionIpad: {
    marginBottom: 165,
    marginTop: 165
  },
  imageContainerSectionIphone678: {
    marginBottom: -5,
    marginTop: -35
  },
  imageContainerSection823: {
    marginTop: 65,
    marginBottom: 65
  },
  imageContainerSection812: {
    marginTop: 50,
    marginBottom: 50
  },
  imageContainerSection568: {
    marginTop: -50,
    marginBottom: 0
  },
  imageContainerSection736: {
    marginTop: 20,
    marginBottom: 22
  },
  imageContainerSection640: {
    marginTop: -40,
    marginBottom: -20
  },
  paginationBoxStyle: {
    position: 'relative',
    bottom: 0,
    padding: 0,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    paddingVertical: 0
  },
  dotStyle: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 0,
    padding: 0,
    margin: 0,
    backgroundColor: 'rgba(128, 128, 128, 0.92)'
  },
  ImageComponentStyle: {
    borderRadius: 15,
    alignItems: 'center',
    alignSelf: 'center',
    padding: 20,
    justifyContent: 'center',
    width: '80%',
    marginTop: 5
  },
  instructionContainer: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'rgba(28, 43, 116, 0.7)',
    width: '100%',
    height: 'auto',
    maxHeight: 300,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  },
  instructionContainerWeb: {
    position: 'relative',
    zIndex: 10,
    backgroundColor: '#d61612',
    width: '100%',
    height: 'auto',
    maxHeight: 300,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  },
  instructionBox: {
    width: '100%'
  },
  textBox: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 2,
    marginBottom: 2
  },
  imageContainer2: {
    flex: 2,
    marginRight: 5,
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageBox: {
    width: 60,
    height: 60,
    backgroundColor: '#808080',
    padding: 5
  },
  image: {
    width: '100%',
    height: '100%'
  },
  barcodeStateInput: {
    flex: 15,
    padding: 5,
    backgroundColor: '#cbcbca',
    fontSize: 15,
    fontWeight: 'bold',
    color: 'black',
    width: '100%',
    textAlign: 'center',
    borderWidth: 1,
    borderColor: '#cbcbca',
    borderRadius: 5
  },
  scanAllItem: {
    flex: 4,
    marginLeft: 5,
    backgroundColor: '#6f8f93',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    borderRadius: 10
  },
  scanAllItemWeb: {
    flex: 4,
    marginLeft: 5,
    backgroundColor: '#41970f',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    borderRadius: 10
  },
  scanBtn: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#fff',
    textShadowColor: '#000',
    textShadowOffset: {width: 1, height: 1},
    textShadowRadius: 2
  },
  productEditLabel: {
    flex: 2,
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: '#fff',
    width: '100%',
    textAlign: 'center',
    padding: 10
  },
  productEditVal: {
    flex: 8,
    padding: 10
  },
  responseView: {
    flex: 1,
    position: 'absolute',
    top: 0,
    zIndex: 15,
    backgroundColor: 'rgba(14, 14 , 14 , 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: windowWidth,
    width: '100%'
  },
  orderConfirmationNew: {
    justifyContent: 'flex-start'
  },
  orderConfirmationNotes: {
    fontFamily: 'Poppins_300Light',
    fontSize: '17px',
    marginLeft: '1%'
  },
  responseBox: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  responseBoxImage: {
    maxHeight: windowWidth > 767 ? 700 : 400,
    maxWidth: windowWidth > 767 ? 700 : 400,
    width: '100%',
    height: '100%'
  },
  scannedItemContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#666666'
  },
  scannedItemContainerWeb: {
    width: '100%',
    height: '100%',
    backgroundColor: '#414141'
  },
  scannedItemBox: {
    width: '100%',
    height: '100%'
  },
  scannedItemText: {
    textAlign: 'center',
    padding: 5,
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white'
  },
  scannedItemTextWeb: {
    textAlign: 'center',
    padding: 5,
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: '#000',
    color: 'white'
  },
  scannedItemImg: {
    width: '100%',
    height: 100
  },
  scannedItemName: {
    flex: 7,
    padding: 10
  },
  scannedItemNameText: {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold'
  },
  scannedItemNameTextWeb: {
    fontSize: 12,
    fontWeight: '500',
    paddingBottom: 5,
    overflow: 'hidden'
  },
  scannedItemLocationTextWeb: {
    fontSize: 12,
    paddingBottom: 5,
    fontWeight: '500',
    width: '100%',
    overflow: 'hidden'
  },
  scannedItemSkuBox: {
    flex: 7,
    alignItems: 'flex-start',
    margin: 10
  },
  scannedItemSkuBoxWeb: {
    flex: 7,
    alignItems: 'flex-start'
  },
  scannedQtyBox: {
    flex: 3,
    alignItems: 'flex-end',
    margin: 10
  },
  scannedQtyBoxWeb: {
    flex: 3,
    alignItems: 'flex-end'
  },
  typeScanContainer: {
    position: 'absolute',
    justifyContent: 'flex-start',
    top: '0px',
    zIndex: 10,
    alignItems: 'center',
    height: '100%',
    width: '100%',
    display: 'flex'
  },
  typeInCountNew: {
    top: '20px'
  },
  recordSerialNew: {
    top: '-300px',
    justifyContent: 'flex-end'
  },
  recordSerialMobile: {
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: '0',
    width: '97%'
  },
  typeRecordContainer: {
    position: 'absolute',
    top: 0,
    zIndex: 10,
    backgroundColor: 'rgba(14, 14 , 14 , 0.7)',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    shadowColor: '#fff',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 10,
    shadowRadius: 10
  },
  typeScanBox: {
    backgroundColor: '#f9df3b',
    opacity: 1,
    width: '60%',
    height: 160,
    padding: 5,
    borderRadius: 10,
    borderColor: '#e8971a',
    boxShadow: '2px 2px 10px #363636',
    borderWidth: 3
  },
  typeScanBoxAddBarcode: {
    backgroundColor: '#f9df3b',
    opacity: 1,
    top: '10%',
    padding: 5,
    borderRadius: 10
  },
  typeRecordBox: {
    backgroundColor: '#d5c732',
    opacity: 1,
    padding: 5,
    borderRadius: 10,
    height: 166,
    paddingTop: 24
  },
  activityContainer: {
    top: 0,
    zIndex: 10,
    backgroundColor: 'rgba(14, 14 , 14 , 0.7)',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    shadowColor: '#fff',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 10,
    shadowRadius: 10,
    justifyContent: 'center'
  },
  aliasBox: {
    backgroundColor: '#d5c732',
    opacity: 1,
    top: '5%',
    width: windowWidth > 900 ? '70%' : '100%',
    padding: 5,
    borderRadius: 20
  },
  aliasSubBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  aliasHeadingContainer: {
    borderRadius: 20,
    borderColor: '#DCEAFC',
    paddingTop: 27,
    borderWidth: 1,
    paddingBottom: 42,
    paddingVertical: 0
  },
  aliasHeadingPadding: {
    paddingTop: 27,
    paddingLeft: 25,
    paddingBottom: 42,
    paddingRight: 25
  },
  aliasBoxCommonStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    padding: 10
  },
  aliasBoxCommonStyle2: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10
  },
  aliasTextHeading: {
    flex: 8,
    fontFamily: 'Poppins_600SemiBold',
    color: '#DCEAFC',
    textAlign: 'center',
    fontSize: 22,
    marginBottom: 10
  },
  aliasText: {
    flex: 8,
    color: '#FFFFFF',
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    alignSelf: 'center',
    margin: 5
  },
  aliasResultText: {
    fontFamily: 'Poppins_300Light',
    color: '#FFFFFF',
    fontSize: 14,
    marginTop: '2%',
    marginBottom: '1%'
  },
  aliasButtonText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    backgroundColor: '#d7a549',
    color: '#000000',
    padding: 8,
    borderRadius: 10,
    marginLeft: 4,
    marginRight: 4,
    width: 'fit-content',
    textAlign: 'justify',
    paddingHorizontal: 10,
    paddingVertical: 6,
    marginBottom: '2%'
  },
  aliasResultMainContainer: {
    width: '80%',
    borderRadius: 15,
    padding: windowWidth > 900 ? 10 : 15
  },
  aliasResultContainer: {
    display: 'flex',
    flexDirection: windowWidth > 900 ? 'row' : 'column',
    justifyContent: 'space-around'
  },
  aliasResultImageContainer: {
    marginTop: 15,
    alignItems: 'center'
  },
  aliasDescription: {
    flex: 8,
    color: 'black',
    fontSize: 14,
    alignSelf: 'center',
    margin: 5
  },
  aliasResultProduct: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  scanCountText: {
    flex: 8,
    color: 'black',
    textAlign: 'left',
    fontSize: 18
  },
  scanCountButton: {
    borderColor: 'black',
    borderWidth: 1,
    height: 23,
    width: 67,
    marginTop: '1%',
    borderRadius: 5,
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: 10
  },
  scanCountTextInput: {
    margin: 15,
    borderWidth: 1,
    border: 'none',
    marginTop: '3px !important',
    height: '31px',
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#fbe1b1',
    color: '#3e3c37',
    fontSize: 15,
    width: '120%',
    borderColor: '#fbe1b1'
  },
  scanRecordCountText: {
    flex: 8,
    color: 'black',
    textAlign: 'center',
    fontSize: 20,
    padding: 8
  },
  addBarcodeText: {
    fontSize: 20,
    padding: 8,
    justifyContent: 'center',
    textAlign: 'center'
  },
  outLineRemove: {
    backgroundColor: 'red',
    outline: 0
  },
  modalButton: {
    borderColor: 'black',
    borderWidth: 1,
    height: 23,
    width: 67,
    marginTop: '1%',
    borderRadius: 5,
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: 10
  },
  scanCountInnerText: {
    flex: 8,
    color: 'black',
    textAlign: 'left',
    fontSize: 20
  },
  scanCountCloseBtn: {
    flex: 2,
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20
  },
  typeScanCountInput: {
    borderWidth: 0,
    justifyContent: 'flex-start',
    backgroundColor: '#fce6c0',
    color: '#000',
    width: '80%',
    border: 'none',
    height: '31px',
    borderRadius: 8,
    padding: 9,
    fontFamily: 'Extra-light 200'
  },
  RecordScanCountInput: {
    borderColor: '#000',
    borderRadius: 10,
    justifyContent: 'flex-start',
    backgroundColor: '#d5c732',
    color: '#000',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  scanCountSubmitBtnBox: {
    backgroundColor: 'transparent',
    textAlign: 'right',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'black',
    width: 'auto',
    paddingHorizontal: 10
  },
  scanCountSubmitBtn: {
    fontSize: 20,
    color: 'black'
  },
  unscannedItemContainer: {
    width: '100%',
    padding: 10,
    height: '100%',
    backgroundColor: '#cccccc'
  },
  unscannedItemContainerWeb: {
    width: '100%',
    height: '100%',
    backgroundColor: '#000'
  },
  unscannedItemBox: {
    paddingBottom: 20,
    width: '100%',
    height: 'auto'
  },
  unscannedItemTitle: {
    textAlign: 'center',
    padding: 5,
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white'
  },
  unscannedItemTitleWeb: {
    textAlign: 'center',
    padding: 5,
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: '#000',
    color: 'white'
  },
  //shipment section start
  shipContainer: {
    backgroundColor: '#fff',
    padding: 20,
    width: '100%',
    alignItems: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#00968838',
    marginTop: 10
  },
  shipParagraph: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 10,
    textAlign: 'center'
  },
  shipCloseButton: {
    marginTop: 10,
    backgroundColor: '#00968838',
    padding: 10,
    borderRadius: 30,
    alignItems: 'center'
  },
  shipOrderLink: {
    color: '#009688',
    fontWeight: 'bold',
    margin: 2,
    textAlign: 'center'
  },
  shipOrderTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: 2,
    alignSelf: 'center'
  },
  scanBarcodeGradient: {
    padding: 10,
    backgroundColor: '#707070',
    borderRadius: 5,
    marginTop: 1
  },
  scanBarcodeGradientDesktop: {
    marginTop: 2,
    backgroundColor: '#707070',
    padding: 10,
    borderRadius: 12,
    position: 'relative'
  },
  scanBarcodeSubGradient: {
    backgroundColor: '#ebebeb',
    height: 10,
    width: 10,
    borderRadius: 5,
    marginRight: 10,
    shadowColor: '#fff',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 24
  },
  scanBarcodeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  scanBarCodeInputMobile: {
    outline: 'none',
    flex: 15,
    paddingLeft: 20,
    padding: 5,
    backgroundColor: '#ebebeb',
    fontSize: 15,
    fontFamily: fontFamily.font600,
    width: '100%',
    textAlign: 'left',
    borderRadius: 5
  },
  scanBarCodeInput: {
    fontFamily: fontFamily.font600,
    outline: 'none',
    fontSize: 22
  },
  scanBarCodeInputContainer: {
    flex: 15,
    paddingLeft: 20,
    padding: 5,
    fontSize: 15,
    color: 'black',
    width: '100%',
    textAlign: 'left',
    borderWidth: 1,
    borderColor: '#a6a9ab',
    borderRadius: 7,
    justifyContent: 'center',
    backgroundColor: '#ebebeb'
  },
  passButton: {
    flex: 4,
    marginLeft: 10,
    backgroundColor: '#455766',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 7,
    borderRadius: 5
  },
  passButtonText: {
    fontFamily: fontFamily.font800,
    fontSize: 26,
    color: '#fff',
    textAlign: 'center'
  },
  scanPackSingleItemContainer: {
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: '#292929',
    textAlign: 'left',
    display: 'flex',
    marginTop: 5
  },
  showMoreButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 14,
    zIndex: 99999,
    textShadowColor: '#000',
    textShadowOffset: {width: 1, height: 1},
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
    background: '#41970f',
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: 5,
    marginTop: 2
  },
  logsContainer: {
    paddingLeft: 10,
    paddingBottom: 0,
    paddingTop: 5,
    paddingRight: 10,
    textAlign: 'left',
    borderBottomWidth: 1,
    borderBottomColor: '#000'
  },
  logActionText: {
    color: '#8a8989',
    fontFamily: fontFamily.font500
  },
  logActionTextDesktop: {
    color: '#8a8989',
    fontFamily: fontFamily.font400
  },
  addBarcodeContainer: {
    padding: 8,
    backgroundColor: '#7c95ae'
  },
  addBarcodeButton: {
    borderColor: '#fff',
    borderRadius: 10,
    borderWidth: 2,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    marginLeft: '2%',
    boxShadow: '0px 0px 4px #fff'
  },
  scanAddBarcodeText: {
    fontSize: 20,
    color: '#fff',
    fontFamily: 'Poppins_600SemiBold',
    textShadow: '0px 0px 2px #fff'
  },
  noOrderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000'
  },
  importOrderMessage: {
    flexDirection: 'row',
    marginTop: 20
  },
  progressBarDimensionWrapper: {
    alignSelf: 'center',
    width: '100%',
    marginTop: 15
  },
  checkImportStatusLabelWrapper: {
    borderRadius: 10,
    borderColor: '#D7D7D7',
    borderWidth: 2,
    paddingHorizontal: 15,
    paddingVertical: 4
  },
  checkImportStatusLabel: {
    fontFamily: fontFamily.font400,
    alignSelf: 'center',
    color: '#FFF',
    fontSize: 14
  },
  importStatusContainer: {
    marginTop: 15,
    width: 500,
    backgroundColor: '#000',
    padding: 15,
    borderRadius: 15
  },
  importStatusSubContainer: {
    flexDirection: 'row',
    paddingVertical: 8,
    justifyContent: 'space-between'
  },
  importStatusLabel: {
    color: '#FFFFFF',
    fontSize: 18,
    paddingVertical: 5,
    alignSelf: 'center'
  },
  importStatLabel: {
    color: '#FFFFFF',
    fontSize: 18
  },
  lastImportLabel: {
    color: '#FFFFFF',
    paddingTop: 5,
    alignSelf: 'center',
    fontSize: 18
  }
});

export default styles;
