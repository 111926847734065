import React from 'react';
import { View, Text, ActivityIndicator, StyleSheet} from 'react-native';
import { Line } from 'react-native-svg';
import { customColors } from '../../../commons/tests/mocks/mockdata/colors';
import { generateGridLines } from './chartUtils';

export const ChartLoading = () => (
  <View style={styles.loadingContainer}>
    <ActivityIndicator
      size="large"
      color={customColors.loadingIndicatorColor}
    />
  </View>
);

export const NoDataDisplay = () => (
  <View style={styles.noDataContainer}>
    <Text style={styles.noDataText}>No data available</Text>
  </View>
);

export const YAxisLabels = ({
  values,
  chartHeight,
  inverted = false,
  labelOffset = -10,
  containerStyle = {},
  labelStyle = {},
  segmentsCount,
  formatLabel = (value) => value
}) => {
  const segments = segmentsCount || (values.length - 1);
  
  return (
    <View style={[styles.yAxisContainer, containerStyle]}>
      {values.map((value, i) => {
        const position = inverted
          ? ((values.length - 1 - i) * chartHeight) / segments + labelOffset
          : (i * chartHeight) / segments + labelOffset;
          
        return (
          <Text
            key={`ylabel-${i}`}
            style={[
              styles.yAxisLabel,
              labelStyle,
              { top: position }
            ]}>
            {formatLabel(value)}
          </Text>
        );
      })}
    </View>
  );
};

export const GridLines = ({ chartHeight, chartWidth, segments = 6, color = "rgba(255,255,255,0.1)" }) => {
  const lines = generateGridLines(chartHeight, chartWidth, segments, color);
  
  return (
    <>
      {lines.map(line => (
        <Line
          key={line.key}
          x1={line.x1}
          y1={line.y1}
          x2={line.x2}
          y2={line.y2}
          stroke={line.stroke}
          strokeWidth={line.strokeWidth}
          strokeDasharray={line.strokeDasharray}
        />
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  noDataContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  noDataText: {
    color: customColors.white || '#fff',
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold'
  },
  legendOuterContainer: {
    width: '15%',
    paddingLeft: 10,
    justifyContent: 'center',
    maxHeight: '100%'
  },
  legendBackground: {
    backgroundColor: '#0E1317',
    padding: 8,
    borderRadius: 4,
    maxHeight: '100%'
  },
  legendScrollContent: {
    paddingVertical: 5
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12
  },
  legendColor: {
    width: 12,
    height: 12,
    borderRadius: 2,
    marginRight: 8,
    flexShrink: 0
  },
  legendText: {
    color: customColors.white || '#fff',
    fontSize: 20,
    fontFamily: 'Poppins_600SemiBold',
    flex: 1
  },
  yAxisContainer: {
    width: 50,
    height: '100%',
    marginRight: 5,
    position: 'relative',
    justifyContent: 'flex-start'
  },
  yAxisLabel: {
    color: customColors.white || '#fff',
    position: 'absolute',
    right: 0,
    fontSize: 14,
    textAlign: 'right',
    paddingRight: 5
  }
});
