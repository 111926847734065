import { customColors } from '../../../commons/tests/mocks/mockdata/colors';

export function getUserInfo(userId, userDetails) {
  const user = userDetails?.find(user => user.id == userId);
  if (user) {
    return { label: user.name, color: user.color };
  } else {
    return null;
  }
}

export function isBarTallEnough(barHeight, threshold = 25) {
  return barHeight > threshold;
}

export function calculateBarWidth(
  availableWidth,
  dataCount,
  defaultWidth,
  minWidth,
  userThreshold,
  spacing
) {
  const totalBarsWidth = dataCount * (defaultWidth + spacing);
  const useMinWidth = totalBarsWidth > availableWidth && dataCount > userThreshold;
  return useMinWidth ? minWidth : defaultWidth;
}

export function calculateMaxValue(values, minimumMaxValue = 10) {
  return Math.max(...values, minimumMaxValue);
}

export function generateYAxisValues(maxValue, segments = 6) {
  const values = [];
  for (let i = 0; i <= segments; i++) {
    values.push(Math.round((maxValue * (segments - i)) / segments));
  }
  return values;
}

export function calculateChartDimensions(
  containerDimensions,
  dataList,
  barWidth,
  spacing,
  yAxisWidth,
  legendWidth
) {
  const availableWidth = containerDimensions.width - yAxisWidth - legendWidth - 20;
  const calculatedChartWidth = Math.max(
    dataList.length * (barWidth + spacing),
    availableWidth
  );
  
  return {
    chartWidth: calculatedChartWidth,
    shouldScroll: calculatedChartWidth > availableWidth
  };
}

export function calculateSum(dataList, defaultValue = 15) {
  return dataList?.reduce((sum, item) => sum + item.item_count, 0) || defaultValue;
}

export function getOptimalFontSize(height) {
  if (height < 20) return 0;
  if (height < 30) return 12;
  if (height < 45) return 14;
  return 26;
}

export function generateGridLines(chartHeight, chartWidth, segments = 6, color = "rgba(255,255,255,0.1)") {
  const lines = [];
  
  for (let i = 0; i <= segments; i++) {
    const y = (i * chartHeight) / segments;
    lines.push({
      key: `line-${i}`,
      x1: 0,
      y1: y,
      x2: chartWidth,
      y2: y,
      stroke: color,
      strokeWidth: 1,
      strokeDasharray: color === customColors.gridLineColor ? "5,5" : undefined
    });
  }

  return lines;
}

export function getColorIntensity(value, max = 100) {
  const intensity = Math.floor((value / max) * 255);
  return `rgb(${255 - intensity}, ${255 - intensity}, 255)`;
}

export function processBarChartData(statistics, userDetails, calculateFn) {
  if (!statistics?.length) return { statistics: [], labels: [] };

  const processedData = statistics.reduce(
    (acc, stat) => {
      const userId = parseInt(stat.user_id);
      const value = calculateFn(stat.data);
      const user = getUserInfo(userId, userDetails);
      
      if (user) {
        acc.statistics.push({
          ...user,
          value
        });
      }
      return acc;
    },
    { statistics: [] }
  );

  const labels = processedData.statistics.map(item => ({
    label: item.label,
    color: item.color
  }));

  return { 
    statistics: processedData.statistics,
    labels
  };
}

export const shadeColor = (color, percent) => {
  try {
    if (!color || typeof color !== 'string' || !color.startsWith('#') || color.length !== 7) {
      return '#cccccc';
    }
    
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.floor(R * (100 + percent) / 100);
    G = Math.floor(G * (100 + percent) / 100);
    B = Math.floor(B * (100 + percent) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    R = R > 0 ? R : 0;
    G = G > 0 ? G : 0;
    B = B > 0 ? B : 0;

    const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

    return `#${RR}${GG}${BB}`;
  } catch (error) {
    console.error('Error in shadeColor:', error);
    return '#cccccc';
  }
};

export const lightenColor = (color, percent = 30) => {
  return shadeColor(color, percent);
};

export const calculateBarTodayVsYesDimensions = (
  groupIndex, 
  group, 
  maxValue, 
  chartHeight, 
  groupWidth, 
  groupSpacing, 
  todayBarWidth, 
  yesterdayBarWidth, 
  barSpacing
) => {
  const groupX = groupIndex * (groupWidth + groupSpacing);
  
  const yesterdayBarHeight = (group.yesterday.value / maxValue) * chartHeight ||
    0;
  const yesterdayBarX = groupX;
  const yesterdayBarY = chartHeight - yesterdayBarHeight;
  const yesterdayBarCenter = yesterdayBarX + yesterdayBarWidth / 2;
  
  const todayBarHeight = (group.today.value / maxValue) * chartHeight || 0;
  const todayBarX = groupX + yesterdayBarWidth + barSpacing;
  const todayBarY = chartHeight - todayBarHeight;
  const todayBarCenter = todayBarX + todayBarWidth / 2;
  
  const veryTallBar = todayBarHeight > 200;
  const tallBar = todayBarHeight > 80 && todayBarHeight <= 200;
  const shortBar = todayBarHeight <= 40;
  
  return {
    groupX,
    yesterday: {
      height: yesterdayBarHeight,
      x: yesterdayBarX,
      y: yesterdayBarY,
      center: yesterdayBarCenter
    },
    today: {
      height: todayBarHeight,
      x: todayBarX,
      y: todayBarY,
      center: todayBarCenter
    },
    barType: {
      veryTall: veryTallBar,
      tall: tallBar,
      short: shortBar
    }
  };
};

export const processHorizontalChartStatistics = (
  response,
  dataProcessedRef,
  setMaxValue,
  setDataList,
  sortOrder = 'desc'
) => {
  if (
    dataProcessedRef.current ||
    !response?.data?.statistics ||
    !response?.data?.user_details
  )
    return false;

  try {
    dataProcessedRef.current = true;

    const {statistics: statisticsList, user_details: userDetails} = response.data;

    if (!statisticsList || !userDetails) return false;

    const userMap = {};
    userDetails.forEach(user => {
      userMap[user.id] = user;
    });

    const processedData = statisticsList.map(userStat => {
      const userId = parseInt(userStat.user_id, 10);
      const user = userMap[userId];
      
      if (!user) return null;

      const itemsPerMinute = userStat.items_per_minute || 0;
      const mainColor = user.color || '#cccccc';
      const lighterColor = lightenColor(mainColor, 30);
      const darkerColor = shadeColor(mainColor, -30);

      return {
        userId,
        label: user.name || `User ${userId}`,
        value: itemsPerMinute,
        color: mainColor,
        gradientStart: lighterColor,
        gradientEnd: darkerColor
      };
    }).filter(Boolean);

    if (!processedData || processedData.length === 0) {
      dataProcessedRef.current = false;
      return false;
    }

    const maxVal = calculateMaxValue(
      processedData.map(item => item.value),
      0
    );
    setMaxValue(Math.ceil(maxVal * 1.05));

    const sortedData = [...processedData];
    if (sortOrder === 'asc') {
      sortedData.sort((a, b) => a.value - b.value);
    } else {
      sortedData.sort((a, b) => b.value - a.value);
    }
    
    setDataList(sortedData);
    
    return true;
  } catch (error) {
    console.error('Error processing statistics:', error);
    dataProcessedRef.current = false;
    return false;
  }
};

export const calculateRemainingTime = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();

  const currentQuarter = Math.floor(minutes / 15);
  const nextQuarterMinute = (currentQuarter + 1) * 15;

  let remainingMinutes = nextQuarterMinute - minutes - 1;
  let remainingSeconds = 60 - seconds;
  
  if (remainingSeconds === 60) {
    remainingSeconds = 0;
    remainingMinutes += 1;
  }

  const formattedText = `${String(remainingMinutes).padStart(2, '0')} min ${String(remainingSeconds).padStart(2, '0')} sec Remaining`;
  
  return {
    text: formattedText,
    minutes: remainingMinutes,
    seconds: remainingSeconds
  };
};

export const getCurrentQuarter = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  return Math.floor(minutes / 15);
};

export const processDailyChartStatistics = (response) => {
  if (!response?.data?.statistics) {
    return { 
      dataList: [], 
      maxValue: 0, 
      success: false 
    };
  }

  try {
    const { statistics: statisticsList, user_details: userDetails } = response.data;

    if (!statisticsList || !statisticsList.length) {
      return { 
        dataList: [], 
        maxValue: 0, 
        success: true 
      };
    }

    const { data } = statisticsList.reduce(
      (acc, { data, user_id }) => {
        try {
          const calculateSum = data.reduce(
            (total, item) => total + (item?.item_count || 0),
            0
          );
          const userInfo = getUserInfo(user_id, userDetails) || 
                          { label: `User ${user_id}`, color: '#cccccc' };
          
          acc.data.push({ value: calculateSum || 0, ...userInfo });
        } catch (e) {
          console.error('Error processing statistic item:', e);
        }
        return acc;
      },
      { data: [] }
    );

    const sortedData = [...data].sort((a, b) => a.value - b.value);

    const enhancedData = sortedData.map(item => {
      const mainColor = item.color || '#cccccc';
      const lighterColor = lightenColor(mainColor, 30);
      const darkerColor = shadeColor(mainColor, -30);

      let limitedLabel = item.label || '';
      if (limitedLabel.length > 10) {
        limitedLabel = limitedLabel.substring(0, 10) + '...';
      }

      return {
        ...item,
        label: limitedLabel,
        gradientStart: lighterColor,
        gradientEnd: darkerColor
      };
    });

    const maxVal = Math.max(...enhancedData.map(item => item.value), 1);
    const maxValue = Math.ceil(maxVal * 1.05);

    return {
      dataList: enhancedData,
      maxValue,
      success: true
    };
  } catch (e) {
    console.error('Error processing statistics:', e);
    return { 
      dataList: [], 
      maxValue: 0, 
      success: false,
      error: e 
    };
  }
};

export const calculateDailyChartDimensions = (
  data, 
  containerDimensions, 
  maxBarsToShow = 6, 
  barGapRatio = 0.2, 
  yAxisWidth = 50
) => {
  const availableWidth = containerDimensions.width - yAxisWidth - 40;
  const totalWidthPerBar = availableWidth / maxBarsToShow;
  const calculatedBarWidth = totalWidthPerBar * (1 - barGapRatio);
  const calculatedSpacing = totalWidthPerBar * barGapRatio;

  const calculatedChartWidth = Math.max(
    data.length * totalWidthPerBar,
    containerDimensions.width - 70
  );

  return {
    barWidth: calculatedBarWidth,
    spacing: calculatedSpacing,
    chartWidth: calculatedChartWidth,
    shouldScroll: data.length > maxBarsToShow
  };
};

export const processWithin15MinChartStatistics = (response) => {
  if (!response?.data?.statistics) {
    return { 
      dataList: [], 
      maxValue: 0, 
      success: false 
    };
  }

  try {
    const { statistics: statisticsList, user_details: userDetails } = response.data;

    if (!statisticsList || !statisticsList.length) {
      return { 
        dataList: [], 
        maxValue: 0, 
        success: true 
      };
    }

    let processedData;
    try {
      processedData = processBarChartData(statisticsList, userDetails, data =>
        data.reduce((total, item) => total + (item?.item_count || 0), 0)
      );
    } catch (e) {
      console.error('Error in processBarChartData:', e);
      processedData = { statistics: [] };
    }

    const data = processedData.statistics || [];

    if (data.length === 0) {
      return { 
        dataList: [], 
        maxValue: 0, 
        success: true 
      };
    }

    const sortedData = [...data].sort((a, b) => a.value - b.value);

    const enhancedData = sortedData.map(item => {
      const mainColor = item.color || '#cccccc';
      const lighterColor = lightenColor(mainColor, 30);
      const darkerColor = shadeColor(mainColor, -30);
      
      let limitedLabel = item.label || '';
      if (limitedLabel.length > 10) {
        limitedLabel = limitedLabel.substring(0, 10) + '...';
      }

      return {
        ...item,
        label: limitedLabel,
        gradientStart: lighterColor,
        gradientEnd: darkerColor
      };
    });

    let maxVal;
    try {
      maxVal = calculateMaxValue(
        enhancedData.map(item => item.value),
        10
      ) * 1.1;
    } catch (e) {
      console.error('Error calculating max value:', e);
      maxVal = Math.max(...enhancedData.map(item => item.value || 0), 1) * 1.1;
    }

    return {
      dataList: enhancedData,
      maxValue: maxVal,
      success: true
    };
  } catch (e) {
    console.error('Error processing statistics:', e);
    return { 
      dataList: [], 
      maxValue: 0, 
      success: false,
      error: e 
    };
  }
};

export const truncateLabel = label => {
  if (!label) return '';

  const maxLength = 10;

  if (label.length > maxLength) {
    return `${label.substring(0, maxLength)}...`;
  }
  return label;
};

export const calculateGridLines = (maxValue, chartWidth) => {
  const segments = 6;
  const lines = [];
  const roundedMaxValue = Math.ceil(maxValue);
  
  for (let i = 0; i <= segments; i++) {
    lines.push({
      position: (i / segments) * chartWidth,
      value: (i / segments) * roundedMaxValue
    });
  }

  return lines;
};

export const getValueTextPosition = (barWidth, itemValue) => {
  return {
    x: barWidth / 2,
    anchor: 'middle',
    fill: 'white',
    display: true
  };
};
