import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSelector } from 'react-redux';

const chartOptions = [
  { id: 1, name: 'Current 15-Min', key: 'current15Min' },
  { id: 2, name: 'Today', key: 'today' },
  { id: 3, name: 'User Performance', key: 'userPerformance' },
  { id: 4, name: 'Yesterday vs Today', key: 'yesterdayVsToday' },
  { id: 5, name: 'This week', key: 'thisWeek' },
];

const SettingsDropdown = ({ isOpen, onClose, updateDashboardSettings }) => {
  const [settings, setSettings] = useState({
    current15Min: { enabled: false, time: 15 },
    today: { enabled: true, time: 15 },
    userPerformance: { enabled: true, time: 15 },
    yesterdayVsToday: { enabled: true, time: 15 },
    thisWeek: { enabled: true, time: 15 },
  });
  const [timeInputs, setTimeInputs] = useState({});
  const dropdownRef = useRef(null);
  
  const listDaily = useSelector(state => state.statistics.listDaily);
  const withIn15MinList = useSelector(state => state.statistics.withIn15MinList);
  const todayVsYesterdayList = useSelector(state => state.statistics.todayVsYesterdayList);
  const weeklyList = useSelector(state => state.statistics.weeklyList);
  
  const [legendItems, setLegendItems] = useState([]);

  const collectUserDetails = () => {
    const allUserDetailsArrays = [
      listDaily?.data?.user_details,
      withIn15MinList?.data?.user_details,
      todayVsYesterdayList?.data?.user_details,
      weeklyList?.data?.user_details
    ].filter(array => Array.isArray(array) && array.length > 0);
    
    const uniqueUsersMap = new Map();
    
    allUserDetailsArrays.forEach(userDetailsArray => {
      userDetailsArray.forEach(user => {
        if (user && user.id && !uniqueUsersMap.has(user.id)) {
          uniqueUsersMap.set(user.id, { 
            label: user.name, 
            color: user.color 
          });
        }
      });
    });
    
    const uniqueUsers = Array.from(uniqueUsersMap.values());
    const sortedUsers = uniqueUsers.sort((a, b) => 
      (a.label || '').localeCompare(b.label || '')
    );
    
    setLegendItems(sortedUsers);
  };

  useEffect(() => {
    collectUserDetails();
  }, [
    listDaily?.data?.user_details,
    withIn15MinList?.data?.user_details,
    todayVsYesterdayList?.data?.user_details,
    weeklyList?.data?.user_details
  ]);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const storedSettings = await AsyncStorage.getItem('chartSettings');
        if (storedSettings) {
          const parsedSettings = JSON.parse(storedSettings);
          setSettings(parsedSettings);
          const initialInputs = {};
          Object.keys(parsedSettings).forEach(key => {
            initialInputs[key] = parsedSettings[key].time.toString();
          });
          setTimeInputs(initialInputs);
        } else {
          const initialInputs = {};
          Object.keys(settings).forEach(key => {
            initialInputs[key] = settings[key].time.toString();
          });
          setTimeInputs(initialInputs);
        }
      } catch (error) {
        console.error('Error loading settings:', error);
      }
    };

    loadSettings();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleToggleOption = (key) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: {
        ...prevSettings[key],
        enabled: !prevSettings[key].enabled
      }
    }));
  };

  const handleTimeInputChange = (key, value) => {
    setTimeInputs(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const finalizeTimeInput = (key) => {
    const numValue = Math.min(Math.max(parseInt(timeInputs[key]) || 5, 5), 60);
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: {
        ...prevSettings[key],
        time: numValue
      }
    }));
    setTimeInputs(prev => ({
      ...prev,
      [key]: numValue.toString()
    }));
  };

  const handleKeyPress = (e, key) => {
    if (e.key === 'Enter') {
      finalizeTimeInput(key);
    }
  };

  const saveChanges = async () => {
    try {
      Object.keys(timeInputs).forEach(key => {
        finalizeTimeInput(key);
      });
      await AsyncStorage.setItem('chartSettings', JSON.stringify(settings));
      updateDashboardSettings(settings);
      onClose();
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <View style={styles.dropdownContainer} ref={dropdownRef}>
      <View style={styles.header}>
        <View style={styles.headerContent}>
          <i className="icon-cog" style={styles.settingsIcon}></i>
          <Text style={styles.headerText}>Settings</Text>
        </View>
      </View>
      
      <ScrollView style={styles.scrollContainer}>
        <View style={styles.optionsContainer}>
          {chartOptions.map((option) => (
            <View key={option.id} style={styles.optionRow}>
              <Text style={styles.optionText}>{option.name}</Text>
              
              <View style={styles.inputContainer}>
                <TextInput
                  style={styles.timeInput}
                  value={timeInputs[option.key] || settings[option.key].time.toString()}
                  onChangeText={(value) => handleTimeInputChange(option.key, value)}
                  onBlur={() => finalizeTimeInput(option.key)}
                  onKeyPress={(e) => handleKeyPress(e, option.key)}
                  keyboardType="numeric"
                  maxLength={2}
                />
                <Text style={styles.secText}>sec</Text>
              </View>
              
              <TouchableOpacity
                onPress={() => handleToggleOption(option.key)}
                style={styles.checkboxContainer}
              >
                {settings[option.key].enabled ? (
                  <i className="icon-check" style={styles.checkIcon}></i>
                ) : (
                  <i className="icon-check-empty" style={styles.checkEmptyIcon}></i>
                )}
              </TouchableOpacity>
            </View>
          ))}
        </View>
        
        {legendItems.length > 0 && (
          <View style={styles.legendSection}>
            <Text style={styles.legendTitle}>Users</Text>
            <View style={styles.legendContainer}>
              <View style={styles.legendBackground}>
                <View style={styles.legendContent}>
                  {legendItems.map((item, index) => (
                    <View key={`legend-${index}`} style={styles.legendItem}>
                      <View
                        style={[styles.legendColor, { backgroundColor: item.color }]}
                      />
                      <Text style={styles.legendText} numberOfLines={1} ellipsizeMode="tail">
                        {item.label}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
        )}
      </ScrollView>
      
      <TouchableOpacity style={styles.saveButton} onPress={saveChanges}>
        <Text style={styles.saveButtonText}>Save Changes</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  dropdownContainer: {
    position: 'absolute',
    width: 320,
    backgroundColor: '#262B30',
    borderRadius: 10,
    right: 20,
    top: 50,
    zIndex: 1000,
    overflow: 'hidden',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    maxHeight: '80%',
  },
  scrollContainer: {
    maxHeight: '550',
  },
  header: {
    backgroundColor: '#0E1317',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255, 255, 255, 0.1)',
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  settingsIcon: {
    color: 'white',
    fontSize: 22,
    marginRight: 10,
  },
  headerText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'Poppins_600SemiBold',
  },
  optionsContainer: {
    paddingVertical: 10,
  },
  optionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
  optionText: {
    color: 'white',
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold',
    flex: 1,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
  },
  timeInput: {
    backgroundColor: '#1A1D20',
    color: 'white',
    width: 50,
    height: 36,
    borderRadius: 20,
    textAlign: 'center',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 16,
    paddingHorizontal: 0,
  },
  secText: {
    color: 'white',
    marginLeft: 8,
    fontFamily: 'Poppins_600SemiBold',
  },
  checkboxContainer: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    color: 'white',
    fontSize: 24,
  },
  checkEmptyIcon: {
    color: 'white',
    fontSize: 24,
  },
  legendSection: {
    paddingTop: 15,
    borderTopWidth: 1,
    borderTopColor: 'rgba(255, 255, 255, 0.1)',
    paddingHorizontal: 20,
    paddingBottom: 10,
  },
  legendTitle: {
    color: 'white',
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold',
    marginBottom: 10,
  },
  legendContainer: {
    marginBottom: 15,
  },
  legendBackground: {
    backgroundColor: '#0E1317',
    borderRadius: 8,
    padding: 10,
  },
  legendContent: {
    paddingVertical: 5,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
  },
  legendColor: {
    width: 16,
    height: 16,
    borderRadius: 3,
    marginRight: 10,
  },
  legendText: {
    color: 'white',
    fontSize: 14,
    fontFamily: 'Poppins_600SemiBold',
    flex: 1,
  },
  saveButton: {
    backgroundColor: '#1A1D20',
    marginVertical: 16,
    paddingVertical: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    shadowColor: '#e853fa',
    shadowOffset: {width: 0, height: 0.5},
    shadowRadius: 8,
    elevation: 5,
    shadowOpacity: 0.5,
    width: 150,
    marginHorizontal: 'auto'
  },
  saveButtonText: {
    color: 'white',
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold',
  },
});

export default SettingsDropdown;
