import { useState, useEffect } from 'react';
import { Animated } from 'react-native';
const useChartAnimations = (data = [], duration = 800, delay = 300, staggerDelay = 100) => {
  const [animations, setAnimations] = useState({});

  useEffect(() => {
    if (!data || data.length === 0) return;

    const animationValues = {};
    data.forEach((_, index) => {
      animationValues[`bar-${index}`] = new Animated.Value(0);
    });
    setAnimations(animationValues);

    const animationTimeout = setTimeout(() => {
      const animationSequence = data.map((_, index) =>
        Animated.timing(animationValues[`bar-${index}`], {
          toValue: 1,
          duration: duration,
          useNativeDriver: false
        })
      );
      
      Animated.stagger(staggerDelay, animationSequence).start();
    }, delay);

    return () => clearTimeout(animationTimeout);
  }, [data, duration, delay, staggerDelay]);

  return animations;
};

export default useChartAnimations;
