import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  Image,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {customColors} from '../../commons/tests/mocks/mockdata/colors';
import i18n from 'i18n-js';
import {LinearGradient} from 'expo-linear-gradient';
import {fontFamily} from '../../helpers/fontFamily';
import {addSoundFile, fetchSounds} from '../../actions/userAction';
import OrderScanSound from './OrderScanSound';
import completeSound from '../../../assets/sounds/_order_complete.mp3';
import scanSuccessSound from '../../../assets/sounds/Scann Success_coin_a.mp3';
import scanFailSound from '../../../assets/sounds/scan_fail_shorter.mp3';
import notReady from '../../../assets/sounds/not_ready.mp3';
import AsyncStorage from '@react-native-async-storage/async-storage';
const wp = Dimensions.get('window').width;
const hp = Dimensions.get('window').height;
import ArrowImage from '../../../assets/option frame.png';
import AboutIcon from  '../../../assets/aboutIcon.png'
import ToolTip from './toolTip';

const SoundSetting = () => {
  const dispatch = useDispatch();
  const response = useSelector(state => state.user.soundData);
  const dropdownRef = useRef(null); // Ref to handle clicks outside
  const [activeTollTip, setActiveTollTip] = useState('');

  const [dropDown, setDropDown] = useState(false);
  const [file, setFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectType, setSelectType] = useState({
    name: 'Select Event Type',
    value: ''
  });
  const [soundList, setSoundList] = useState([
    {name: 'CORRECT SCAN', value: 'correct_scan'},
    {name: 'ERROR SCAN', value: 'error_scan'},
    {name: 'ORDER DONE', value: 'order_done'}
  ]);

  // Added state to track current selected filenames for each sound type
  const [currentSelectedFiles, setCurrentSelectedFiles] = useState({
    correct_scan: "Default Sound.mp3",
    error_scan: "Default Sound.mp3",
    order_done: "Default Sound.mp3"
  });

  const [sounds, setSounds] = useState({
    errorScan: [{
      content_type: "application/octet-stream",
      filename: "Default Sound.mp3",
      tenant_name: "",
      url: scanFailSound
    }],
    correctScan: [{
      content_type: "application/octet-stream",
      filename: "Default Sound.mp3",
      tenant_name: "",
      url: scanSuccessSound
    }],
    orderDone: [{
      content_type: "application/octet-stream",
      filename: "Default Sound.mp3",
      tenant_name: "",
      url: completeSound
    }]
  });
  const handleTollTip = attribute => {
    if (activeTollTip !== attribute) {
      setActiveTollTip(attribute);
      return;
    }
    setActiveTollTip('');
  };

  const handleOutsidePress = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropDown(false);
    }
  };

  useEffect(() => {
    // Add event listener for detecting click outside
    document.addEventListener('mousedown', handleOutsidePress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsidePress);
    };
  }, []);



  useEffect(() => {
    dispatch(fetchSounds());
  }, [dispatch]);

  useEffect(() => {
    handleSounds();
    setIsLoading(false)
    // dispatch(fetchSounds())
  }, [response]);


  const handleSounds = async () => {
    let correctScanLabel = localStorage.getItem('correctScanLabel');
    let errorScanLabel = localStorage.getItem('errorScanLabel');
    let orderScanLabel = localStorage.getItem('orderScanLabel');

    if(response?.sounds?.error_scan ===  undefined){
      correctScanLabel = "Default.mp3";
    }

    if(response?.sounds?.correct_scan === undefined){
      errorScanLabel = "Default.mp3";
    }

    if(response?.sounds?.order_done === undefined){
      orderScanLabel = "Default.mp3";
    }

    setCurrentSelectedFiles({
      correct_scan: correctScanLabel,
      error_scan: errorScanLabel,
      order_done: orderScanLabel,
  });

    const errorScanObject = {
      content_type: "application/octet-stream",
      filename: "Default Sound.mp3",
      tenant_name: "",
      url: scanFailSound
    }

    const correctScanObject = {
      content_type: "application/octet-stream",
      filename: "Default Sound.mp3",
      tenant_name: "",
      url: scanSuccessSound
    }

    
    const orderScanObject = {
      content_type: "application/octet-stream",
      filename: "Default Sound.mp3",
      tenant_name: "",
      url: completeSound
    }
    
    setSounds({
      errorScan: response?.sounds?.error_scan !== undefined ? [errorScanObject, ...response?.sounds?.error_scan] : [errorScanObject],
      correctScan: response?.sounds?.correct_scan !== undefined ? [correctScanObject, ...response?.sounds?.correct_scan] : [correctScanObject],
      orderDone: response?.sounds?.order_done !== undefined ? [orderScanObject, ...response?.sounds?.order_done] : [orderScanObject]
    });
  }

  const handleSelectType = item => {
    setSelectType(item);
    setDropDown(prev => !prev);
    setFile('');
  };

  const handleUploadFile = async () => {
    if (selectType.value != '' && file != '') {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file_paths', file);
      formData.append('sound_type', selectType.value);
      try {
        const uploadResponse = await dispatch(addSoundFile(formData)); // Ensure the file is uploaded before fetching        
        setTimeout(() => {
          dispatch(fetchSounds()); 
          handleSounds(); 
          setCurrentSelectedFiles(prev => ({
            ...prev,
            [selectType.value]: file.name
          }));
        }, 3000);
        
      } catch (error) {
        console.error('Upload failed', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderSoundTypeItem = ({item}) => {
    return (
      <TouchableOpacity
        style={styles.dropDownOptionWrapper}
        onPress={() => {
          handleSelectType(item);
        }}>
        <Text style={styles.dropDownTextWrapper}>{item.name}</Text>
      </TouchableOpacity>
    );
  };

  const handleOnChangeFile = file => {
    setFile(file);
  };

  const getCurrentFileName = () => {
    if (file) {
      return file.name;
    } else if (selectType.value) {
      return currentSelectedFiles[selectType.value];
    }
    return "No sound chosen";
  };

  return (

    <View style={styles.container}>
      <Text style={styles.heading}>
        {i18n.t('directprinting.soundSettingLabel')}
      </Text>
      <View  style={{flexDirection: 'row'}}>
        <TouchableOpacity
          testID='selectDropDownTestID'
          onPress={() => {
            setDropDown(prev => !prev);
          }}
          style={styles.dropdownButton}>
          <Text style={styles.dropdownText}>{selectType.name}</Text>
          <View style={{flexDirection: 'row'}}>
            <Image source={ArrowImage} style={{width:15 ,height:15, justifyContent: 'center'}}/>
            <View
                  style={{
                    display: 'flex',
                    gap: '10px',
                    left: '6%',
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingBottom: '20px',
                    position: 'relative',
                    zIndex: 7,
                  }}>
                  <TouchableOpacity
                    testID="tooltipone"
                    onPress={() => handleTollTip('QuickScanToolTip0')}
                    style={{width: 20}}>
                    <i
                      className="icon-info-circled"
                      style={styles.iconInfo}></i>
                  </TouchableOpacity>
                  {activeTollTip === 'QuickScanToolTip0' && (
                    <ToolTip
                      message={i18n.t('directprinting.tooltipSoundSetting')}
                      testID={'quicKScanToolTipone'}
                      windowWidth={100}
                      left={'-52%'}
                      bodyTop={-83}
                      fontSize = {5}
                    />
                  )}
            </View>

          </View>
        </TouchableOpacity>

        <View style={styles.fileInputContainer}>
          <TouchableOpacity style={styles.chooseFileButton}>
            <Text numberOfLines={1} style={styles.chooseFileText}>Choose File</Text>
            <input
              type="file"
              accept=".mp3"
              style={styles.hiddenInput}
              testID={'changesFileTextId'}
              onChange={event => handleOnChangeFile(event.target.files[0])}
            />
          </TouchableOpacity>

          <View style={styles.fileInputWrapper}>
            <Text numberOfLines={1} style={styles.fileInputText}>
              {getCurrentFileName()}
            </Text>
          </View>
        </View>
      
        {isLoading ? (
          <View style={styles.loadingWrapper}>
            <ActivityIndicator size="small" color={customColors.white} />
            <Text style={styles.loadingText}>Uploading...</Text>
          </View>
        ) : (
          <TouchableOpacity
            style={{height: 36, width: 150, marginLeft: 20}}
            onPress={handleUploadFile}
            disabled={selectType.value === '' || file === ''}>
            <LinearGradient
              colors={['transparent']}
              style={styles.saveButtonWrapper}>
              <Text style={styles.saveButtonTextWrapper}>Upload & Save</Text>
            </LinearGradient>
          </TouchableOpacity>
        )}
      </View>
      {dropDown && (
        <View
          ref={dropdownRef} 
          style={{
            marginTop: 10,
            width: 300,
            height: 'auto',
            overflow: 'visible',
            alignSelf: 'left',
            backgroundColor: customColors.white,
            borderRadius: 5,
            paddingHorizontal: 5
          }}>
          <FlatList
            style={styles.selectSoundTypeWrapper}
            data={soundList}
            renderItem={renderSoundTypeItem}
          />
        </View>
      )}
      <View style={styles.selectSoundTableWrapper}>
        <OrderScanSound
          title={'Correct Scan Feedback'}
          type={'correct_scan'}
          orderScanList={sounds.correctScan}
          selectSounds={localStorage.getItem('correctScanLabel') || 'Default'}
        />
        <OrderScanSound
          title={'Error Scan Feedback'}
          type={'error_scan'}
          orderScanList={sounds.errorScan}
          selectSounds ={ localStorage.getItem('errorScanLabel') || 'Default'}
        />
        <OrderScanSound
          title={'Order Done Feedback'}
          type={'order_done'}
          orderScanList={sounds.orderDone}
          selectSounds ={ localStorage.getItem('orderScanLabel') || 'Default'}
        />
      </View>
    </View>
  );
};

export default SoundSetting;

const styles = StyleSheet.create({
  container: {
    height: 300,
    alignSelf: 'center',
    marginTop: 40,
    padding: 30
  },
  heading: {
    color: customColors.white,
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 10
  },
  dropdownButton: {
    width: 300,
    borderWidth: 1,
    borderColor: 'transparent',
    borderRadius: 5,
    backgroundColor: 'transparent',
    borderBottomColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dropdownText: {
    color: customColors.white
  },
  dropDownTextWrapper: {
    color: '#fff',
  },
  selectSoundTypeWrapper: {
    width: 300,
    height: 'auto',
    overflow: 'visible',
    alignSelf: 'center',
    borderColor: 'transparent',
    backgroundColor:'#2D445F',
    borderWidth: 2
  },
  subHeading: {
    color: 'white',
    fontWeight: 600,
    fontSize: '8px'
  },
  iconInfo: {
    color: 'rgb(172,187,199)',
    fontSize: '17px',
    height: '18px'
  },
  dropDownOptionWrapper: {
    width: 300,
    height: 40,
    justifyContent: 'center',
    borderBottomColor: 'transparent',
    borderBottomWidth: 1,
    color: customColors.white,
  },
  chooseFileWrapper: {
    height: 29,
    backgroundColor: '#2D445F',
    width: 246,
    marginLeft: 10,
    borderRadius: 10,
    border: 'rgb(104, 124, 144);',
    paddingHorizontal: 11,
    paddingVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
    display: 'flex',
    flexDirection: 'row'
  },
  fileInputContainer: {
    flexDirection: 'row',
    alignItems: 'stretch',
    width: 400,
    borderBottomColor: 'transparent',
    marginLeft: 30,
  },
  fileInputWrapper: {
    flex: 1,
    backgroundColor: '#fff',
    borderBottomColor: 'transparent',
    justifyContent: 'center',
    borderRadius: 4,
    paddingHorizontal: 10,
    marginLeft: 14,
    width: 500,
    borderRadius: 15,
    height: 32,
   
  },
  fileInputText: {
    color: '#666',
    fontSize: 14
  }, 
  hiddenInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer'
  },
  chooseFileButton: {
    marginLeft: -5,
    borderRadius: 10,
    borderColor: customColors.blueSlate,
    borderWidth: 2,
    borderStyle: 'solid',
    paddingHorizontal: 11,
    paddingVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    display: 'flex',
    backgroundColor: ' rgb(81, 107, 131)'
  },
  chooseFileText: {
    color: customColors.white,
    fontSize: 14
  },
  inputFileWrapper: {
    alignSelf: 'center',
    marginLeft: 10
  },
  saveButtonWrapper: {
    marginLeft: 10,
    borderRadius: 10,
    borderColor: customColors.blueSlate,
    borderWidth: 2,
    borderStyle: 'solid',
    paddingHorizontal: 11,
    paddingVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    display: 'flex',
    backgroundColor: ' rgb(81, 107, 131)'
  },
  saveButtonTextWrapper: {
    color: '#fff',
    fontFamily: fontFamily.font400,
    fontSize: 12,
    weight: 500,
    lineHeight: 18,
    textAlign: 'center',
    width: 150,
  },
  loadingWrapper: {
    marginLeft: 10,
    borderRadius: 10,
    paddingHorizontal: 11,
    paddingVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
    width: 150,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#516b83',
  },
  loadingText: {
    color: customColors.white,
    marginLeft: 8,
    fontFamily: fontFamily.font300,
    fontSize: 14,
  },
  selectSoundTableWrapper: {
    marginTop: 10,
    padding: (wp * 1) / 100,
    width: (wp * 50) / 100,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'space-around',
    color: customColors.white,
    fontSize: 5,
    left: 0,
    position: 'relative',
    paddingLeft: 0
  }
});