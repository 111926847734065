import axios from 'axios';
import {
  UPDATE_PRODUCT,
  SEARCH_SCANPACK_ORDER,
  SEARCH_SCANPACK_ORDER_FAIL,
  IMPORT_ORDER_SUCCESS,
  IMPORT_ORDER_FAIL,
  IMPORT_ORDER_STATUS_SUCCESS,
  IMPORT_ORDER_STATUS_FAIL,
  FULL_ORDER_SCAN_CHECK,
  DETECT_ORDERS_DISCREPANCIES,
  DASHBOARD_DATA,
  CHECK_IMPORT_ORDER_STATUS_SUCCESS,
  CHECK_IMPORT_ORDER_STATUS_FAIL,
  RESET_SCANPACK_ORDER
} from '../constants';

import AsyncStorage from '@react-native-async-storage/async-storage';

export function SearchScanpackOrder(data, callBack) {
  return async dispatch => {
    if (callBack === false && !data.scan_to_cart_enabled) {
      return dispatch({
        type: SEARCH_SCANPACK_ORDER_FAIL,
        payload: ''
      });
    }

    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');

      const response = await axios({
        method: 'post',
        url: `${url}/scan_pack/scan_barcode.json`,
        headers: {Authorization: `Bearer ${access_token}`},
        data
      });

      if (data.scan_to_cart_enabled) {
        if (!response.data.status) {
          if (
            response.data.error_messages.includes(
              'Cart has totes that are already assigned to orders'
            )
          ) {
            callBack(data.input, 'not empty cart');
          }
          return;
        }

        if (!response?.data?.data?.next_state) {
          callBack?.(data.input, response.data.success_messages);
          return;
        }
      }

      dispatch({
        type: SEARCH_SCANPACK_ORDER,
        payload: response?.data
      });
    } catch (error) {
      if (error?.response?.status) {
        dispatch({
          type: SEARCH_SCANPACK_ORDER_FAIL,
          payload: error?.response?.status
        });
      }
    }
  };
}
export function ResetSearchOrderData() {
  return async dispatch => {
    dispatch({
      type: RESET_SCANPACK_ORDER
    });
  };
}

export function UpdateProduct(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/products/update_product_list.json?id=${data.id}&var=${data.name}&value=${data.value}`,
      headers: {Authorization: `Bearer ${access_token}`}
      // data: data
    })
      .then(data => {
        dispatch({
          type: UPDATE_PRODUCT,
          payload: data.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_PRODUCT_FAIL,
          payload: error.response.status
        });
      });
  };
}
export function ImportOrder() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/orders/import_all.json`,
      headers: {Authorization: `Bearer ${access_token}`}
      // data: data
    })
      .then(data => {
        dispatch({
          type: IMPORT_ORDER_SUCCESS,
          payload: data.data
        });
        return dispatch(ImportOrderStatus());
      })
      .catch(error => {
        dispatch({
          type: IMPORT_ORDER_FAIL,
          payload: error.response.status
        });
      });
  };
}

export function ImportOrderStatus() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/home/import_status`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(data => {
        dispatch({
          type: IMPORT_ORDER_STATUS_SUCCESS,
          payload: data.data
        });
      })
      .catch(error => {
        dispatch({
          type: IMPORT_ORDER_STATUS_FAIL,
          payload: error?.response?.status
        });
      });
  };
}

export function FullOrderScan(orderID) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/scan_pack/verify_order_scanning`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: `id=${orderID}&app_url=${window.location.origin}`
    })
      .then(res => {
        dispatch({
          type: FULL_ORDER_SCAN_CHECK
        });
      })
      .catch(error => {
        dispatch({
          // type: REMOVE_PACKING_IMAGE_FAIL
        });
      });
  };
}

export function detectOrdersDiscrepancies(orders_data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/scan_pack/detect_discrepancy`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: {data: orders_data, app_url: window.location.origin}
    })
      .then(res => {
        dispatch({
          type: DETECT_ORDERS_DISCREPANCIES,
          payload: res?.data
        });
      })
      .catch(error => {
        console.log('error in discrepancy function => ', error);
      });
  };
}

export function SetDashboardData(data) {
  return dispatch => {
    dispatch({
      type: DASHBOARD_DATA,
      payload: data
    });
  };
}
export function CheckImportOrderProcess(storeId) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/order_import_summary/get_import_details?store_id=${storeId}`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(async data => {
        const {
          total_imported,
          last_imported_data,
          import_start,
          import_end,
          elapsed_time
        } = data.data;

        const dataToStore = {
          imported_orders: total_imported,
          imported_date: last_imported_data,
          import_start,
          import_end,
          elapsed_time
        };

        for (const [key, value] of Object.entries(dataToStore)) {
          await AsyncStorage.setItem(key, JSON.stringify(value));
        }
        dispatch({
          type: CHECK_IMPORT_ORDER_STATUS_SUCCESS,
          payload: data.data
        });
      })
      .catch(error => {
        dispatch({
          type: CHECK_IMPORT_ORDER_STATUS_FAIL,
          payload: error?.response?.status
        });
      });
  };
}
