import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import downArrow from '../../../assets/down_arrow.png';
import usePersistentMenuState from '../../commons/usePersistentMenuState';
import useScanToCartWorkflowEnabled from '../../helpers/useScanToCartWorkflowEnabled';


const CustomTouchableOpacity = ({ onPress, selectedStatus, handleStatusSelect, disableFilter, buttonName, buttonText, orderCount, testID }) => {
  const getCountOfAwaitingItems = () => {
    const { awaiting, partially_scanned, scanned, cancelled, serviceissue,pick_in_progress,picked, onhold } = orderCount;

    switch (buttonName.toLowerCase()) {
    case 'awaiting':
      return awaiting || 0;
    case 'cancelled':
      return cancelled || 0;
    case 'scanned':
      return scanned || 0;
    case 'partiallyscanned':
      return partially_scanned || 0;
    case 'onhold':
      return onhold || 0;
    case 'serviceissue':
      return serviceissue || 0;
    case 'pick_in_progress':
      return pick_in_progress || 0;
    case 'picked':
      return picked || 0;
    default:
      return 0;
  }
  }

  return (
    <TouchableOpacity onPress={onPress} style={{ paddingLeft: 5 }} testID={testID}>
      <View style={styles.buttonContainer}>
        <Text style={styles.buttonLabel}>{orderCount && getCountOfAwaitingItems()}</Text>
        <Text style={[handleStatusSelect?.includes(buttonName) && styles.dropdownActiveButton,
        styles.activeButtonLabel
        ]}>
          {buttonText || buttonName}
        </Text>
        <TouchableOpacity onPress={disableFilter} style={[{marginHorizontal: '1%'}]} testID={testID+'_reset'}>
        {handleStatusSelect.includes(selectedStatus) &&
          <i className="icon-cancel" style={{fontSize: '15px', color: 'gray'}}></i>
        }
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};


const OrderStatusDropdown = ({getFilteredOrders, resetFilters, clearAllFilters, resetFilterCheck, status, orderCount}) => {
  const [isOpen, toggleDropdown] = usePersistentMenuState('statusDropdown');
  const [selectedStatus, setSelectedStatus] = useState(() => status);
  const isScanToCartEnabled = useScanToCartWorkflowEnabled();


  const handleStatusSelect = (status) => {
    let statues;
    let isPresent = false;
    setSelectedStatus(prevSelectedStatus => {
      if (!prevSelectedStatus.includes(status)) {
        statues = [...prevSelectedStatus, status];
        return [...prevSelectedStatus, status];
    } else {
        statues = [prevSelectedStatus];
        isPresent = true;
        return prevSelectedStatus;
    }
    });
    isPresent === true ? disableFilter(status) : getFilteredOrders(statues)
  };

  const disableFilter = (statusToRemove) => {
    let updatedStatus = [];
    setSelectedStatus(prevSelectedStatus => {
        updatedStatus = prevSelectedStatus.filter(status => status !== statusToRemove);
        return updatedStatus;
    });
    resetFilters(updatedStatus);
  };

  useEffect(() => {
      if(clearAllFilters === true)
      {
        setSelectedStatus([]);
        resetFilterCheck();
      }
  }, [clearAllFilters]);
  useEffect(() => {
      setSelectedStatus(status)
  }, [status]);

  return (
    <View style={{borderTop: '2px solid rgb(0, 0, 0)'}}>
      <TouchableOpacity style={styles.statusDropDownButton} onPress={toggleDropdown}>
        <View style={styles.statusButtonContainer}>
          <Text style={styles.statusLabel}>
            Status
          </Text>
          <Image style={[styles.dateRangeImage, isOpen && styles.iconOpen]} source={downArrow} />
        </View>

      </TouchableOpacity>
      {isOpen && (
        <View style={{ marginTop: 10 }}>
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('Awaiting')}
            selectedStatus={'Awaiting'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('Awaiting')}}
            buttonName="Awaiting"
            orderCount={orderCount}
            testID="awaiting_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('Scanned')}
            selectedStatus={'Scanned'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('Scanned')}}
            buttonName="Scanned"
            orderCount={orderCount}
            testID="scanned_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('Cancelled')}
            selectedStatus={'Cancelled'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('Cancelled')}}
            buttonName="Cancelled"
            orderCount={orderCount}
            testID="cancelled_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('ServiceIssue')}
            selectedStatus={'ServiceIssue'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('ServiceIssue')}}
            buttonName="ServiceIssue"
            buttonText="Service Issue"
            orderCount={orderCount}
            testID="service_issue_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('PartiallyScanned')}
            selectedStatus={'PartiallyScanned'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('PartiallyScanned')}}
            buttonName="PartiallyScanned"
            buttonText="Partially Scanned"
            orderCount={orderCount}
            testID="partically_scanned_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('OnHold')}
            selectedStatus={'OnHold'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('OnHold')}}
            buttonName="OnHold"
            buttonText="Action Required"
            orderCount={orderCount}
            testID="action_required_button"
          />
          {isScanToCartEnabled && <><CustomTouchableOpacity
            onPress={() => handleStatusSelect('Pick_In_Progress')}
            selectedStatus={'Pick_In_Progress'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('PickInProgress')}}
            buttonName="Pick_In_Progress"
            buttonText="Pick In Progress"
            orderCount={orderCount}
            testID="pick_in_progress_button"
          />
          <CustomTouchableOpacity
            onPress={() => handleStatusSelect('Picked')}
            selectedStatus={'Picked'}
            handleStatusSelect={selectedStatus}
            disableFilter={() => {disableFilter('Picked')}}
            buttonName="Picked"
            orderCount={orderCount}
            testID="picked_button"
          />
          </>
          }
        </View>
      )}
    </View>
  );
};


const styles = StyleSheet.create({
  dropdownActiveButton: {
    border: '1px solid gray',
    borderRadius: 8,
    paddingTop: 2,
  },
  dateRangeImage: {
    width: 15,
    height: 15,
    marginRight: '4%',
    marginTop: '1.5%',
    color: 'gray'
  },
  iconOpen: {
    transform: [{rotate: '180deg'}]
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems:'center'
  },
  buttonLabel:{
    color: 'white',
    width: '16%',
    fontSize: '12px',
    textAlign: 'right'
  },
  activeButtonLabel:{
    color: 'white',
    paddingBottom: 2,
    fontSize: '15px',
    paddingLeft: 10,
    width: '65%',
    marginLeft: '1%',
    fontFamily: 'Poppins_300Light',
    marginBottom: '2px'
  },
  statusLabel: {
    color: '#9BA7B4',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 13,
    padding: 5
  },
  statusDropDownButton: {
    maxWidth: '107%',
    backgroundColor: '#262B30',
    marginLeft: '-1px'
  },
  statusButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});


export default OrderStatusDropdown;
