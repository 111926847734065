import React, { useEffect, useRef, useState } from 'react';
import { View, ScrollView, StyleSheet, Animated, Easing, Dimensions } from 'react-native';

const AutoScrollWrapper = ({
  children,
  enabled = true,
  speed = 35,
  pauseAtEnds = 2500,
  easing = Easing.linear,
  showIndicators = true,
  style = {},
}) => {
  const scrollViewRef = useRef(null);
  const scrollAnimatedValue = useRef(new Animated.Value(0)).current;
  const [scrollViewWidth, setScrollViewWidth] = useState(0);
  const [scrollContentWidth, setScrollContentWidth] = useState(0);
  const animationRef = useRef(null);
  const autoScrollEnabled = useRef(enabled);
  const shouldScroll = scrollContentWidth > scrollViewWidth;

  const getScrollDuration = (distance) => {
    return (distance / speed) * 1000;
  };

  const startScrollingRight = () => {
    if (!enabled || !scrollViewRef.current || !autoScrollEnabled.current) return;

    const scrollableDistance = scrollContentWidth - scrollViewWidth;
    if (scrollableDistance <= 0) return;

    const currentPosition = scrollAnimatedValue.__getValue();
    const remainingDistance = scrollableDistance - currentPosition;
    const duration = getScrollDuration(remainingDistance);

    if (animationRef.current) {
      animationRef.current.stop();
    }

    animationRef.current = Animated.timing(scrollAnimatedValue, {
      toValue: scrollableDistance,
      duration: duration,
      easing: easing,
      useNativeDriver: true,
    });

    animationRef.current.start(({ finished }) => {
      if (finished && autoScrollEnabled.current) {
        setTimeout(() => {
          if (autoScrollEnabled.current) {
            startScrollingLeft();
          }
        }, pauseAtEnds);
      }
    });
  };

  const startScrollingLeft = () => {
    if (!enabled || !scrollViewRef.current || !autoScrollEnabled.current) return;

    const currentPosition = scrollAnimatedValue.__getValue();
    const duration = getScrollDuration(currentPosition);

    if (animationRef.current) {
      animationRef.current.stop();
    }

    animationRef.current = Animated.timing(scrollAnimatedValue, {
      toValue: 0,
      duration: duration,
      easing: easing,
      useNativeDriver: true,
    });

    animationRef.current.start(({ finished }) => {
      if (finished && autoScrollEnabled.current) {
        setTimeout(() => {
          if (autoScrollEnabled.current) {
            startScrollingRight();
          }
        }, pauseAtEnds);
      }
    });
  };

  useEffect(() => {
    autoScrollEnabled.current = enabled;
    
    if (
      enabled &&
      scrollViewWidth > 0 &&
      scrollContentWidth > 0 &&
      scrollContentWidth > scrollViewWidth
    ) {
      startScrollingRight();
    } else if (!enabled && animationRef.current) {
      animationRef.current.stop();
    }

    return () => {
      autoScrollEnabled.current = false;
      if (animationRef.current) {
        animationRef.current.stop();
      }
    };
  }, [scrollViewWidth, scrollContentWidth, enabled]);

  const handleScrollViewLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setScrollViewWidth(width);
  };

  const handleContentSizeChange = (width, height) => {
    setScrollContentWidth(width);
  };

  useEffect(() => {
    const id = scrollAnimatedValue.addListener(({ value }) => {
      if (scrollViewRef.current && scrollViewRef.current.scrollTo) {
        scrollViewRef.current.scrollTo({ x: value, animated: false });
      }
    });

    return () => {
      scrollAnimatedValue.removeListener(id);
    };
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      if (animationRef.current) {
        animationRef.current.stop();
      }

      setScrollViewWidth(0);
      setScrollContentWidth(0);
    };

    const dimensionsHandler = Dimensions.addEventListener('change', updateDimensions);

    return () => {
      dimensionsHandler.remove();
    };
  }, []);

  return (
    <View style={[styles.container, style]} onLayout={handleScrollViewLayout}>
      <ScrollView
        ref={scrollViewRef}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        scrollEnabled={!enabled}
        onContentSizeChange={handleContentSizeChange}
        contentContainerStyle={styles.scrollContent}
      >
        {children}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
  scrollContent: {
    // Default scroll content styling
  },
  scrollIndicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 40,
    zIndex: 10,
  },

});

export default AutoScrollWrapper;
