import React from 'react';
import { Animated } from 'react-native';
import Svg, {
  Rect,
  Text as SvgText,
  LinearGradient,
  Stop,
  Defs,
  Line
} from 'react-native-svg';
import { generateGridLines, truncateLabel } from './chartUtils';

const AnimatedRect = Animated.createAnimatedComponent(Rect);
const AnimatedSvgText = Animated.createAnimatedComponent(SvgText);

export const ChartBackground = ({ width, height, fill = "#0E1317", rx = 3, ry = 3 }) => (
  <Rect
    x={0}
    y={0}
    width={width}
    height={height}
    fill={fill}
    rx={rx}
    ry={ry}
  />
);

export const ChartBar = ({ 
  x, 
  y, 
  width, 
  height, 
  gradientId, 
  rx = 15, 
  ry = 15, 
  opacity = 0.7 
}) => (
  <AnimatedRect
    x={x}
    y={y}
    width={width}
    height={height}
    fill={`url(#${gradientId})`}
    rx={rx}
    ry={ry}
    opacity={opacity}
  />
);

export const BarValue = ({ 
  x,
  y,
  value,
  fill = "white",
  fontSize = 80,
  fontWeight = "bold",
  fontFamily = "Poppins_600SemiBold",
  stroke = "#00000040",
  strokeWidth = "1",
  strokeLinejoin = "round"
}) => (
  <AnimatedSvgText
    x={x}
    y={y}
    fill={fill}
    textAnchor="middle"
    fontSize={fontSize}
    fontWeight={fontWeight}
    fontFamily={fontFamily}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinejoin={strokeLinejoin}
  >
    {value}
  </AnimatedSvgText>
);

export const BarLabel = ({
  x,
  y,
  label,
  fill = "white",
  fontSize = 30,
  fontWeight = "bold",
  fontFamily = "Poppins_600SemiBold",
  stroke = "#00000040",
  strokeWidth = "0.5",
  strokeLinejoin = "round"
}) => (
  <SvgText
    x={x}
    y={y}
    fill={fill}
    textAnchor="middle"
    fontSize={fontSize}
    fontWeight={fontWeight}
    fontFamily={fontFamily}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinejoin={strokeLinejoin}
  >
    {label || ''}
  </SvgText>
);

export const GradientDefinitions = ({ data }) => (
  <Defs>
    {data.map((item, index) => (
      <LinearGradient
        key={`gradient-${index}`}
        id={`gradient-${index}`}
        x1="0"
        y1="0"
        x2="0"
        y2="1">
        <Stop
          offset="0"
          stopColor={
            item.gradientStart || item.color || '#cccccc'
          }
          stopOpacity="1"
        />
        <Stop
          offset="1"
          stopColor={
            item.gradientEnd || item.color || '#cccccc'
          }
          stopOpacity="1"
        />
      </LinearGradient>
    ))}
  </Defs>
);

export const GridLinesComponent = ({ chartHeight, chartWidth, segments = 6, color = "rgba(255,255,255,0.1)" }) => {
  const lines = generateGridLines(chartHeight, chartWidth, segments, color);
  
  return (
    <>
      {lines.map(line => (
        <Line
          key={line.key}
          x1={line.x1}
          y1={line.y1}
          x2={line.x2}
          y2={line.y2}
          stroke={line.stroke}
          strokeWidth={line.strokeWidth}
          strokeDasharray={line.strokeDasharray}
        />
      ))}
    </>
  );
};

export const ChartContent = ({ 
  chartHeight, 
  chartWidth, 
  labelSpaceHeight, 
  segmentsCount, 
  data,
  barWidth,
  spacing,
  maxValue,
  animations,
  GridLines
}) => {
  return (
    <Svg
      height={chartHeight + labelSpaceHeight}
      width={chartWidth}
      style={{ overflow: 'visible' }}>
      
      <GradientDefinitions data={data} />
      
      <ChartBackground width={chartWidth} height={chartHeight} />
      
      <GridLines
        chartHeight={chartHeight}
        chartWidth={chartWidth}
        segments={segmentsCount}
      />

      {data.map((item, index) => {
        const barHeight = (item.value / maxValue) * chartHeight;
        const barX = index * (barWidth + spacing);
        const barCenter = barX + barWidth / 2;
        const MIN_HEIGHT_FOR_COUNT = 90;
        const hasSpaceForCount = barHeight >= MIN_HEIGHT_FOR_COUNT;

        const animationValue = animations[`bar-${index}`] || new Animated.Value(0);

        const animatedHeight = animationValue.interpolate({
          inputRange: [0, 1],
          outputRange: [0, barHeight]
        });

        const animatedY = animationValue.interpolate({
          inputRange: [0, 1],
          outputRange: [chartHeight, chartHeight - barHeight]
        });

        const displayValue = typeof item.value === 'number'
          ? item.value
          : (item.value || 0).toString();

        return (
          <React.Fragment key={index}>
            <ChartBar
              x={barX}
              y={animatedY}
              width={barWidth}
              height={animatedHeight}
              gradientId={`gradient-${index}`}
            />
            
            {hasSpaceForCount ? (
              <BarValue
                x={barCenter}
                y={Animated.add(animatedY, 70)}
                value={displayValue}
              />
            ) : (
              <BarValue
                x={barCenter}
                y={Animated.subtract(animatedY, 10)}
                value={displayValue}
              />
            )}

            <BarLabel
              x={barCenter}
              y={chartHeight + 35}
              label={truncateLabel(item.label)}
            />
          </React.Fragment>
        );
      })}
    </Svg>
  );
};
