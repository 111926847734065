import { ADD_CART, DELETE_CART, FETCH_CARTS, UPDATE_CART, UPDATE_CART_LOCAL } from "../constants";

const initialState = {
  carts: [],
  loading: false,
  error: null
};

export default function scanToCartReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CART_LOCAL:
      return {
        ...state,
        carts: state.carts.map(cart => 
          cart.id === action.payload.id ? action.payload : cart
        )
      };

    case ADD_CART:
      return {
        ...state,
        carts: [...state.carts, action.payload],
        loading: false,
        error: null
      };
    case UPDATE_CART:
      return {
        ...state,
        carts: state.carts.map(cart => {
          if (cart.id === action.payload.id) {
            return action.payload;
          }
          return cart;
        }),
        loading: false,
        error: null
      };
    case DELETE_CART:
      return {
        ...state,
        carts: state.carts.filter(cart => cart.id !== action.payload),
        loading: false,
        error: null
      };
    case FETCH_CARTS:
      return {
        ...state,
        carts: action.payload,
        loading: false,
        error: null
      };
    default:
      return state;
  }
}
