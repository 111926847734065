import React, {useEffect, useState, useRef} from 'react';
import {
  View,
  Dimensions,
  Text,
  StyleSheet,
  Animated,
  Easing,
  Platform
} from 'react-native';
import Svg, {
  Rect,
  Text as SvgText,
  LinearGradient,
  Stop,
  Defs,
  G
} from 'react-native-svg';
import {useDispatch, useSelector} from 'react-redux';
import {getStatisticsWeeklyDetails} from '../../../actions/statisticsAction';
import {customColors} from '../../../commons/tests/mocks/mockdata/colors';
import moment from 'moment/moment';
import {getOptimalFontSize, shadeColor, lightenColor, truncateLabel} from './chartUtils';
import {ChartLoading, GridLines, NoDataDisplay} from './ChartComponents';
import AutoScrollWrapper from './AutoScrollWrapper';
import usePolling from './usePolling';
import ChartTooltip from './ChartTooltip';

const AnimatedRect = Animated.createAnimatedComponent(Rect);
const AnimatedSvgText = Animated.createAnimatedComponent(SvgText);

const isWeb = Platform.OS === 'web';

const getNameDisplaySettings = stackHeight => {
  const valueFontSize = getOptimalFontSize(stackHeight);

  const minHeightForName = 80;
  const showName = stackHeight >= minHeightForName && valueFontSize > 0;

  const nameFontSize = showName ? Math.max(valueFontSize - 2, 10) : 0;

  return {
    showName,
    nameFontSize,
    valueFontSize
  };
};

const WeeklyStackedChart = () => {
  const dispatch = useDispatch();
  const response = useSelector(state => state.statistics.weeklyList);
  const [processedData, setProcessedData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [labels, setLabels] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [barWidth, setBarWidth] = useState(0);
  const [spacing, setSpacing] = useState(0);
  const [loading, setLoading] = useState(!response?.data?.statistics);
  const [animations, setAnimations] = useState({});
  const [shouldAutoScroll, setShouldAutoScroll] = useState(false);
  const animationDuration = 800;
  const [containerDimensions, setContainerDimensions] = useState({
    width: Dimensions.get('window').width * 0.95,
    height: 380
  });
  const [activeHover, setActiveHover] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({x: 0, y: 0});
  const chartContainerRef = useRef(null);
  const hasAnimated = useRef(false);
  const maxDaysToShow = 6;

  const onContainerLayout = event => {
    const {width, height} = event.nativeEvent.layout;
    setContainerDimensions({width, height});
  };

  const startAnimations = data => {
    const newAnimations = {};
    data.forEach((dayData, dayIndex) => {
      newAnimations[`day-${dayIndex}-total`] = new Animated.Value(0);
      dayData.stacks.forEach((_, stackIndex) => {
        newAnimations[`day-${dayIndex}-stack-${stackIndex}`] =
          new Animated.Value(0);
      });
    });

    setAnimations(newAnimations);
    setTimeout(() => {
      const totalAnimations = data.map((_, dayIndex) => {
        return Animated.timing(newAnimations[`day-${dayIndex}-total`], {
          toValue: 1,
          duration: animationDuration,
          easing: Easing.out(Easing.cubic),
          useNativeDriver: false
        });
      });

      const stackAnimations = data.flatMap((dayData, dayIndex) => {
        return dayData.stacks.map((_, stackIndex) => {
          return Animated.timing(
            newAnimations[`day-${dayIndex}-stack-${stackIndex}`],
            {
              toValue: 1,
              duration: animationDuration,
              easing: Easing.out(Easing.cubic),
              useNativeDriver: false
            }
          );
        });
      });

      const allAnimations = [...totalAnimations, ...stackAnimations];
      Animated.stagger(40, allAnimations).start();
    }, 300);
  };

  const processData = () => {
    const statisticsList = response?.data?.statistics || [];
    const userDetails = response?.data?.user_details || [];

    if (statisticsList.length === 0) {
      setProcessedData([]);
      return;
    }

    const dateLabels = statisticsList.map(item =>
      moment(item.date, 'ddd, DD MMM YYYY').format('ddd Do')
    );
    setLabels(dateLabels);
    const stackedBars = [];
    let highestTotal = 0;

    statisticsList.forEach(dayData => {
      const stacksForDay = [];
      let dayTotal = 0;

      if (dayData.data && dayData.data.length > 0) {
        dayData.data.forEach(userData => {
          const userId = userData.packing_user_id;
          const userInfo = userDetails.find(u => u.id === userId) || {};
          let userSum = 0;
          if (userData.data && userData.data.length > 0) {
            userSum = userData.data.reduce(
              (sum, item) => sum + (item.item_count || 0),
              0
            );
          }

          if (userSum > 0) {
            const mainColor = userInfo.color || '#cccccc';
            const lighterColor = lightenColor(mainColor, 30);
            const darkerColor = shadeColor(mainColor, -30);

            stacksForDay.push({
              userId,
              value: userSum,
              name: userInfo.name || `User ${userId}`,
              color: mainColor,
              gradientStart: lighterColor,
              gradientEnd: darkerColor
            });
            dayTotal += userSum;
          }
        });
      }
      stacksForDay.sort((a, b) => a.value - b.value);

      stackedBars.push({
        stacks: stacksForDay,
        total: dayTotal
      });

      if (dayTotal > highestTotal) {
        highestTotal = dayTotal;
      }
    });

    setMaxValue(Math.ceil(highestTotal * 1.05));
    setProcessedData(stackedBars);

    setShouldAutoScroll(stackedBars.length > maxDaysToShow);

    const userMap = new Map();
    stackedBars.forEach(bar => {
      bar.stacks.forEach(stack => {
        if (userMap.has(stack.userId)) {
          const userData = userMap.get(stack.userId);
          userData.totalValue += stack.value;
        } else {
          userMap.set(stack.userId, {
            id: stack.userId,
            label: stack.name,
            color: stack.color,
            totalValue: stack.value
          });
        }
      });
    });

    const sortedUsers = Array.from(userMap.values()).sort(
      (a, b) => b.totalValue - a.totalValue
    );
    setUsersList(sortedUsers);
  };

  usePolling(() => {
    dispatch(getStatisticsWeeklyDetails());
  }, 5000);

  useEffect(() => {
    if (response?.data?.statistics) {
      processData();
      setLoading(false);
    }
  }, [response?.data?.statistics]);

  useEffect(() => {
    if (processedData.length > 0 && !hasAnimated.current) {
      startAnimations(processedData);
      hasAnimated.current = true;
    }
  }, [processedData]);

  useEffect(() => {
    if (processedData.length > 0) {
      calculateBarDimensions();
    }
  }, [processedData, containerDimensions]);

  const calculateBarDimensions = () => {
    const yAxisWidth = 50;
    const availableWidth = containerDimensions.width - yAxisWidth - 40;
    const optimalBarCount = Math.min(maxDaysToShow, processedData.length);
    const totalBarSpace = availableWidth * 0.8;
    const totalSpacingSpace = availableWidth * 0.2;
    const calculatedBarWidth = totalBarSpace / optimalBarCount;
    const calculatedSpacing = totalSpacingSpace / (optimalBarCount - 1 || 1);

    setBarWidth(calculatedBarWidth);
    setSpacing(calculatedSpacing);
  };

  const chartHeight = containerDimensions.height * 0.95 - 40;

  const generateYAxisLabels = () => {
    const segments = 6;
    const labels = [];

    for (let i = 0; i <= segments; i++) {
      const value = Math.round((maxValue * (segments - i)) / segments);
      labels.push({
        value,
        position: (i * chartHeight) / segments
      });
    }

    return labels;
  };

  const renderLoading = () => {
    if (loading) {
      return <ChartLoading />;
    }

    if (processedData.length === 0) {
      return (
        <>
          <ChartLoading />
          <NoDataDisplay />
        </>
      );
    }
  };

  const handleStackHover = (event, dayIndex, stackIndex, stack, stackY, stackHeight,isLastStack) => {
    if (!isWeb) return;
    const rect = event.currentTarget.getBoundingClientRect();
    const stackCenter = rect.top + (rect.height / 2);

    setTooltipPosition({
      x: rect.right,
      y: stackCenter
    });
    
    setActiveHover({
      dayIndex,
      stackIndex,
      stack,
      stackY,
      stackHeight,
      isLastStack
    });
  };

  const handleStackLeave = () => {
    if (!isWeb) return;
    setActiveHover(null);
  };

  const calculatedWidth = processedData.length * (barWidth + spacing) - spacing;

  return (
    <View
      style={styles.container}
      ref={chartContainerRef}
      onLayout={onContainerLayout}>
      {processedData.length === 0 ? (
        renderLoading()
      ) : (
        <View style={styles.mainContent}>
          <View style={styles.chartArea}>
            <View style={styles.chartContainer}>
              <View style={styles.yAxisContainer}>
                {generateYAxisLabels().map((label, i) => (
                  <Text
                    key={i}
                    style={[styles.axisLabel, {top: label.position - 12}]}>
                    {label.value}
                  </Text>
                ))}
              </View>

              <View style={styles.chartBackground}>
                <AutoScrollWrapper
                  enabled={shouldAutoScroll}
                  speed={35}
                  pauseAtEnds={2500}
                  showIndicators={shouldAutoScroll}
                  style={styles.chartScrollContainer}>
                  <Svg
                    height={chartHeight + 40}
                    width={Math.max(
                      calculatedWidth,
                      containerDimensions.width - 70
                    )}
                    style={styles.chartSvg}>
                    <Defs>
                      {processedData.flatMap((dayData, dayIndex) =>
                        dayData.stacks.map((stack, stackIndex) => (
                          <LinearGradient
                            key={`gradient-${dayIndex}-${stackIndex}`}
                            id={`gradient-${dayIndex}-${stackIndex}`}
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1">
                            <Stop
                              offset="0"
                              stopColor={stack.gradientStart || stack.color}
                              stopOpacity="1"
                            />
                            <Stop
                              offset="1"
                              stopColor={stack.gradientEnd || shadeColor(stack.color, -20)}
                              stopOpacity="1"
                            />
                          </LinearGradient>
                        ))
                      )}
                    </Defs>
                    <GridLines
                      chartHeight={chartHeight}
                      chartWidth={Math.max(
                        calculatedWidth,
                        containerDimensions.width - 70
                      )}
                      segments={6}
                    />

                    {processedData.map((dayData, dayIndex) => {
                      let currentY = 0;
                      const barTotal = dayData.total || 0;
                      const barHeight = (barTotal / maxValue) * chartHeight;

                      return (
                        <React.Fragment key={`day-${dayIndex}`}>
                          {dayData.stacks.map((stack, stackIndex) => {
                            const stackPercentage = stack.value / barTotal;
                            const stackHeight = stackPercentage * barHeight;
                            const stackY = currentY;
                            currentY = currentY + stackHeight;

                            const {showName, nameFontSize, valueFontSize} =
                              getNameDisplaySettings(stackHeight);

                            return (
                              <React.Fragment
                                key={`stack-${dayIndex}-${stackIndex}`}>
                                {(() => {
                                  const totalAnimation =
                                    animations[`day-${dayIndex}-total`] ||
                                    new Animated.Value(0);
                                  const stackAnimation =
                                    animations[
                                      `day-${dayIndex}-stack-${stackIndex}`
                                    ] || new Animated.Value(0);
                                  const animatedStackHeight = Animated.multiply(
                                    stackAnimation,
                                    Animated.multiply(
                                      totalAnimation,
                                      new Animated.Value(stackHeight)
                                    )
                                  );

                                  const animatedY = Animated.add(
                                    chartHeight,
                                    Animated.multiply(
                                      -1,
                                      Animated.add(
                                        Animated.multiply(
                                          totalAnimation,
                                          new Animated.Value(stackY)
                                        ),
                                        animatedStackHeight
                                      )
                                    )
                                  );

                                  const textOpacity = Animated.multiply(
                                    totalAnimation,
                                    stackAnimation
                                  );

                                  return (
                                    <G
                                      {...(isWeb ? {
                                        onMouseEnter: (event) => handleStackHover(
                                          event,
                                          dayIndex, 
                                          stackIndex, 
                                          stack, 
                                          stackY, 
                                          stackHeight,
                                          dayIndex === processedData.length - 1
                                        ),
                                        onMouseLeave: handleStackLeave,
                                        cursor: 'pointer'
                                      } : {})}>
                                      <AnimatedRect
                                        x={dayIndex * (barWidth + spacing)}
                                        y={animatedY}
                                        width={barWidth}
                                        height={animatedStackHeight}
                                        fill={`url(#gradient-${dayIndex}-${stackIndex})`}
                                        rx={15}
                                        ry={15}
                                        opacity={0.7}
                                      />
                                      {valueFontSize > 0 && (
                                        <AnimatedSvgText
                                          x={
                                            dayIndex * (barWidth + spacing) +
                                            barWidth / 2
                                          }
                                          y={Animated.subtract(
                                            chartHeight,
                                            Animated.add(
                                              Animated.add(
                                                Animated.multiply(
                                                  totalAnimation,
                                                  new Animated.Value(stackY)
                                                ),
                                                Animated.multiply(
                                                  animatedStackHeight,
                                                  new Animated.Value(0.5)
                                                )
                                              ),
                                              new Animated.Value(
                                                showName
                                                  ? nameFontSize / 2 + 2
                                                  : 0
                                              )
                                            )
                                          )}
                                          fill="white"
                                          textAnchor="middle"
                                          fontWeight={'bold'}
                                          fontFamily="Poppins_600SemiBold"
                                          fontSize={valueFontSize}
                                          opacity={textOpacity}>
                                          {stack.value}
                                        </AnimatedSvgText>
                                      )}
                                      {showName && (
                                        <AnimatedSvgText
                                          x={
                                            dayIndex * (barWidth + spacing) +
                                            barWidth / 2
                                          }
                                          y={Animated.subtract(
                                            chartHeight,
                                            Animated.add(
                                              Animated.add(
                                                Animated.multiply(
                                                  totalAnimation,
                                                  new Animated.Value(stackY)
                                                ),
                                                Animated.multiply(
                                                  animatedStackHeight,
                                                  new Animated.Value(0.5)
                                                )
                                              ),
                                              new Animated.Value(
                                                showName
                                                  ? -(nameFontSize / 2 + 2) - 10
                                                  : 0
                                              )
                                            )
                                          )}
                                          fill="white"
                                          textAnchor="middle"
                                          fontWeight={'bold'}
                                          fontFamily="Poppins_600SemiBold"
                                          fontSize={nameFontSize}
                                          opacity={Animated.multiply(
                                            textOpacity,
                                            0.9
                                          )}>
                                          {truncateLabel(stack.name)}
                                        </AnimatedSvgText>
                                      )}
                                    </G>
                                  );
                                })()}
                              </React.Fragment>
                            );
                          })}
                          {barTotal > 0 &&
                            (() => {
                              const totalAnimation =
                                animations[`day-${dayIndex}-total`] ||
                                new Animated.Value(0);

                              return (
                                <AnimatedSvgText
                                  x={
                                    dayIndex * (barWidth + spacing) +
                                    barWidth / 2
                                  }
                                  y={Animated.add(
                                    chartHeight,
                                    Animated.multiply(
                                      -1,
                                      Animated.add(
                                        Animated.multiply(
                                          totalAnimation,
                                          new Animated.Value(barHeight)
                                        ),
                                        new Animated.Value(15)
                                      )
                                    )
                                  )}
                                  fill="white"
                                  textAnchor="middle"
                                  fontFamily="Poppins_600SemiBold"
                                  fontSize={26}
                                  opacity={totalAnimation}
                                  fontWeight="bold">
                                  {barTotal}
                                </AnimatedSvgText>
                              );
                            })()}
                          <AnimatedSvgText
                            x={dayIndex * (barWidth + spacing) + barWidth / 2}
                            y={chartHeight + 20}
                            fill="white"
                            textAnchor="middle"
                            fontFamily="Poppins_600SemiBold"
                            fontSize={18}
                            opacity={
                              animations[`day-${dayIndex}-total`] ||
                              new Animated.Value(0)
                            }>
                            {labels[dayIndex] || `Day ${dayIndex + 1}`}
                          </AnimatedSvgText>
                        </React.Fragment>
                      );
                    })}
                  </Svg>
                </AutoScrollWrapper>
              </View>
            </View>
          </View>
        </View>
      )}
      {activeHover && (
        <ChartTooltip 
          visible={!!activeHover}
          position={tooltipPosition}
          isLastStack={activeHover?.isLastStack}
          data={{
            stack: activeHover.stack,
            dayLabel: labels[activeHover.dayIndex] || `Fri June 21`
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#191919'
  },
  mainContent: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingVertical: 10,
    paddingHorizontal: 5
  },
  chartArea: {
    flex: 1,
    height: '100%'
  },
  chartContainer: {
    flexDirection: 'row',
    height: '100%',
    paddingRight: 5
  },
  chartBackground: {
    flex: 1,
    backgroundColor: '#0E1317',
    borderRadius: 4,
    overflow: 'hidden'
  },
  chartScrollContainer: {
    flex: 1,
    height: '100%',
    paddingTop: 15,
    paddingBottom: 5,
    paddingHorizontal: 10
  },
  yAxisContainer: {
    width: 50,
    height: '100%',
    paddingRight: 5,
    justifyContent: 'flex-start'
  },
  axisLabel: {
    color: customColors.white,
    position: 'absolute',
    right: 5,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 16
  },
  chartSvg: {
    overflow: 'visible',
    paddingTop: 5
  }
});

export default WeeklyStackedChart;
