import axios from "axios";
import AsyncStorage from '@react-native-async-storage/async-storage';
export function filterScannedList(items) {
  if (items?.length > 0) {
    const result = Object.values(
      items.reduce((acc, item) => {
        const { product_id, qty_remaining, scanned_qty, total_qty, ...rest } = item;
        if (acc[product_id]) {
          acc[product_id].scanned_qty += scanned_qty;
          acc[product_id].qty_remaining += qty_remaining;
        } else {
          acc[product_id] = { product_id, scanned_qty, qty_remaining, total_qty, ...rest };
        }
        return acc;
      }, {})
    );
    return result;
  }
  return []
}

export function SortList(items) {
  const list = items
  list?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
}

export function getItemSkuByOrderItemId(orderItemId, unscannedItems) {
  if (Array.isArray(unscannedItems)) {
    const item = unscannedItems.find(item => item.order_item_id === orderItemId);


    if (item) {
      return item.sku;
    }
  }
};

export const getUpdatedSelectedList = (e, isShiftPressed, perviousSelectedOrders) => {
  let selected = e.selected;
  if (selected === true || selected === false) {
    const indicesToRemove = Object.keys(e.unselected || {});

    let selectedObjects = [...e.originalData];

    if (indicesToRemove.length > 0) {
      indicesToRemove.forEach(strIndex => {
        const index = parseInt(strIndex, 10) - 1;
        if (index >= 0 && index < selectedObjects.length) {
          selectedObjects.splice(index, 1);
        }
      });
      selected = selectedObjects;
    } else {
      if (e?.data?.length === undefined) {
        selected = [...e.originalData];
      } else {
        selected = e.data;
      }
    }
  }
  if (isShiftPressed && perviousSelectedOrders) {
    selected = handleShiftKeySelection(e, perviousSelectedOrders, selected);
  }
  return selected;
};

export const handleShiftKeySelection = (e, perviousSelectedOrders, selected) => {
  const lastSelectedOrders = {...perviousSelectedOrders};
  const originalData = [...e.originalData];
  const data = {...e.data};
  const unSelectedOrders = Object.keys(e.unselected || {});

  if (unSelectedOrders.length > 0) {
    return selected;
  }

  try {
    const lowerLimitId = Object.values(lastSelectedOrders)
      .filter(order => order.id < data.id)
      .reduce((prev, curr) => (curr.id > prev.id ? curr : prev), {
        id: -Infinity
      });

    const extractedRecords = originalData.filter(
      item => item.id >= lowerLimitId.id && item.id <= data.id
    );

    const mergedObject = {};
    Object.values(lastSelectedOrders).forEach(order => {
      mergedObject[order.id] = order;
    });
    extractedRecords.forEach(record => {
      mergedObject[record.id] = record;
    });

    const updatedList = getTransformedList(mergedObject);
    return updatedList;
  } catch (error) {
    console.log('error', error);
    return selected;
  }
};

export const getSelectedOrderIds = data => {
  const orderIds = Object.values(data || {}).flatMap(pageSelections =>
    Object.values(pageSelections).map(item => ({id: item.currentItem?.id}))
  );
  return orderIds;
}

export const getSelectedOrders = data => {
  const selectedOrdersArray = Object.values(data || {}).reduce(
    (allSelections, pageSelections) =>
      allSelections.concat(Object.values(pageSelections)),
    []
  );
  return selectedOrdersArray;
}

export const getTransformedList = (data) => {
  const transformedData = {};
  Object.values(data || {})?.forEach(item => {
    transformedData[item.id] = item;
  });
  return transformedData;
}


export async function getLiveStatsData() {
  try {
      const access_token = await AsyncStorage.getItem('access_token');
      const tenent = await AsyncStorage.getItem('tenent');
      const response = await axios.get(
        'https://api-gl.groove-packer.com/dashboard/calculate',
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${access_token}`,
            domain: 'groovepackerapi.com',
            tenant: tenent
          }
        }
      );

      // console.log('Dashboard data:', response.data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
}

export const getUserName = (userId, usersData) =>
  usersData?.find(user => user.id === userId)?.username || 'Unassigned';

export const getUserIdLabelArray = usersData => [
  {id: 'unassigned', label: 'Unassigned'},
  ...(usersData?.map(user => ({
    id: user.username,
    label: user.username
  })) || [])
];
export const getTagIdLabelArray = tags => {
  if (tags && tags.data) {
    return tags.data
      .filter(tag => tag.name && tag.name.length > 0)
      .map(tag => ({
        id: tag.name,
        label: tag.name
      }));
  }
  return [];
};

export const processUserAndTagFilter = (filterValue, includedTags) => {
  let selectedUserFilter = filterValue.find(item => item.name === 'User');
  let selectedTagFilter = filterValue.find(item => item.name === 'Tags');
  let selectedOSLMTFilter = filterValue.find(item => item.name === 'OSLMT');

  const selectedUser =
    selectedUserFilter?.value?.length > 0 ? [selectedUserFilter.value] : [];
  const selectedTag =
    selectedTagFilter?.value?.length > 0 ? [selectedTagFilter.value] : [];

  let updatedFilterValue = [...filterValue];
  if (selectedUser?.length > 0 || selectedTag.length > 0) {
    updatedFilterValue = updatedFilterValue.filter(
      item => item.name !== 'User' && item.name !== 'Tags'
    );
  }
  if (selectedOSLMTFilter) {
    updatedFilterValue = updatedFilterValue.map(item =>
      item.name === 'OSLMT'
        ? { ...item, name: 'OrderDate' }
        : item
    );
  }
  const isTagIncluded = selectedTagFilter?.value?.some(tagValue => 
    includedTags?.some(item => item.name === tagValue)
  );
  return {selectedUser, selectedTag,isTagIncluded, updatedFilterValue};
};


export const priorityListDisplayToggle = (id, state, setState) => {
  if (id === 4) {
      reloadGird(state)
      return;
  }
  const isLowPriority = id < 2;

  setState({
      ...state,
      selectedMenuId: id,
      displayPriorityList: !isLowPriority
  });
};

export const reloadGird = state => {
  let temp = state.gridRef?.current.getColumnFilterValue('OrderNumber')?.value;
  state.gridRef?.current.setColumnFilterValue('OrderNumber', '-1');
  setTimeout(() => {
    state.gridRef?.current.setColumnFilterValue('OrderNumber', temp);
  }, 500);
};

