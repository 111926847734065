import { useSelector } from 'react-redux';

const useScanToCartWorkflowEnabled = () => {
  const { bothSettings, settings } = useSelector(state => state.user);

  const scanPackWorkflow = settings?.data?.scan_pack_workflow;
  const scanToCartOption = bothSettings?.data?.data?.scanpack_setting?.scan_to_cart_option;

  return scanPackWorkflow === 'scan_to_cart' && scanToCartOption;
};

export default useScanToCartWorkflowEnabled;
