import React from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import I18n from 'i18n-js';

const CartErrorPrompt = ({ setShowPrompt }) => {
  return (
    <View style={styles.responseView}>
      <LinearGradient
        locations={[0, 1]}
        start={{ x: 0, y: 1 }}
        end={{ x: 0, y: 0 }}
        colors={['#d40202', '#e53935']}
        style={styles.promptContainer}>
        <View style={styles.cancelIcon}>
          <TouchableOpacity testID="CloseErrorPrompt" onPress={() => setShowPrompt(false)}>
            <i
              className="icon-cancel"
              style={{ fontSize: 26, color: '#ffffff' }}></i>
          </TouchableOpacity>
        </View>

        <View style={styles.messageContainer}>
          <Text style={styles.messageText}>
            {I18n.t('scanToCart.cartUpdateError')}
          </Text>
        </View>

        <TouchableOpacity
          style={styles.okButton}
          testID='OkButton'
          onPress={() => setShowPrompt(false)}>
          <LinearGradient
            locations={[0, 0.7, 1]}
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
            colors={['#ffffff', '#f5f5f5', '#e0e0e0']}
            style={styles.actionButton}>
            <Text style={styles.buttonText}>{I18n.t('scanToCart.okButtonLabel')}</Text>
          </LinearGradient>
        </TouchableOpacity>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  responseView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1000,
    width: '100%',
    height: '100%'
  },
  promptContainer: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    width: '90%',
    maxWidth: 550,
    marginTop: 0,
    borderRadius: 5,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    position: 'relative'
  },
  cancelIcon: {
    display: 'flex',
    alignSelf: 'flex-end'
  },
  messageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    padding: 15,
  },
  messageText: {
    fontFamily: 'Poppins_300Light',
    fontSize: 18,
    textAlign: 'center',
    color: '#ffffff',
    fontWeight: '600',
    marginBottom: 10
  },
  okButton: {
    width: '100%',
    alignItems: 'center',
    marginTop: 10
  },
  actionButton: {
    borderRadius: 20,
    paddingVertical: 8,
    paddingHorizontal: 16,
    minWidth: '40%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {
    color: '#d40202',
    fontFamily: 'Poppins_300Light',
    fontSize: 16,
    fontWeight: '500'
  }
});

export default CartErrorPrompt;
