import React, {useState} from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity
} from 'react-native';
import i18n from 'i18n-js';
import {customColors} from '../../commons/tests/mocks/mockdata/colors';
import {fontFamily} from '../../helpers/fontFamily';
import {LinearGradient} from 'expo-linear-gradient';

const CountDownChart = props => {
  const {time, onClick} = props;
  const [second, setSecond] = useState(time);

  const handleClick = () => {
    onClick(second);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{i18n.t('directprinting.slideShow')}</Text>
      <View style={{flexDirection: 'row'}}>
        <TextInput
          testID="second-input"
          autoFocus={true}
          placeholder={i18n.t('directprinting.enterNumberOfSeconds')}
          value={second}
          keyboardType="number-pad"
          onChangeText={value => {
            setSecond(value);
          }}
          style={styles.slideShowInputWrapper}
        />
        <TouchableOpacity style={{alignSelf: 'center'}} testID='save-button' onPress={handleClick}>
          <LinearGradient
            colors={['#95abbf', '#516b83']}
            style={styles.slideShowButtonWrapper}>
            <Text style={styles.slideShowTextWrapper}>
              {i18n.t('directprinting.saveLabel')}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20
  },
  heading: {
    color: customColors.white,
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 10
  },
  slideShowInputWrapper: {
    borderRadius: 5,
    fontSize: 14,
    fontFamily: fontFamily.font400,
    backgroundColor: customColors.mistyRose,
    color: customColors.SlateGray,
    borderColor: customColors.lightSteelBlue,
    borderWidth: 2,
    width: 200,
    height: 40,
    paddingLeft: 10
  },
  slideShowButtonWrapper: {
    marginLeft: 15,
    borderRadius: 10,
    borderColor: customColors.blueSlate,
    borderWidth: 2,
    borderStyle: 'solid',
    paddingHorizontal: 11,
    paddingVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    display: 'flex',
    flexDirection: 'row'
  },
  slideShowTextWrapper: {
    color: customColors.white,
    fontFamily: fontFamily.font300,
    fontSize: 16,
    weight: 300,
    lineHeight: 18,
    textAlign: 'center'
  }
});

export default CountDownChart;
