import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, Vibration } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { fontFamily } from "../helpers/fontFamily";
import * as Clipboard from "expo-clipboard";
import AddBarcodeButton from "./addBarcodeButton";
import ScrollAbleText from "./ScrollAbleText";
import { useSelector } from "react-redux";
const OrderItemSuggested = ({
  redirectToItemDetail,
  item,
  isSwap,
  numberOfKeysEven,
  testID,
  addBarcodeFun,
  name,
  displayRowAtTop,
  getFontSize,
  displayRow,
  width,
}) => {
  const copyTextToClipboard = async (text) => {
    await Clipboard.setStringAsync(`${text}`);
  };
  const ScrollAbleWidthObj = {
    '30%' : '100%',
    '35%' : '45%',
    '40%' : '50%',
    '45%' : '60%',

  }
  const GetNameWidth = {
    '30%' : '35%',
    '35%' : '40%',
    '40%' : '50%',
    '45%' : '60%',
    '50%' : '50%'

  }
  const getFont = (length) => {
    if(width > 900) {
      return length <= 32 ? 38 : 28
    }
    return length <= 32 ? 24 : 20
  }
  
  const getFontSizeMobile = () => {
    const windowWidth = Dimensions.get('window').width
    if (windowWidth > 700 && windowWidth < 870) {
      return '75%';
    }
    return "55%"
  } 
  const isHaptic = useSelector((state) => state.menu.isHaptics);
  return (
    <View
      dataSet={{ componentName: name }}
      style={width > 900 ? styles.subContainer : styles.subContainerMobile}
    >
      <LinearGradient
        colors={["#b6c2d2", "#ddedfc", "#dceafc"]}
        locations={[0, 0.34, 1]}
        style={
          width > 900
            ? styles.gradientContainer
            : styles.gradientContainerMobile
        }
      >
        <TouchableOpacity
          testID={testID}
          onPress={() => redirectToItemDetail(item)}
          delayLongPress={1000}
          style={{height: width > 900 ? '100%':''}}
        >
          <Text
            dataSet={{ componentName: 'SuggestedItemName'}} 
            numberOfLines={3}
            ellipsizeMode="tail"
            style={[
              width > 900 ? styles.nameText : styles.nameTextMobile,
              {
                fontSize: getFont(item.name.length),
                width: width > 900 ? GetNameWidth[getFontSize()] : getFontSizeMobile(),
                height: width > 900 ? '100%':''
              },
            ]}
          >
            {item.name}
          </Text>
        </TouchableOpacity>
      </LinearGradient>
      {item.sku && (
        <View style={styles.skuContainer} dataSet={{ componentName: 'suggestedItemSkuDesktop' }}>
          <View style={{ width:"100%" }}>
            <ScrollAbleText
              onPress={() => {
                isHaptic && Vibration.vibrate(30)
                copyTextToClipboard(item.sku);
              }}
              textStyle={
                width > 900
                  ? styles.skuSubContainer
                  : styles.skuSubContainerMobile
              }
              labelStyle={width > 900 ? styles.skuText : styles.skuTextMobile}
              value={item.sku}
              id={"copySKU"}
              label={"SKU"}
            />
          </View>
        </View>
      )}
      {displayRowAtTop && displayRowAtTop}
      {item.barcodes?.length > 0 && item.barcodes[0].barcode !== "" ? (
        <View
          style={{
            paddingHorizontal: 8,
            backgroundColor:
              isSwap && !numberOfKeysEven ? "#b6cadd" : "#7c95ae",
          }}
        >
          <View style={{ width:"100%" }}>
            <ScrollAbleText
              onPress={() => {
                isHaptic && Vibration.vibrate(30)
                copyTextToClipboard(item.barcodes[0]?.barcode);
              }}
              textStyle={width > 900 ? styles.upcText : styles.upcTextMobile}
              labelStyle={width > 900 ? styles.skuText : styles.skuTextMobile}
              value={item.barcodes[0]?.barcode}
              id={"copyUPC"}
              label={"UPC"}
            />
          </View>
        </View>
      ) : (
        <AddBarcodeButton
          addBarcodeFun={() => {
            addBarcodeFun(item);
          }}
          width={width}
        />
      )}
      {displayRow}
    </View>
  );
};
const styles = StyleSheet.create({
  subContainer: {
    width: "100%",
    minHeight: 500,
    borderRadius: 12,
    paddingBottom: 10,
    backgroundColor: "#b6c2d2",
  },
  subContainerMobile: {
    width: "100%",
    minHeight: 260,
  },
  gradientContainer: {
    flex: 28,
    padding: 8,
    borderTopLeftRadius: 12,
    borderTopEndRadius: 12,
  },
  gradientContainerMobile: {
    flex: 16,
    padding: 8,
    borderTopLeftRadius: 12,
    borderTopEndRadius: 12,
  },
  nameText: {
    fontFamily: fontFamily.font400,
    textAlign: "left",
  },
  nameTextMobile: {
    fontFamily: fontFamily.font400,
    textAlign: "left",
    minHeight: 163,
    width: "50%",
  },
  skuContainer: {
    paddingHorizontal: 8,
    justifyContent: "center",
    backgroundColor: "#b6cadd",
  },
  skuSubContainer: {
    fontSize: 30,
    fontFamily: fontFamily.font700,
    color: "#3f638b",
    textAlign: "left",
  },
  skuSubContainerMobile: {
    fontSize: 22,
    fontFamily: fontFamily.font700,
    color: "#3f638b",
  },
  skuText: {
    fontSize: 16,
    fontFamily: fontFamily.font400,
    fontWeight: "bold",
  },
  skuTextMobile: {
    fontSize: 16,
    fontWeight: "bolder",
  },
  upcContainer: {
    backgroundColor: "#c1d4e6",
    paddingLeft: 3,
    paddingRight: 3,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingBottom: 3,
  },
  upcSubContainer: {
    fontSize: 28,
    fontFamily: fontFamily.font400,
    color: "#393838",
    textAlign: "left",
  },
  upcLabel: {
    fontSize: 16,
    fontFamily: fontFamily.font400,
    fontWeight: "bold",
  },
  upcText: {
    fontSize: 28,
    fontFamily: fontFamily.font400,
    color: "#393838",
    textAlign: "left",
  },
  upcTextMobile: {
    fontSize: 22,
    fontFamily: fontFamily.font400,
    color: "#393838",
    textAlign: "left",
  },
});
export default OrderItemSuggested;
