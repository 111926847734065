import React, {useEffect, useState, useRef} from 'react';
import {View, Dimensions, Text, StyleSheet, Animated} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import Svg, {
  Rect,
  Text as SvgText,
  LinearGradient,
  Stop,
  Defs
} from 'react-native-svg';
import {getStatisticsTodayVsYesterdayDetails} from '../../../actions/statisticsAction';
import {customColors} from '../../../commons/tests/mocks/mockdata/colors';
import {
  getUserInfo,
  shadeColor,
  calculateBarTodayVsYesDimensions,
  lightenColor,
  truncateLabel
} from './chartUtils';
import {ChartLoading, GridLines, NoDataDisplay, YAxisLabels} from './ChartComponents';
import AutoScrollWrapper from './AutoScrollWrapper';
import usePolling from './usePolling';
import { BarLabel, BarValue, ChartBar } from './svgComponents';

const generateYAxisValues = (maxValue, segments = 6) => {
  return Array(segments)
    .fill()
    .map((_, i) => Math.round((maxValue * i) / (segments - 1)));
};

const AnimatedRect = Animated.createAnimatedComponent(Rect);
const AnimatedSvgText = Animated.createAnimatedComponent(SvgText);

const TodayVsYesterdayChart = () => {
  const maxGroupsToShow = 4;
  const groupSpacing = 20;
  const barSpacing = 10;

  const dispatch = useDispatch();
  const response = useSelector(state => state.statistics.todayVsYesterdayList);

  const [groupedData, setGroupedData] = useState([]);
  const [dataLabel, setDataLabel] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [animations, setAnimations] = useState({});
  const [shouldAutoScroll, setShouldAutoScroll] = useState(false);
  const animationDuration = 800;
  const dataProcessedRef = useRef(false);
  const animationStartedRef = useRef(false);

  const [containerDimensions, setContainerDimensions] = useState({
    width: Dimensions.get('window').width * 0.9,
    height: 550
  });

  const chartHeight = containerDimensions.height * 0.9;
  const chartContainerRef = useRef(null);

  const availableWidth = containerDimensions.width - 70; 
  const groupWidthWithSpacing = availableWidth / maxGroupsToShow;
  const groupWidth = groupWidthWithSpacing - groupSpacing;

  const yesterdayBarWidth = (groupWidth - barSpacing) / 3;
  const todayBarWidth = yesterdayBarWidth * 2;

  const onContainerLayout = event => {
    const {width, height} = event.nativeEvent.layout;
    setContainerDimensions({width, height});
  };

  const startAnimations = (data, animationValues) => {
    if (!data || !data.length || !animationValues || animationStartedRef.current) {
      return;
    }

    animationStartedRef.current = true;

    try {
      const animations = data
        .map((group, index) => [
          Animated.timing(animationValues[`today-${index}`], {
            toValue: 1,
            duration: animationDuration,
            useNativeDriver: false
          }),
          Animated.timing(animationValues[`yesterday-${index}`], {
            toValue: 1,
            duration: animationDuration,
            useNativeDriver: false
          })
        ])
        .flat();

      Animated.stagger(100, animations).start(() => {
        animationStartedRef.current = false;
      });
    } catch (e) {
      console.error('Error starting animations:', e);
      animationStartedRef.current = false;
    }
  };

  const processStatistics = () => {
    try {
      if (dataProcessedRef.current || groupedData.length > 0) {
        return;
      }

      dataProcessedRef.current = true;

      const statisticsList = response?.data?.statistics;
      const userDetails = response?.data?.user_details;
      if (!statisticsList?.length) {
        setLoading(false);
        dataProcessedRef.current = false;
        return;
      }

      const userGroups = {};
      const newAnimations = {};

      statisticsList.forEach(stat => {
        try {
          const userId = parseInt(stat.user_id);

          const todayItemsPerMinute = stat.data?.today_details?.items_per_minute || 0;
          const yesterdayItemsPerMinute = stat.data?.yestderday_dtetails?.items_per_minute || 0;

          if (todayItemsPerMinute === 0 && yesterdayItemsPerMinute === 0) {
            return;
          }

          const user = getUserInfo(userId, userDetails);
          if (!user) {
            return;
          }

          const mainColor = user.color || '#cccccc';
          const lighterColor = lightenColor(mainColor, 30);
          const darkerColor = shadeColor(mainColor, -30);
          
          const maxLabelLength = 8;
          let userLabel = user.label || `User ${userId}`;
          if (userLabel.length > maxLabelLength) {
            userLabel = userLabel.substring(0, maxLabelLength) + '...';
          }

          if (!userGroups[userId]) {
            userGroups[userId] = {
              userId,
              label: userLabel,
              color: mainColor,
              today: {
                value: todayItemsPerMinute,
                gradientStart: lighterColor,
                gradientEnd: darkerColor
              },
              yesterday: {
                value: yesterdayItemsPerMinute,
                gradientStart: lighterColor,
                gradientEnd: darkerColor
              }
            };
          }
        } catch (e) {
          console.error('Error processing stat:', e);
        }
      });

      const groupedDataArray = Object.values(userGroups)
        .sort((a, b) => a.today.value - b.today.value);

      groupedDataArray.forEach((group, index) => {
        newAnimations[`today-${index}`] = new Animated.Value(0);
        newAnimations[`yesterday-${index}`] = new Animated.Value(0);
      });

      const labelList = groupedDataArray.map(group => ({
        label: group.label,
        color: group.color
      }));

      const allValues = groupedDataArray.flatMap(group => [
        group.today.value,
        group.yesterday.value
      ]);

      const maxVal = Math.max(...allValues, 1);

      setDataLabel(labelList);
      setMaxValue(Math.ceil(maxVal * 1.05));
      setAnimations(newAnimations);
      setGroupedData(groupedDataArray);

      setShouldAutoScroll(groupedDataArray.length > maxGroupsToShow);
      
      setLoading(false);

      const animationTimeout = setTimeout(() => {
        startAnimations(groupedDataArray, newAnimations);
      }, 300);

      return () => clearTimeout(animationTimeout);
    } catch (e) {
      console.error('Error in processStatistics:', e);
      setError(e.message);
      setLoading(false);
      dataProcessedRef.current = false;
    }
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (groupedData && groupedData.length > 0) {
        setShouldAutoScroll(groupedData.length > maxGroupsToShow);
      }
    };

    const dimensionsHandler = Dimensions.addEventListener(
      'change',
      updateDimensions
    );

    return () => {
      if (dimensionsHandler.remove) {
        dimensionsHandler.remove();
      }
    };
  }, [groupedData, maxGroupsToShow]);

  usePolling(() => {
    dispatch(getStatisticsTodayVsYesterdayDetails());
  }, 5000);

  useEffect(() => {
    if (response?.data?.statistics) {
      processStatistics();
    }
  }, [response?.data?.statistics]);

  useEffect(() => {
    return () => {
      dataProcessedRef.current = false;
      animationStartedRef.current = false;
    };
  }, []);

  const renderContent = () => {
    if (loading) {
      return <ChartLoading />;
    }

    if (!groupedData.length) {
      return <NoDataDisplay />;
    }

    const yAxisValues = generateYAxisValues(maxValue, 6);

    const totalChartWidth = groupedData.length * (groupWidth + groupSpacing);

    return (
      <View style={styles.mainContent}>
        <View style={styles.chartArea}>
          <View style={styles.chartContainer}>
            <YAxisLabels
              values={yAxisValues}
              chartHeight={chartHeight}
              inverted={true}
              containerStyle={styles.yAxisContainer}
              labelStyle={styles.axisLabel}
            />

            <AutoScrollWrapper
              enabled={shouldAutoScroll}
              speed={35}
              pauseAtEnds={2500}
              showIndicators={shouldAutoScroll}
              style={styles.chartBackground}>
              <Svg
                height={chartHeight + 60}
                width={Math.max(
                  totalChartWidth,
                  containerDimensions.width - 70
                )}
                style={styles.chartSvg}>
                <Defs>
                  {groupedData.map((group, groupIndex) => (
                    <React.Fragment key={`gradients-${groupIndex}`}>
                      <LinearGradient
                        id={`gradient-today-${groupIndex}`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1">
                        <Stop
                          offset="0"
                          stopColor={group.today.gradientStart || '#cccccc'}
                          stopOpacity="1"
                        />
                        <Stop
                          offset="1"
                          stopColor={group.today.gradientEnd || '#aaaaaa'}
                          stopOpacity="1"
                        />
                      </LinearGradient>
                      <LinearGradient
                        id={`gradient-yesterday-${groupIndex}`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1">
                        <Stop
                          offset="0"
                          stopColor={group.yesterday.gradientStart || '#cccccc'}
                          stopOpacity="0.4"
                        />
                        <Stop
                          offset="1"
                          stopColor={group.yesterday.gradientEnd || '#aaaaaa'}
                          stopOpacity="0.4"
                        />
                      </LinearGradient>
                    </React.Fragment>
                  ))}
                </Defs>

                <GridLines
                  chartHeight={chartHeight}
                  chartWidth={Math.max(
                    totalChartWidth,
                    containerDimensions.width - 70
                  )}
                  segments={6}
                />

                {groupedData.map((group, groupIndex) => {
                  const dimensions = calculateBarTodayVsYesDimensions(
                    groupIndex,
                    group,
                    maxValue,
                    chartHeight,
                    groupWidth,
                    groupSpacing,
                    todayBarWidth,
                    yesterdayBarWidth,
                    barSpacing
                  );

                  const {yesterday, today} = dimensions;

                  return (
                    <React.Fragment key={`group-${groupIndex}`}>
                      {(() => {
                        const yesterdayAnimation =
                          animations[`yesterday-${groupIndex}`] ||
                          new Animated.Value(0);
                        const animatedYesterdayHeight =
                          yesterdayAnimation.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, yesterday.height]
                          });

                        const animatedYesterdayY =
                          yesterdayAnimation.interpolate({
                            inputRange: [0, 1],
                            outputRange: [
                              chartHeight,
                              chartHeight - yesterday.height
                            ]
                          });
                        const yesterdayFontSize = group.yesterday.value > 9 ? 50 : 55
                        const yesterdayCount = group.yesterday.value > 0 ? group.yesterday.value.toFixed(2) : group.yesterday.value
                        return (
                          <>
                            <ChartBar 
                              x={yesterday.x}
                              y={animatedYesterdayY}
                              width={yesterdayBarWidth}
                              height={animatedYesterdayHeight}
                              gradientId={`gradient-yesterday-${groupIndex}`}
                              opacity={0.4}
                            />

                            {yesterday.height > 60 ? (
                              <BarValue
                                x={yesterday.center}
                                y={Animated.add(animatedYesterdayY, 50)}
                                value={yesterdayCount}
                                fontSize={yesterdayFontSize}
                              />
                            ) : (
                              <BarValue
                                x={yesterday.center}
                                y={Animated.subtract(animatedYesterdayY, 10)}
                                value={yesterdayCount}
                                fontSize={yesterdayFontSize}
                              />
                            )}
                          </>
                        );
                      })()}

                      {(() => {
                        const todayAnimation =
                          animations[`today-${groupIndex}`] ||
                          new Animated.Value(0);
                        const animatedTodayHeight = todayAnimation.interpolate({
                          inputRange: [0, 1],
                          outputRange: [0, today.height]
                        });

                        const animatedTodayY = todayAnimation.interpolate({
                          inputRange: [0, 1],
                          outputRange: [chartHeight, chartHeight - today.height]
                        });

                        const MIN_HEIGHT_FOR_COUNT = 90;

                        const hasSpaceForCount =
                          today.height >= MIN_HEIGHT_FOR_COUNT;
                        const todayCountFontSize = group.today.value > 9 ? 70 : 80
                        const todayCount = group.today.value > 0 ? group.today.value.toFixed(2) : group.today.value

                        return (
                          <>
                            <ChartBar
                              x={today.x}
                              y={animatedTodayY}
                              width={todayBarWidth}
                              height={animatedTodayHeight}
                              gradientId={`gradient-today-${groupIndex}`}
                            />
                            {hasSpaceForCount ? (
                              <BarValue 
                                x={today.center}
                                y={Animated.add(animatedTodayY, 70)}
                                value={todayCount}
                                fontSize={todayCountFontSize}
                              />
                            ) : (
                              <BarValue 
                                x={today.center}
                                y={Animated.subtract(animatedTodayY, 10)}
                                value={todayCount}
                                fontSize={todayCountFontSize}
                              />
                            )}
                            <BarLabel
                              x={today.center}
                              y={chartHeight + 25}
                              label={truncateLabel(group.label)}
                              fontSize={30}
                            />
                          </>
                        );
                      })()}
                    </React.Fragment>
                  );
                })}
              </Svg>
            </AutoScrollWrapper>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View
      style={styles.container}
      ref={chartContainerRef}
      onLayout={onContainerLayout}>
      {renderContent()}
    </View>
  );
};

export default TodayVsYesterdayChart;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  mainContent: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingVertical: 10,
    paddingHorizontal: 5
  },
  chartArea: {
    flex: 1,
    height: '100%'
  },
  chartContainer: {
    flexDirection: 'row',
    height: '100%',
    paddingRight: 5
  },
  chartBackground: {
    flex: 1,
    backgroundColor: '#0E1317',
    borderRadius: 4,
    overflow: 'hidden'
  },
  yAxisContainer: {
    width: 50,
    height: '100%',
    paddingRight: 5,
    justifyContent: 'flex-start'
  },
  axisLabel: {
    color: customColors.white,
    position: 'absolute',
    right: 5,
    fontSize: 16
  },
  chartSvg: {
    overflow: 'visible',
    paddingTop: 15,
    paddingBottom: 5,
    paddingHorizontal: 10
  },
  legendContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
    paddingHorizontal: 10
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15
  },
  legendBar: {
    height: 10,
    marginRight: 5,
    borderRadius: 2
  },
  legendText: {
    color: customColors.white,
    fontSize: 12
  }
});
