import 'react-native-gesture-handler';
import React, {useEffect, useState} from 'react';
import Main from './src/components/main';
import {Provider} from 'react-redux';
import configureStore from './src/store/configureStore';
import {Text, View, TouchableOpacity} from 'react-native';
import SocketSession from './src/components/socketSession';
// import axios from 'axios';
import axios from './src/config/axios';
import {Client} from 'rollbar-react-native';
import ErrorBoundary from './src/components/errorBoundary';

import {
  useFonts,
  Poppins_100Thin,
  Poppins_200ExtraLight,
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
  Poppins_800ExtraBold,
  Poppins_900Black
} from '@expo-google-fonts/poppins';
import io from 'socket.io-client';
import qz from 'qz-tray';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Fingerprint from 'fingerprintjs';
import './fontello/css/gp_icons.css';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import en from './src/helpers/textFile';
import es from './src/helpers/es';
import PrinterShow from './src/components/directPrinting/printerShow';
import SocketQZ, {fetchData, handlePrinterSelection} from './qzservice';
import rollbarConfig from './rollbarConfig';
import {getLiveStatsData} from './src/helpers/scanPackFunctions';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {useLanguageLoader} from './src/helpers/useLanguageLoader';
i18n.fallbacks = true;
i18n.translations = {en, es};
i18n.locale = Localization.locale;
const store = configureStore();

const rollbarNative = new Client({
  accessToken: rollbarConfig.rollbarAccessToken,
  environment: __DEV__ ? 'development' : 'production',
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureDeviceInfo: true
});
const rollbar = rollbarNative.rollbar;
let barcode = true;
let messageUrl = '';
let msgData = '';
let newSet = '';
let progress = 0;
global.progressBarValue = 1;
global.importIntiated = 'Import initiated';
global.importReciveApiResponse = 0;
global.importOrderStoreId = 0;
global.globalImportOrderStatus = '';
global.isVisibleImportInitiated = true;

global.importButton = true;

export default function App() {
  let [fontsLoaded] = useFonts({
    Poppins_100Thin,
    Poppins_200ExtraLight,
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    Poppins_800ExtraBold,
    Poppins_900Black
  });
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [currentSocket, setCurrentSocket] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [dimension, setDimension] = useState({});
  const [progressCount, setProgressCount] = useState(0);
  const [actionMsg, setActionMsg] = useState(0);
  const [redirectData, setRedirectData] = useState(false);
  const [updateOrders, setUpdateOrders] = useState(false);
  //hook that will handle the language translation
  useLanguageLoader();

  const printlabel = async (setting, msg) => {
    let dimensionShipping = msg?.data?.dimensions.split('x');
    setUrl(msg?.data?.url);
    let data = await fetchData();
    msgData = msg?.data?.print_type;
    setPrinter(JSON.parse(JSON.stringify(data?.printersList)));
    let packingSlipSize = await AsyncStorage.getItem('Packing_slip_size');
    let shippingLabel =
      (msg?.data?.print_type === 'packing_slip'
        ? await AsyncStorage.getItem(
            `user_selected_printer_packing_slip_${packingSlipSize}`
          )
        : await AsyncStorage.getItem(
            'user_selected_printer_order_barcode_label'
          )) || '';
    if (shippingLabel.length === 0) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
      setTimeout(() => {
        handlePrinterSelection(
          JSON.parse(shippingLabel),
          msg?.data?.url,
          setting,
          msgData,
          {width: dimensionShipping[0], height: dimensionShipping[1]}
        );
      }, [3000]);
    }
  };

  const restProgress = () => {
    progress = 0;
    setProgressCount(progress);
    setUpdateOrders(false);
  };

  const socketFun = async params => {
    const access_token = await AsyncStorage.getItem('access_token');
    const socket = io.connect('wss://split1socket.groovepacker.com' + '/v1', {
      path: '/socket/socket.io',
      reconnect: true,
      query: {
        fingerprint: new Fingerprint({
          canvas: true,
          screen_resolution: true
        }).get(),
        token: access_token
      },
      cors: {
        cookie: false,
        host: 'localhost:19006',
        origin: 'http://localhost:5000',
        credentials: true
      },
      transports: ['websocket', 'polling']
    });
    setCurrentSocket(socket);
    socket.on('connect', () => {
      console.log('Socket Connected');

      socket.on('import_status_update', msg => {
        if (msg.import_info) {
          switch (msg.import_info.status) {
            case 'in_progress':
              global.importButton = false;
              global.globalImportOrderStatus = 'in_progress';
              global.isVisibleImportInitiated = true;
              global.importIntiated = 'In Progress';
              break;

            case 'not_started':
              importIntiated = 'Receiving API Response';
              break;

            case 'completed':
              global.progressBarValue = 100;
              global.importButton = true;
              global.globalImportOrderStatus = 'completed';
              global.importOrderStoreId = 0;
              break;

            case 'cancelled':
              global.importButton = true;
              global.isVisibleImportInitiated = true;
              global.globalImportOrderStatus = 'cancelled';
              break;

            case 'import_initiate':
              global.importButton = false;
              global.globalImportOrderStatus = 'import_initiate';
              global.isVisibleImportInitiated = false;
              break;

            case 'fetch_api_response':
              global.importButton = false;
              global.progressBarValue = 0;
              global.globalImportOrderStatus = 'fetch_api_response';
              // importIntiated = 'Receiving API Response';
              global.isVisibleImportInitiated = false;
              break;

            default:
              console.warn(`Unhandled status: ${msg.import_info.status}`);
          }
        }

        // Update progress bar based on individual item statuses
        let inProgressItems = 0;

        msg.import_items.forEach(item => {
          if (item.import_info.status === 'in_progress') {
            global.importOrderStoreId = item.import_info.store_id;
            localStorage.setItem('storeId', item.import_info.store_id);
          }
        });
      });

      socket.on('ask_logout', msg => {
        //  console.log('Socket Checking Ask Logout')
        setMessage(msg.message);
      });

      socket.on('hide_logout', msg => {
        //  console.log('Socket Hide Ask Logout')
        setMessage('');
      });

      socket.on('logout', msg => {
        if (msg.message == 'You have been logged out.') {
          //  console.log('Socket Logging Logout')
          logout_me();
        }
      });

      socket.on('pnotif', async msg => {
        //TODO: Order Barcode Print // Change this for GROOV-2570
        let newsetingfromAPI = newSet?.data?.data?.settings;
        let msgDimension = msg.data?.dimensions?.split('x') ?? '';
        msgDimension.length > 0 &&
          setDimension({width: msgDimension[0], height: msgDimension[1]});
        if (
          msg.type == 'generate_barcode_status' &&
          messageUrl !== msg.data.url &&
          msg.data.url !== null
        ) {
          messageUrl = msg.data.url;
          if (
            messageUrl == (await AsyncStorage.getItem('recent_printed_url'))
          ) {
            return;
          }
          await AsyncStorage.setItem('recent_printed_url', messageUrl);
          if (
            newsetingfromAPI?.print_packing_slips &&
            msg?.data?.print_type === 'packing_slip'
          ) {
            printlabel(newSet?.data?.data?.settings, msg);
          } else if (
            newsetingfromAPI?.print_post_scanning_barcodes &&
            msg?.data?.print_type === 'order_barcode'
          ) {
            printlabel(newSet?.data?.data?.settings, msg);
          } else {
            window.open(messageUrl);
          }

          setTimeout(async () => {
            await AsyncStorage.removeItem('recent_printed_url');
          }, 3000);

          // if(window.innerWidth < 900){
          //   window.location.href = messageUrl
          // }
          // else{
          //   window.open(messageUrl);
          // }
        }
        if (msg?.data?.print_type === 'packing_slip') {
          const {total_orders, current_order_position, status} = msg.data;
          if (status === 'scheduled') {
            setProgressCount(4);
          }
          if (status === 'in_progress') {
            const progress = (current_order_position / total_orders) * 100;
            setProgressCount(progress);
          }
          if (status === 'completed') {
            const progress = 100;
            setProgressCount(progress);
            setUpdateOrders(true);
          }
        }
        if (msg.type == 'groove_bulk_actions') {
          progress = progress >= 100 ? 50 : progress;
          if (msg.data.status === 'in_progress') {
            if (progress < 100) {
              progress += 20;
            }
            setProgressCount(progress);
          }
          if (msg.data.status === 'scheduled') {
            if (progress < 100) {
              progress += 10;
            }
            setProgressCount(progress);
          }
          if (msg.data.status === 'completed') {
            console.log('Message::::::', msg.data);
            progress = 100;
            setProgressCount(progress);
            setUpdateOrders(true);
            if (
              msg.data.activity === 'status_update' &&
              msg.data.identifier === 'orders'
            ) {
              await AsyncStorage.setItem('statusChange', 'completed');
            }
            else if (msg.data.activity === 'assign_rfo_orders' && msg.data.identifier === 'orders') {
              await AsyncStorage.setItem('rfoAssignment', 'completed');
            }
          }
        }
        if (msg.type == 'groove_bulk_tags_actions') {
          //   console.log(">>>>>>>>>progress", msg.data)

          //  console.log(">>>>>>>>>progress", msg.data)

          setActionMsg('groove_bulk_tags_actions');
          setProgressCount(msg.data);

          if (msg.data === 100) {
            try {
              setUpdateOrders(true);
              await AsyncStorage.setItem('progress', JSON.stringify(msg.data));
            } catch (error) {
              console.error('Error saving progress to AsyncStorage:', error);
            }
          }
        }
      });

      socket.on('disconnect', reason => {
        //  console.log('Socket Disconnected: ', reason)
        setProgressCount(0);
        setUpdateOrders(true);
      });

      socket.on('connect_error', error => {
        //  console.log('Socket Connect Error: ', error)
      });
      socket.on('dashboard_update_stat', response => {
        //  console.log('Received dashboard_update_stat:', response);
      });

      socket.on('dashboard_update', message => {
        const filteredData = message.data.dashboard.filter(
          item => parseInt(item.duration) === 30
        );
        setDashboardData(filteredData[0]);
      });

      socket.on('connecting', () => {
        //  console.log('Socket Connecting')
      });
    });
  };

  //API call for the get updated settings.
  const settingApi = async () => {
    const access_token = await AsyncStorage.getItem('access_token');

    if (!access_token) {
      return;
    }

    const url = await AsyncStorage.getItem('url');
    await axios({
      method: 'get',
      url: `${url}/settings/get_settings.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(res => {
        newSet = res;
      })
      .catch(error => {
        //  console.log(error);
      });
  };

  useEffect(() => {
    settingApi();
  }, [redirectData]);

  const getData = data => {
    setRedirectData(data);
  };
  const setupQZCerts = async () => {
    qz.security.setCertificatePromise(function (resolve, reject) {
      fetch(`https://admin.groovepackerapi.com/print/qz_certificate`).then(
        function (data) {
          data.ok ? resolve(data.text()) : reject(data.text());
        }
      );
    });

    qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        fetch(
          `https://admin.groovepackerapi.com/print/qz_sign?request=${toSign}`,
          {
            cache: 'no-store',
            headers: {'Content-Type': 'text/plain'},
            method: 'POST'
          }
        ).then(function (data) {
          data.ok ? resolve(data.text()) : reject(data.text());
        });
      };
    });
  };
  const fetchDashboardData = async () => {
    await getLiveStatsData();
  };

  useEffect(() => {
    setupQZCerts();
    socketFun();
    barcode = true;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      currentSocket && fetchDashboardData();
    }, 2000);
  }, []);

  const logout_everyone = () => {
    currentSocket.emit('logout_everyone_else');
    setMessage('');
  };

  const logout_me = async () => {
    if (currentSocket) {
      currentSocket.disconnect(true);
    }

    // const access_token = await AsyncStorage.getItem('access_token')
    // const url = await AsyncStorage.getItem('url');

    // try {
    //   if (access_token?.length && url?.length) {
    //     await axios({
    //       method: 'post',
    //       url: `${url}/auth/v1/logout`,
    //       headers: { Authorization: `Bearer ${access_token}` },
    //       data: {
    //         token: access_token
    //       }
    //     });
    //   }
    // } catch (e) {}

    await AsyncStorage.removeItem('tenant');
    await AsyncStorage.removeItem('access_token');
    await AsyncStorage.removeItem('Logging');
    await AsyncStorage.removeItem('userInfo');

    window.location.reload();
  };
  //   const socket = io("https://split1socket.groovepacker.com/socket.io/", {
  //     query:
  //       "fingerprint=" +
  //       new Fingerprint({
  //         canvas: true,
  //         screen_resolution: true,
  //         transports: ["websocket", "polling"],
  //       }).get() +
  //       "&token=" +
  //       access_token,
  //   });
  if (!fontsLoaded) {
    return <Text>Loading!</Text>;
  } else if (message?.length) {
    return (
      <SocketSession
        logout_everyone={() => logout_everyone()}
        logout_me={() => logout_me()}
      />
    );
  } else {
    return (
      <Provider store={store}>
        {isModalVisible && (
          <PrinterShow
            dataPrinter={printers}
            url={url}
            setting={newSet?.data?.data?.settings}
            label="shipping_label"
            handlePrinterSelection={async printer => {
              let printerData = await handlePrinterSelection(
                printer,
                url,
                newSet?.data?.data?.settings,
                msgData,
                dimension
              );
              setIsModalVisible(false);
              setSelectedPrinter(printerData.type);
            }}
            closePrompt={() => {
              setIsModalVisible(false);
            }}></PrinterShow>
        )}
        {/* {modalQZ && (
      <AlertBoxSaving name ={i18n.t('directprinting.printComplete')}></AlertBoxSaving>
      )} */}
        <ErrorBoundary instance={rollbar}>
          <GestureHandlerRootView style={{flex: 1}}>
            <Main
              socketFun={() => socketFun()}
              restProgress={() => restProgress()}
              currentSocket={currentSocket}
              isModalVisible={isModalVisible}
              getData={getData}
              progressCount={progressCount}
              actionMsg={actionMsg}
              updateOrders={updateOrders}
              dashboardData={dashboardData}
            />
          </GestureHandlerRootView>
        </ErrorBoundary>
      </Provider>
    );
  }
}
