import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  View,
  Dimensions,
  Text,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
  Animated
} from 'react-native';
import Svg, {
  Rect,
  Text as SvgText,
  Line,
  LinearGradient,
  Stop,
  Defs
} from 'react-native-svg';
import {getStatisticsDetails} from '../../../actions/statisticsAction';
import {customColors} from '../../../commons/tests/mocks/mockdata/colors';
import {
  shadeColor,
  processHorizontalChartStatistics,
  truncateLabel,
  calculateGridLines,
  getValueTextPosition
} from './chartUtils';
import AutoScrollWrapperVertical from './AutoScrollWrapperVertical';
import usePolling from './usePolling';

const AnimatedRect = Animated.createAnimatedComponent(Rect);
const AnimatedSvgText = Animated.createAnimatedComponent(SvgText);

const LARGE_USER_THRESHOLD = 8;
const DEFAULT_BAR_HEIGHT = 80;
const COMPACT_BAR_HEIGHT = 64;
const DEFAULT_BAR_SPACING = 30;
const COMPACT_BAR_SPACING = 24;
const Y_LABEL_WIDTH = 225;

const HorizontalChart = () => {
  const dispatch = useDispatch();
  const response = useSelector(state => state.statistics.listDaily);
  const [dataList, setDataList] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [containerDimensions, setContainerDimensions] = useState({
    width: Dimensions.get('window').width,
    height: 380
  });
  const [barHeight, setBarHeight] = useState(DEFAULT_BAR_HEIGHT);
  const [barSpacing, setBarSpacing] = useState(DEFAULT_BAR_SPACING);
  const [animations, setAnimations] = useState({});
  const [shouldAutoScroll, setShouldAutoScroll] = useState(false);
  const animationDuration = 800;
  const chartContainerRef = useRef(null);
  const dataProcessedRef = useRef(false);
  const animationStartedRef = useRef(false);
  const animationsCompleteRef = useRef(false);
  const isFirstRenderRef = useRef(true);

  const chartWidth = containerDimensions.width - Y_LABEL_WIDTH - 40;
  const chartContainerHeight = containerDimensions.height * 0.9 - 40;

  useEffect(() => {
    const dimensionsHandler = ({window}) => {
      setContainerDimensions({
        width: window.width,
        height: 380
      });
    };

    const subscription = Dimensions.addEventListener(
      'change',
      dimensionsHandler
    );

    return () => {
      if (subscription && subscription.remove) {
        subscription.remove();
      }
    };
  }, []);

  const onContainerLayout = event => {
    const {width, height} = event.nativeEvent.layout;
    setContainerDimensions({width, height});
  };

  const startAnimations = data => {
    if (animationStartedRef.current || !data || data.length === 0) return;

    animationStartedRef.current = true;
    animationsCompleteRef.current = false;

    const newAnimations = {};

    data.forEach((item, index) => {
      newAnimations[`bar-${index}`] = new Animated.Value(0);
    });

    setAnimations(newAnimations);

    setTimeout(() => {
      const animationSequence = data.map((_, index) => {
        return Animated.timing(newAnimations[`bar-${index}`], {
          toValue: 1,
          duration: animationDuration,
          useNativeDriver: false
        });
      });

      Animated.stagger(50, animationSequence).start(() => {
        animationStartedRef.current = false;
        animationsCompleteRef.current = true;
      });
    }, 100);
  };

  const toggleSortOrder = () => {
    const newOrder = sortOrder === 'desc' ? 'asc' : 'desc';
    setSortOrder(newOrder);

    if (dataList && dataList.length > 0) {
      sortData(dataList, newOrder);
    }
  };

  const sortData = (data, order = sortOrder) => {
    if (!data || data.length === 0) return;

    const sortedData = [...data];
    if (order === 'asc') {
      sortedData.sort((a, b) => a.value - b.value);
    } else {
      sortedData.sort((a, b) => b.value - a.value);
    }

    setDataList(sortedData);
    startAnimations(sortedData);
  };

  useEffect(() => {
    if (!dataList || dataList.length === 0) return;

    const isLargeUserSet = dataList.length > LARGE_USER_THRESHOLD;
    const newBarHeight = isLargeUserSet
      ? COMPACT_BAR_HEIGHT
      : DEFAULT_BAR_HEIGHT;
    const newBarSpacing = isLargeUserSet
      ? COMPACT_BAR_SPACING
      : DEFAULT_BAR_SPACING;

    setBarHeight(newBarHeight);
    setBarSpacing(newBarSpacing);
    const totalContentHeight =
      dataList.length * (newBarHeight + newBarSpacing) - newBarSpacing;
    const shouldScroll = totalContentHeight > chartContainerHeight;
    setShouldAutoScroll(shouldScroll);
  }, [dataList.length, chartContainerHeight]);

  usePolling(() => {
    dispatch(getStatisticsDetails());
  }, 5000);

  const processStatistics = () => {
    const success = processHorizontalChartStatistics(
      response,
      dataProcessedRef,
      setMaxValue,
      (data) => sortData(data),
      sortOrder
    );
    
    if (!success) {
      console.log('Failed to process horizontal chart statistics');
    }
  };

  useEffect(() => {
    if (response?.data?.statistics) {
      processStatistics();
    }
  }, [response?.data?.statistics]);

  useEffect(() => {
    if (!response?.data?.statistics) {
      dataProcessedRef.current = false;
      animationStartedRef.current = false;
      animationsCompleteRef.current = false;
    }
  }, [response]);

  useEffect(() => {
    if (isFirstRenderRef.current && dataList && dataList.length > 0) {
      isFirstRenderRef.current = false;
      startAnimations(dataList);
    }
  }, [dataList]);

  const getBarWidth = itemValue => {
    if (maxValue === 0 || isNaN(itemValue)) {
      return 0;
    }
    return (itemValue / maxValue) * chartWidth;
  };

  const totalHeight = dataList.length * (barHeight + barSpacing) - barSpacing;
  const gridLines = calculateGridLines(maxValue, chartWidth);

  return (
    <View
      style={styles.container}
      ref={chartContainerRef}
      onLayout={onContainerLayout}>
      {dataList.length === 0 ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator
            size="large"
            color={customColors.loadingIndicatorColor}
          />
        </View>
      ) : (
        <View style={styles.mainContent}>
          <View style={styles.header}>
            <Text style={styles.title}>Performance Metrics</Text>
            <TouchableOpacity
              onPress={toggleSortOrder}
              style={styles.sortButton}>
              <Text style={styles.sortButtonText}>
                {sortOrder === 'desc' ? 'Sort Ascending' : 'Sort Descending'}
              </Text>
            </TouchableOpacity>
          </View>
          
          <View style={styles.chartContainer}>
            <View style={styles.chartAreaContainer}>
              <View style={[styles.chartBackground, { height: chartContainerHeight }]}>
                <View style={[styles.gridContainer, { left: Y_LABEL_WIDTH }]}>
                  <Svg width={chartWidth} height="100%">
                    {gridLines.map((line, i) => (
                      <Line
                        key={`gridline-${i}`}
                        x1={line.position}
                        y1={0}
                        x2={line.position}
                        y2="100%"
                        stroke="rgba(255, 255, 255, 0.1)"
                        strokeWidth={1}
                      />
                    ))}
                  </Svg>
                </View>
                <AutoScrollWrapperVertical
                  enabled={shouldAutoScroll}
                  speed={15}
                  pauseAtEnds={4000}
                  initialDelay={700}
                  showIndicators={false}
                  style={styles.scrollWrapper}
                  contentContainerStyle={{
                    minHeight: Math.max(totalHeight, chartContainerHeight - 40)
                  }}>
                  {dataList.map((item, index) => {
                    const barWidth = getBarWidth(item.value);
                    const valueTextPosition = getValueTextPosition(barWidth, item.value);
                    const barY = index * (barHeight + barSpacing);
                    const barAnimationValue = animations[`bar-${index}`] || new Animated.Value(0);
                    
                    return (
                      <View key={`chart-row-${index}`} style={[
                        styles.chartRow,
                        {
                          height: barHeight,
                          marginBottom: barSpacing
                        }
                      ]}>
                        <View style={styles.labelContainer}>
                          <Text style={styles.labelText}>
                            {truncateLabel(item.label)}
                          </Text>
                        </View>
                        <View style={styles.barContainer}>
                          <Svg height={barHeight} width={chartWidth}>
                            <Defs>
                              <LinearGradient
                                id={`gradient-${index}`}
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1">
                                <Stop
                                  offset="0"
                                  stopColor={item.gradientStart || item.color || '#cccccc'}
                                  stopOpacity="0.7"
                                />
                                <Stop
                                  offset="1"
                                  stopColor={
                                    item.gradientEnd ||
                                    shadeColor(item.color || '#cccccc', -30)
                                  }
                                  stopOpacity="0.7"
                                />
                              </LinearGradient>
                            </Defs>

                            <AnimatedRect
                              x={0}
                              y={0}
                              width={barAnimationValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: [0, barWidth]
                              })}
                              height={barHeight}
                              fill={`url(#gradient-${index})`}
                              rx={12}
                              ry={12}
                              opacity={0.7}
                            />

                            <AnimatedSvgText
                              x={barAnimationValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: [0, barWidth < 100 ? barWidth + 10 : barWidth - 20] 
                              })}
                              y={barHeight / 2}
                              fill="white"
                              textAnchor={barWidth < 100 ? "start" : "end"}
                              alignmentBaseline="middle"
                              fontFamily="Poppins_600SemiBold"
                              fontSize={38}
                              opacity={barAnimationValue.interpolate({
                                inputRange: [0, 0.7, 1],
                                outputRange: [0, 0, 1]
                              })}>
                              {typeof item.value === 'number' ? item.value.toFixed(2) : item.value}
                            </AnimatedSvgText>
                          </Svg>
                        </View>
                      </View>
                    );
                  })}
                </AutoScrollWrapperVertical>

                <View style={[styles.xAxisContainer, { left: Y_LABEL_WIDTH }]}>
                    {gridLines.map((line, i) => (
                      <Text
                        key={`x-label-${i}`}
                        style={[
                          styles.xAxisLabel,
                          { left: line.position }
                        ]}>
                        {Number.isInteger(line.value) ? line.value.toFixed(0) : line.value.toFixed(2)}
                      </Text>
                    ))}
                  </View>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#191919'
  },
  mainContent: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15
  },
  title: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'Poppins_600SemiBold'
  },
  sortButton: {
    padding: 8,
    backgroundColor: '#333',
    borderRadius: 6
  },
  sortButtonText: {
    color: 'white',
    fontSize: 14
  },
  chartContainer: {
    flex: 1,
    width: '100%'
  },
  chartAreaContainer: {
    flex: 1,
    position: 'relative'
  },
  chartBackground: {
    backgroundColor: '#0E1317',
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative'
  },
  gridContainer: {
    position: 'absolute',
    top: 0,
    bottom: 40,
    right: 0,
    zIndex: 1,
    pointerEvents: 'none'
  },
  scrollWrapper: {
    flex: 1,
    marginBottom: 40,
  },
  chartRow: {
    flexDirection: 'row',
    width: '100%',
  },
  labelContainer: {
    width: Y_LABEL_WIDTH,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 10
  },
  labelText: {
    color: 'white',
    fontSize: 30,
    fontFamily: 'Poppins_600SemiBold',
    textAlign: 'right'
  },
  barContainer: {
    flex: 1
  },
  xAxisContainer: {
    position: 'absolute',
    bottom: 0,
    height: 40,
    right: 0
  },
  xAxisLabel: {
    position: 'absolute',
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 14,
    fontFamily: 'Poppins_600SemiBold',
    transform: [{ translateX: -15 }],
    textAlign: 'center'
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default HorizontalChart;
