import React, {Component} from 'react';
import {connect} from 'react-redux';
import i18n from 'i18n-js';
import {LinearGradient} from 'expo-linear-gradient';
import {ImportOrderStatus} from '../../actions/scanpackAction';
import {customColors} from '../../commons/tests/mocks/mockdata/colors';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import TodayVsYesterdayChart from './components/TodayVsYesterdayChart';
import DailyStackedChart from './components/DailyStackedChart';
import HorizontalChart from './components/HorizontalChart';
import WeeklyChart from './components/WeeklyChart';
import WithIn15MinChart from './components/WithIn15MinChart';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SettingsDropdown from './components/SettingsDropdown';
import RemainingTimeDisplay from './components/RemainingTimeDisplay';
import { getStatisticsDetails, getStatisticsTodayVsYesterdayDetails, getStatisticsWeeklyDetails, getStatisticsWithIn15MinDetails } from '../../actions/statisticsAction';
import { GetGeneralSetting } from '../../actions/userAction';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartOption: [
        {value: 1, status: true, activeOption: true, key: 'current15Min', time: 15},
        {value: 2, status: false, activeOption: true, key: 'today', time: 15},
        {value: 3, status: false, activeOption: true, key: 'userPerformance', time: 15},
        {value: 4, status: false, activeOption: true, key: 'yesterdayVsToday', time: 15},
        {value: 5, status: false, activeOption: false, key: 'thisWeek', time: 15}
      ],
      activeChart: 1,
      isActiveIndex: true,
      isSettingsOpen: false,
      currentTimerValue: 15
    };
    this.timerID = null;
  }

  componentDidMount() {
    this.loadSettings().then(() => {
      this.activateFirstEnabledChart();
      this.fetchAllStatisticsData();
    });
  }

  // handleTimeZoneValue = () =>{
  //   debugger
  //   response = dispatch(GetGeneralSetting());
  //   console.log("response", response);
    
  // }

  fetchAllStatisticsData = () => {
    this.props.getStatisticsWithIn15MinDetails();
    this.props.getStatisticsDetails();
    this.props.getStatisticsTodayVsYesterdayDetails();
    this.props.getStatisticsWeeklyDetails();
  }

  loadSettings = async () => {
    try {
      const chartSettings = await AsyncStorage.getItem('chartSettings');
      if (chartSettings) {
        const parsedSettings = JSON.parse(chartSettings);
        const updatedOptions = this.state.barChartOption.map(option => ({
          ...option,
          activeOption: parsedSettings[option.key]?.enabled ?? option.activeOption,
          time: parsedSettings[option.key]?.time ?? option.time
        }));
        
        this.setState({ barChartOption: updatedOptions });
      }
    } catch (error) {
      console.error('Error loading settings:', error);
    }
  };

  activateFirstEnabledChart = () => {
    const { barChartOption } = this.state;
    const firstEnabledIndex = barChartOption.findIndex(option => option.activeOption);
    if (firstEnabledIndex !== -1) {
      const newOptions = barChartOption.map((option, index) => ({
        ...option,
        status: index === firstEnabledIndex
      }));
      
      const selectedChart = barChartOption[firstEnabledIndex];
      
      this.setState({
        barChartOption: newOptions,
        activeChart: selectedChart.value,
        currentTimerValue: selectedChart.time,
        isActiveIndex: true
      }, () => {
        this.startAutoRotation();
      });
    } else {
      this.setState({ isActiveIndex: false });
    }
  };

  updateDashboardSettings = (settings) => {
    const updatedOptions = this.state.barChartOption.map(option => ({
      ...option,
      activeOption: settings[option.key]?.enabled ?? option.activeOption,
      time: settings[option.key]?.time ?? option.time
    }));
    
    this.setState({ 
      barChartOption: updatedOptions 
    }, () => {
      const hasEnabledCharts = updatedOptions.some(option => option.activeOption);
      
      if (!hasEnabledCharts) {
        this.setState({ isActiveIndex: false });
        this.stopAutoRotation();
        return;
      }
      
      const { activeChart, barChartOption } = this.state;
      const currentOption = barChartOption.find(option => option.value === activeChart);
      
      if (!currentOption?.activeOption) {
        this.activateFirstEnabledChart();
      } else {
        this.setState({
          currentTimerValue: currentOption.time,
          isActiveIndex: true
        });
        this.stopAutoRotation();
        this.startAutoRotation();
      }
    });
  };

  startAutoRotation() {
    this.stopAutoRotation();
    const { barChartOption, activeChart } = this.state;
    const currentOption = barChartOption.find(option => option.value === activeChart);
    if (!currentOption || !currentOption.activeOption) {
      return;
    }
    const timeInMs = (currentOption.time || 15) * 1000;
    this.timerID = setInterval(() => {
      this.autoRotateButtons();
    }, timeInMs);
  }

  stopAutoRotation() {
    if (this.timerID) {
      clearInterval(this.timerID);
      this.timerID = null;
    }
  }

  autoRotateButtons() {
    const {barChartOption} = this.state;
    const currentActiveIndex = barChartOption.findIndex(
      object => object.status
    );

    let nextIndex = currentActiveIndex;
    let count = 0;
    while (count < barChartOption.length) {
      nextIndex = (nextIndex + 1) % barChartOption.length;
      if (barChartOption[nextIndex].activeOption) {
        break;
      }
      count++;
    }

    if (barChartOption[nextIndex].activeOption) {
      const nextValue = barChartOption[nextIndex].value;
      this.handlePress(nextIndex, nextValue);
    }
  }

  handlePress(index, value) {
    const {barChartOption} = this.state;

    if (!barChartOption[index].activeOption) {
      return;
    }

    const list = barChartOption.map((option, i) => ({
      ...option,
      status: i === index
    }));

    const selectedChart = barChartOption[index];

    this.setState({
      barChartOption: list,
      activeChart: value,
      currentTimerValue: selectedChart.time,
      isActiveIndex: true
    }, () => {
      this.stopAutoRotation();
      this.startAutoRotation();
    });
  }

  toggleSettings = () => {
    this.setState(prevState => ({
      isSettingsOpen: !prevState.isSettingsOpen
    }));
  };

  componentWillUnmount() {
    this.stopAutoRotation();
  }

  render() {
    const {barChartOption, activeChart, isActiveIndex, isSettingsOpen, currentTimerValue} = this.state;
    const chartTitleMap = {
      1: i18n.t('dashboard.current15MinSession'),
      2: i18n.t('dashboard.eachUserItemsByToday'),
      3: i18n.t('dashboard.eachUserItemsForToDay'),
      4: i18n.t('dashboard.itemsScannedByTime'),
      5: i18n.t('dashboard.eachUserItemsByDay')
    };
    const chartTitle = chartTitleMap[activeChart] || '';

    return (
      <View style={styles.container}>
        <LinearGradient
          colors={customColors.linearGradient}
          start={[-0.5, -1]}
          style={styles.linearGradientContainer}>
          {isActiveIndex ? (
            <View style={styles.dashboardContainer}>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>{chartTitle}</Text>
                 {activeChart === 1 && <RemainingTimeDisplay />}
                <TouchableOpacity onPress={this.toggleSettings} style={styles.settingsButton}>
                  <i className="icon-cog" style={styles.settingsIcon}></i>
                </TouchableOpacity>
              </View>
              
              <View style={styles.chartContentContainer}>
                {activeChart == 1 && <WithIn15MinChart />}
                {activeChart == 2 && <DailyStackedChart />}
                {activeChart == 3 && <HorizontalChart />}
                {activeChart == 4 && <TodayVsYesterdayChart />}
                {activeChart == 5 && <WeeklyChart />}
              </View>

              <View style={styles.bottomDotsWraaper}>
                {barChartOption.map((item, index) => (
                  <TouchableOpacity
                    testID={`testID${index}`}
                    key={index}
                    onPress={() => this.handlePress(index, item.value)}
                    disabled={!item.activeOption}>
                    <View
                      style={[
                        styles.dotStyle,
                        {
                          backgroundColor: item.status ? '#FFFFFF' : 'transparent',
                          borderColor: '#FFFFFF',
                          opacity: item.activeOption ? 1 : 0.3
                        }
                      ]}
                    />
                  </TouchableOpacity>
                ))}
              </View>
              <View style={styles.timerDisplay}>
                <Text style={styles.timerText}>{currentTimerValue} sec</Text>
              </View>
            </View>
          ) : (
            <View style={styles.noChartsContainer}>
              <Text style={styles.noChartsText}>No charts are enabled</Text>
              <TouchableOpacity onPress={this.toggleSettings} style={styles.settingsButtonLarge}>
                <i className="icon-cog" style={styles.settingsIconLarge}></i>
                <Text style={styles.settingsButtonText}>Open Settings</Text>
              </TouchableOpacity>
            </View>
          )}
          
          {isSettingsOpen && (
            <SettingsDropdown 
              isOpen={isSettingsOpen}
              onClose={this.toggleSettings}
              updateDashboardSettings={this.updateDashboardSettings}
            />
          )}
        </LinearGradient>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#191919',
  },
  linearGradientContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  dashboardContainer: {
    flex: 1,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 20,
    position: 'relative',
  },
  title: {
    color: '#FFFFFF',
    fontSize: 26,
    fontFamily: 'Poppins_600SemiBold',
    textAlign: 'center',
  },
  settingsButton: {
    position: 'absolute',
    right: 0,
    padding: 5,
  },
  settingsIcon: {
    color: 'white',
    fontSize: 20,
  },
  chartContentContainer: {
    width: '100%',
    height: '80%',
    backgroundColor: '#0E1317',
    borderRadius: 8,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomDotsWraaper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
    marginBottom: 10,
  },
  dotStyle: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginHorizontal: 5,
    borderWidth: 1,
  },
  timerDisplay: {
    borderWidth: 1,
    borderColor: '#FFFFFF',
    borderRadius: 20,
    paddingVertical: 4,
    paddingHorizontal: 15,
    marginBottom: 10,
  },
  timerText: {
    color: '#FFFFFF',
    fontSize: 12,
    fontFamily: 'Poppins_600SemiBold',
  },
  noChartsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  noChartsText: {
    color: '#FFFFFF',
    fontSize: 18,
    fontFamily: 'Poppins_600SemiBold',
    marginBottom: 20,
  },
  settingsButtonLarge: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#1A1D20',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 8,
  },
  settingsIconLarge: {
    color: 'white',
    fontSize: 24,
    marginRight: 10,
  },
  settingsButtonText: {
    color: 'white',
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold',
  }
});

const mapDispatchToProps = {
  ImportOrderStatus,
  getStatisticsDetails,
  getStatisticsTodayVsYesterdayDetails,
  getStatisticsWeeklyDetails,
  getStatisticsWithIn15MinDetails
};

export default connect(null, mapDispatchToProps)(Dashboard);
