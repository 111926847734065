import React, {useState, useEffect} from 'react';
import { Text, TouchableOpacity, View, StyleSheet, ActivityIndicator } from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import I18n from 'i18n-js';
import { useDispatch } from 'react-redux';
import { SearchScanpackOrder } from '../../actions/scanpackAction';

const CartAssignmentPrompt = ({showPrompt,setShowPrompt,cartId, payload}) => {
  const [tooltips, setTooltips] = useState({
    continueTooltip: false,
    restartTooltip: false,
    cancelTooltip: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] =  useState('');

  const dispatch = useDispatch();

  const HandleCartAssignmentAction = (action) => {
    const data = {
      ...payload,
      input: cartId,
      cart_action: action
    };
    setIsLoading(true);
    dispatch(SearchScanpackOrder(data, callBack))
  }

  const callBack = (cartId, message) => {
    setIsLoading(false);
    setMessage(message);
  }


  const toggleTooltip = tooltipName => {
    setTooltips({
      continueTooltip:
        tooltipName === 'continue' ? !tooltips.continueTooltip : false,
      restartTooltip:
        tooltipName === 'restart' ? !tooltips.restartTooltip : false,
      cancelTooltip: tooltipName === 'cancel' ? !tooltips.cancelTooltip : false
    });
  };

  useEffect(() => {
    const handleClickOutside = () => {
      if (
        tooltips.continueTooltip ||
        tooltips.restartTooltip ||
        tooltips.cancelTooltip
      ) {
        setTooltips({
          continueTooltip: false,
          restartTooltip: false,
          cancelTooltip: false
        });
      }
    };

    if (
      tooltips.continueTooltip ||
      tooltips.restartTooltip ||
      tooltips.cancelTooltip
    ) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltips]);

  const GradientButton = ({text, onPress, testID}) => (
    <TouchableOpacity testID={testID} onPress={onPress}>
      <LinearGradient
        locations={[0, 0.7, 1]}
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        colors={['#ffb636', '#ffb636', '#d47d02']}
        style={styles.actionButton}>
        <Text style={styles.buttonText}>{text}</Text>
      </LinearGradient>
    </TouchableOpacity>
  );

  const TooltipComponent = ({isVisible, tooltipId, content}) => {
    if (!isVisible) return null;

    return (
      <LinearGradient
        testID={tooltipId}
        locations={[0, 1]}
        colors={['#8a9daf', '#d7e1e9']}
        style={styles.tooltipContainer}>
        <Text style={styles.tooltipText}>{content}</Text>
        <View style={styles.tooltipArrow} />
      </LinearGradient>
    );
  };

  const ButtonWithTooltip = ({type, onPress = () => {}}) => {
    const tooltipKey = `${type}Tooltip`;
    const isTooltipVisible = tooltips[tooltipKey];
  
    return (
      <View style={styles.buttonWithTooltip}>
        <View style={styles.buttonRow}>
          <GradientButton
            testID={`${type}Button`}
            text={I18n.t(`cartAssignment.${type}Button`)}
            onPress={onPress}
          />
          <TouchableOpacity
            testID={`${type}InfoIcon`}
            style={styles.infoIconWrapper}
            onPress={() => toggleTooltip(type)}>
            <i className="icon-info-circled" style={styles.infoIcon}></i>
          </TouchableOpacity>
        </View>
        <TooltipComponent
          isVisible={isTooltipVisible}
          tooltipId={`${type}Tooltip`}
          content={I18n.t(`cartAssignment.${type}Tooltip`)}
        />
      </View>
    );
  };

  const buttons = [
    {type: 'continue', onPress: () => HandleCartAssignmentAction('take_assignment')},
    {type: 'restart', onPress: () => HandleCartAssignmentAction('restart')},
    {type: 'cancel', onPress: () => setShowPrompt(false)}
  ];

  return (
    <View style={[styles.responseView, {display: !showPrompt && 'none' }]}>
      <LinearGradient
        locations={[0, 1]}
        start={{x: 0, y: 1}}
        end={{x: 0, y: 0}}
        colors={['#d47d02', '#ffb636']}
        style={styles.promptContainer}>
        <View style={styles.cancelIcon}>
          <TouchableOpacity testID="CancelIcon" onPress={() => {setShowPrompt(false)}}>
            <i
              className="icon-cancel"
              style={{fontSize: 26, color: '#1C1C1C'}}></i>
          </TouchableOpacity>
        </View>

        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>{I18n.t('cartAssignment.title')}</Text>
        </View>

        <View style={styles.buttonContainer}>
          {buttons.map(button => (
            <ButtonWithTooltip
              key={button.type}
              type={button.type}
              onPress={button.onPress}
            />
          ))}
        </View>
        <View>
          {isLoading && <ActivityIndicator size="large" color="#000"/>}
          {message?.length > 0 && <Text style={styles.titleText}>{message}</Text>}
        </View>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  responseView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1000,
    width: '100%',
    height: '100%'
  },
  promptContainer: {
    backgroundColor: '#d3dfea',
    paddingHorizontal: 20,
    paddingVertical: 15,
    width: '90%',
    maxWidth: 550,
    marginTop: 0,
    borderColor: '#e8971a',
    borderWidth: 3,
    borderRadius: 5,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    position: 'relative'
  },
  cancelIcon: {
    display: 'flex',
    alignSelf: 'flex-end'
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: 10
  },
  titleText: {
    fontFamily: 'Poppins_300Light',
    fontSize: 20,
    textAlign: 'center',
    color: '#000',
    fontWeight: '500'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: 15
  },
  buttonWithTooltip: {
    width: '100%',
    position: 'relative',
    marginBottom: 5
  },
  buttonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 5
  },
  actionButton: {
    borderWidth: 1,
    borderColor: '#1C1C1C',
    borderRadius: 20,
    paddingVertical: 8,
    paddingHorizontal: 16,
    minWidth: '75%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {
    color: '#1C1C1C',
    fontFamily: 'Poppins_300Light',
    fontSize: 16,
    textShadowColor: 'rgba(0, 0, 0, 0.25)',
    textShadowOffset: {width: 1, height: 1},
    textShadowRadius: 2,
    fontWeight: '500'
  },
  infoIconWrapper: {
    marginLeft: 10,
    padding: 5
  },
  infoIcon: {
    color: '#1C1C1C',
    fontSize: 24
  },
  tooltipContainer: {
    padding: 12,
    borderRadius: 5,
    marginTop: 5,
    width: '100%',
    position: 'relative',
    zIndex: 2
  },
  tooltipText: {
    color: '#000',
    fontFamily: 'Poppins_300Light',
    fontSize: 14,
    lineHeight: 20
  },
  tooltipArrow: {
    position: 'absolute',
    top: -10,
    left: '45%',
    width: 0,
    height: 0,
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderBottomWidth: 10,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#8a9daf'
  }
});

export default CartAssignmentPrompt;
