import {
  STATISTICS_LIST,
  STATISTICS_LIST_FAIL,
  STATISTICS_TODAY_VS_YESTERDAY_LIST,
  STATISTICS_TODAY_VS_YESTERDAY_LIST_FAIL,
  STATISTICS_WITHIN15MIN_LIST,
  STATISTICS_WITHIN15MIN_LIST_FAIL,
  STATISTICS_WEEKLY_LIST,
  STATISTICS_WEEKLY_LIST_FAIL
} from '../constants';

const initialState = {
  listDaily: [],
  todayVsYesterdayList: [],
  withIn15MinList: [],
  weeklyList: []
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATISTICS_LIST:
      return {...state, listDaily: action.payload};
    case STATISTICS_LIST_FAIL:
      return {...state, listDaily: action.payload};
    case STATISTICS_TODAY_VS_YESTERDAY_LIST:
      return {...state, todayVsYesterdayList: action.payload};
    case STATISTICS_TODAY_VS_YESTERDAY_LIST_FAIL:
      return {...state, todayVsYesterdayList: action.payload};
    case STATISTICS_WITHIN15MIN_LIST:
      return {...state, withIn15MinList: action.payload};
    case STATISTICS_WITHIN15MIN_LIST_FAIL:
      return {...state, withIn15MinList: action.payload};
    case STATISTICS_WEEKLY_LIST:
      return {...state, weeklyList: action.payload};
    case STATISTICS_WEEKLY_LIST_FAIL:
      return {...state, weeklyList: action.payload};
    default:
      return state;
  }
};

export default statisticsReducer;
