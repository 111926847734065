import React, { useEffect, useState } from 'react';

const ChartTooltip = ({ position, data, visible, isLastStack }) => {
  if (!visible || !data) return null;
  
  const { stack, dayLabel } = data;
  const [tooltipPosition, setTooltipPosition] = useState({ 
    x: position.x,
    y: position.y,
    alignment: 'right'
  });
  useEffect(() => {
    setTooltipPosition({
      x: position.x,
      y: position.y,
      alignment: 'right'
    });
  }, [position]);
  
  const widthToSub = (tooltipPosition.x * 15 )/ 100
  const xPosition = isLastStack 
    ? `${tooltipPosition.x - widthToSub}px` 
    : `${tooltipPosition.x + 15}px`;
  
  return (
    <div
      style={{
        position: 'fixed',
        left: xPosition,
        top: tooltipPosition.y,
        transform: isLastStack 
          ? 'translate(-100%, -50%)' 
          : 'translateY(-50%)',
        backgroundColor: '#000000',
        color: 'white',
        borderRadius: 8,
        padding: '8px 12px',
        border: '1px solid #959595',
        textAlign: 'center',
        boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
        zIndex: 1000,
        whiteSpace: 'nowrap'
      }}>
      <div style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 1.4,fontFamily: 'Poppins_400Regular'  }}>
       {stack.name} {stack.value}
      </div>
      <div style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 1.4,fontFamily: 'Poppins_400Regular'  }}>
        {dayLabel}
      </div>
    </div>
  );
};

export default ChartTooltip;
