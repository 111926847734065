import React, {useEffect, useState, useRef} from 'react';
import {
  View,
  Dimensions,
  StyleSheet
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {getStatisticsDetails} from '../../../actions/statisticsAction';
import {ChartLoading, NoDataDisplay, GridLines, YAxisLabels} from './ChartComponents';
import {
  generateYAxisValues, 
  processDailyChartStatistics,
  calculateDailyChartDimensions
} from './chartUtils';
import AutoScrollWrapper from './AutoScrollWrapper';
import usePolling from './usePolling';
import useChartAnimations from './useChartAnimations';
import { ChartContent } from './svgComponents';

const DailyStackedChart = () => {
  const [containerDimensions, setContainerDimensions] = useState({
    width: Dimensions.get('window').width * 0.9,
    height: 450
  });

  const maxBarsToShow = 6;
  const barGapRatio = 0.2;
  const yAxisWidth = 50;

  const dispatch = useDispatch();
  const response = useSelector(state => state.statistics.listDaily);
  const [dataList, setDataList] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [loading, setLoading] = useState(!response?.data?.statistics);
  const [barWidth, setBarWidth] = useState(80);
  const [spacing, setSpacing] = useState(20);
  const [chartWidth, setChartWidth] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(false);
  const dataProcessedRef = useRef(false);

  const animations = useChartAnimations(dataList, 800, 300, 100);

  const onContainerLayout = event => {
    const {width, height} = event.nativeEvent.layout;
    setContainerDimensions({width, height});
  };

  const processStatistics = () => {
    if (dataProcessedRef.current) {
      return;
    }

    try {
      const result = processDailyChartStatistics(response);

      if (!result.success) {
        setLoading(false);
        return;
      }
      
      setMaxValue(result.maxValue);
      setDataList(result.dataList);
      dataProcessedRef.current = true;

      const dimensions = calculateDailyChartDimensions(
        result.dataList,
        containerDimensions,
        maxBarsToShow,
        barGapRatio,
        yAxisWidth
      );
      
      setBarWidth(dimensions.barWidth);
      setSpacing(dimensions.spacing);
      setChartWidth(dimensions.chartWidth);
      setShouldScroll(dimensions.shouldScroll);
      
      setLoading(false);
    } catch (e) {
      console.error('Error processing statistics:', e);
      setLoading(false);
    }
  };

  usePolling(() => {
    dispatch(getStatisticsDetails());
  }, 5000);

  useEffect(() => {
    if (response?.data?.statistics && !dataProcessedRef.current) {
      processStatistics();
    } else if (dataProcessedRef.current && dataList.length > 0) {
      const dimensions = calculateDailyChartDimensions(
        dataList,
        containerDimensions,
        maxBarsToShow,
        barGapRatio,
        yAxisWidth
      );
      
      setBarWidth(dimensions.barWidth);
      setSpacing(dimensions.spacing);
      setChartWidth(dimensions.chartWidth);
      setShouldScroll(dimensions.shouldScroll);
    }
  }, [response?.data?.statistics, containerDimensions]);

  useEffect(() => {
    const updateDimensions = () => {
      if (dataList && dataList.length > 0) {
        const dimensions = calculateDailyChartDimensions(
          dataList,
          containerDimensions,
          maxBarsToShow,
          barGapRatio,
          yAxisWidth
        );
        
        setBarWidth(dimensions.barWidth);
        setSpacing(dimensions.spacing);
        setChartWidth(dimensions.chartWidth);
        setShouldScroll(dimensions.shouldScroll);
      }
    };

    const dimensionsHandler = Dimensions.addEventListener('change', updateDimensions);

    return () => {
      dimensionsHandler.remove();
    };
  }, [dataList]);

  const chartContainerHeight = containerDimensions.height * 0.85;
  const adjustedChartHeight = chartContainerHeight - 10;
  const segmentsCount = 6;
  const labelSpaceHeight = 50;

  const renderContent = () => {
    if (loading) {
      return <ChartLoading />;
    }

    if (dataList.length === 0) {
      return <NoDataDisplay />;
    }

    return (
      <View style={styles.mainContent}>
        <View style={styles.chartArea}>
          <View style={styles.chartRow}>
            <YAxisLabels
              values={generateYAxisValues(maxValue, segmentsCount)}
              chartHeight={adjustedChartHeight}
              segmentsCount={segmentsCount}
              containerStyle={{ height: adjustedChartHeight }}
            />
            <AutoScrollWrapper
              enabled={shouldScroll}
              speed={35}
              pauseAtEnds={2500}
              showIndicators={shouldScroll}
              style={styles.chartBackground}
            >
              <View
                style={[
                  styles.svgContainer,
                  {
                    height: adjustedChartHeight + labelSpaceHeight, 
                    width: chartWidth
                  }
                ]}>
                <ChartContent
                  chartHeight={adjustedChartHeight}
                  chartWidth={chartWidth}
                  labelSpaceHeight={labelSpaceHeight}
                  segmentsCount={segmentsCount}
                  data={dataList}
                  barWidth={barWidth}
                  spacing={spacing}
                  maxValue={maxValue}
                  animations={animations}
                  GridLines={GridLines}
                />
              </View>
            </AutoScrollWrapper>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container} onLayout={onContainerLayout}>
      {renderContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#191919',
    height: '90%'
  },
  mainContent: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingVertical: 10,
    paddingBottom: 40,
    paddingHorizontal: 5
  },
  chartArea: {
    flex: 1,
    height: '100%'
  },
  chartRow: {
    flexDirection: 'row',
    height: '100%'
  },
  yAxisContainer: {
    width: 50,
    marginRight: 5,
    backgroundColor: '#191919',
    position: 'relative'
  },
  chartBackground: {
    flex: 1,
    backgroundColor: '#191919'
  },
  svgContainer: {
    borderRadius: 3,
    overflow: 'hidden'
  },
  chartSvg: {
    overflow: 'visible'
  }
});

export default DailyStackedChart;
