const colors = [
  {firstColor: '#1CBB77', secondColor: '#7BFAC3', textColor: 'black'},
  {firstColor: '#CF7C08', secondColor: '#FFB538', textColor: 'black'},
  {firstColor: '#8E0000', secondColor: '#E41400', textColor: 'white'},
  {firstColor: '#045586', secondColor: '#056EAA', textColor: 'white'},
  {firstColor: '#979797', secondColor: '#D7D7D7', textColor: 'black'}
];
export const customColors = {
  linearGradient: ['#191919', '#191919', '#191919', '#191919'],
  white: '#FFFFFF',
  transparent: 'transparent',
  red: '#A9151A',
  black: '#000000',
  blackShadow: '#1A1A1A',
  DarkGray: '#333333',
  MediumGray: '#666666',
  //Switch Case Colors.
  offColor: '#5c6e79',
  onColor: '##5AA6D1',
  offHandleColor: '#969696',
  onHandleColor: '#fbfdfe',
  loadingIndicatorColor: '#336599',
  mistyRose: '#f4efef',
  blueSlate: '#D7D7D7',
  lightSteelBlue: 'rgb(172,187,199)',
  SlateGray: '#54626F'
};
export default colors;
