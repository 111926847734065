import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GENERAL_SETTINGS,
  SCANPACK_SETTINGS,
  USER_INFO_SUCCESS,
  GET_BOTH_SETTINGS,
  USER_INFO_SUCCESS_FAIL,
  GET_BOTH_SETTINGS_FAIL,
  SEND_PASSWORD_RESET_EMAIL,
  SEND_PASSWORD_RESET_EMAIL_FAIL,
  IS_EMAIL_SEND,
  IS_BUG_SUPPORT_POPUP_OPEN,
  GET_ALL_USERS,
  UPLOAD_SOUND_FAIL,
  UPLOAD_SOUND_SUCCESS,
  FETCH_SOUND_SUCCESS,
  FETCH_SOUND_FAIL,
  REMOVE_SOUND_FAIL,
  REMOVE_SOUND_SUCCESS
} from '../constants';

export function Login(data) {
  return async dispatch => {
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/oauth/token`,
      headers: {},
      data: data
    })
      .then(async res => {
        await AsyncStorage.setItem('access_token', res.data.access_token).then(
          () => {
            Logging();
          }
        );
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.status,
          time: new Date()
        });
      })
      .catch(error => {
        dispatch({
          type: LOGIN_FAIL,
          payload: error?.response?.status
        });
      });
  };
}

const Logging = async () => {
  let arr = JSON.stringify([]);
  await AsyncStorage.setItem('Logging', arr);
};

export function UserInfo(call) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/home/userinfo.json`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(async res => {
        let data = JSON.stringify(res.data);
        await AsyncStorage.setItem('userInfo', data);
        await AsyncStorage.setItem('userId', res.data.id);
        call === false
          ? dispatch({
              type: USER_INFO_SUCCESS_FAIL,
              payload: res.data
            })
          : dispatch({
              type: USER_INFO_SUCCESS,
              payload: res.data
            });
      })
      .catch(error => {
        if (error?.response?.status == '401') {
          dispatch({
            type: USER_INFO_SUCCESS_FAIL,
            payload: error.response.status
          });
        }
      });
  };
}

export function GetBothSettings() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/settings/get_setting.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(res => {
        dispatch({
          type: GET_BOTH_SETTINGS,
          payload: {data: res.data, time: new Date()}
        });
      })
      .catch(error => {
        dispatch({
          type: GET_BOTH_SETTINGS_FAIL,
          payload: {data: error?.response?.status, time: new Date()}
        });
      });
  };
}

export function GetGeneralSetting() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/settings/get_settings.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(async res => {
        await AsyncStorage.setItem(
          'Packing_slip_size',
          res?.data.data.settings.packing_slip_size
        );
        await AsyncStorage.setItem(
          'Product_Barcode_label_size',
          res?.data.data.settings.product_barcode_label_size
        );

        dispatch({
          type: GENERAL_SETTINGS,
          payload: {data: res.data, time: new Date()}
        });
      })
      .catch(error => {});
  };
}

export function UpdateSettings(payload) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');

    axios({
      method: 'put',
      url: `${url}/settings/update_settings.json`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: payload
    })
      .then(res => {
        dispatch(GetBothSettings());
      })
      .catch(error => {});
  };
}

export function GetScanPackSetting() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/settings/get_scan_pack_settings.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(res => {
        dispatch({
          type: SCANPACK_SETTINGS,
          payload: {data: res.data, time: new Date()}
        });
      })
      .catch(error => {});
  };
}

export function ResetPassword(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    dispatch({
      type: IS_EMAIL_SEND,
      payload: true
    });
    axios({
      method: 'get',
      url: `${url}/users/get_user_email?user=${data.name}`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(data => {
        dispatch({
          type: SEND_PASSWORD_RESET_EMAIL_FAIL,
          payload: false
        });
        dispatch({
          type: SEND_PASSWORD_RESET_EMAIL,
          payload: data.data.msg
        });
        dispatch({
          type: IS_EMAIL_SEND,
          payload: false
        });
      })
      .catch(error => {
        dispatch({
          type: SEND_PASSWORD_RESET_EMAIL_FAIL,
          payload: true
        });
        dispatch({
          type: IS_EMAIL_SEND,
          payload: false
        });
      });
  };
}
export function SetEmailSend() {
  return async dispatch => {
    dispatch({
      type: IS_EMAIL_SEND,
      payload: ''
    });
  };
}

export function setBugOrSupportPopUP() {
  return async dispatch => {
    dispatch({
      type: IS_BUG_SUPPORT_POPUP_OPEN,
      payload: true
    });
  };
}

export function resetBugOrSupportPopUP() {
  return async dispatch => {
    dispatch({
      type: IS_BUG_SUPPORT_POPUP_OPEN,
      payload: false
    });
  };
}

export function GetAllUsers() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');

    axios({
      method: 'get',
      url: `${url}/users.json`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(response => {
        dispatch({
          type: GET_ALL_USERS,
          payload: response.data
        });
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  };
}

export function addSoundFile(formData) {
  return async dispatch => {
    const url = await AsyncStorage.getItem('url');
    const access_token = await AsyncStorage.getItem('access_token');
    const tenent = await AsyncStorage.getItem('tenent');

    axios
      .post(`${url}/sound_files/create_sounds`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'multipart/form-data',
          tenent: tenent
        }
      })
      .then(response => {
        dispatch({
          type: UPLOAD_SOUND_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: UPLOAD_SOUND_FAIL,
          payload: error?.response?.status
        });
      });
  };
}

export function fetchSounds() {
  return async dispatch => {
    const url = await AsyncStorage.getItem('url');
    const access_token = await AsyncStorage.getItem('access_token');
    const tenent = await AsyncStorage.getItem('tenent');

    axios
      .get(`${url}/sound_files/get_sounds_files`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'multipart/form-data',
          tenent: tenent
        }
      })
      .then(response => {
        dispatch({
          type: FETCH_SOUND_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_SOUND_FAIL,
          payload: error?.response?.status
        });
      });
  };
}

export function removeSounds(soundType, fileNames) {
  return async dispatch => {
    const url = await AsyncStorage.getItem('url');
    const access_token = await AsyncStorage.getItem('access_token');
    const tenant = await AsyncStorage.getItem('tenant');
    // Construct the payload
    const payload = {
      sound_type: soundType,
      file_names: fileNames
    };

    axios
      .delete(`${url}/sound_files/remove_sounds`, {
        data: JSON.stringify(payload),  // Send data in the request body
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',  // Change to JSON content type
          tenant: tenant
        }
      })
      .then(response => {
        dispatch({
          type: REMOVE_SOUND_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: REMOVE_SOUND_FAIL,
          payload: error?.response?.status
        });
      });
  };
}

