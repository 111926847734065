import React, { useState, useEffect, useRef } from 'react';
import { View, Text, FlatList, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import { customColors } from '../../commons/tests/mocks/mockdata/colors';
import { fontFamily } from '../../helpers/fontFamily';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons } from '@expo/vector-icons';
import { Audio } from 'expo-av';
import { removeSounds, fetchSounds } from '../../actions/userAction';
import { useDispatch } from 'react-redux';
import AlertModal from './AlertModal';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

const OrderScanSound = props => {
  const { title, type, orderScanList, selectSounds, onDeleteFile } = props;
  const [dropDown, setDropDown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSound, setSelectedSound] = useState({
    label: selectSounds,
    url: 'NA'
  });
  const dropdownRef = useRef(null); // Ref to handle clicks outside
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingItemId, setPlayingItemId] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const soundRef = useRef(null);
  const dispatch = useDispatch();



  const handleOutsidePress = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropDown(false);
    }
  };

  useEffect(() => {
    // Add event listener for detecting click outside
    document.addEventListener('mousedown', handleOutsidePress);

    // Clean up the event listener when the component unmounts
    return () => {
      if (soundRef.current) {
              soundRef.current.unloadAsync();
            }
      document.removeEventListener('mousedown', handleOutsidePress);
    };
  }, []);


  
 
  

  const handleSelectSound = async (item) => {
    const selectedLabel = item?.filename?.replace(/\.mp3$/, '') || 'Default';
    const selectedUrl = item?.url || 'NA';

    setSelectedSound({
      label: selectedLabel,
      url: selectedUrl
    });
    
    setDropDown(false);

    const storageKeyMap = {
      correct_scan: 'correctScanType',
      error_scan: 'errorScanType',
      order_done: 'orderScanType'
    };
  
    const settingLabelKeyMap = {
      correct_scan: 'correctScanLabel',
      error_scan: 'errorScanLabel',
      order_done: 'orderScanLabel'
    };
  
    try {
      if (storageKeyMap[type]) {
        await AsyncStorage.setItem(storageKeyMap[type], selectedUrl);
      }
      if (settingLabelKeyMap[type]) {
        await AsyncStorage.setItem(settingLabelKeyMap[type], selectedLabel);
      }
    } catch (error) {
      console.error('Error saving sound settings:', error);
    }
  };

  const handlePlayPause = async (item, index, event) => {
    if (event) {
      event.stopPropagation();
    }
    
    try {
      if (playingItemId === index && isPlaying) {
        if (soundRef.current) {
          await soundRef.current.pauseAsync();
        }
        setIsPlaying(false);
        setPlayingItemId(null);
      } else {
        if (soundRef.current) {
          await soundRef.current.unloadAsync();
        }
        
        const { sound } = await Audio.Sound.createAsync(
          { uri: item?.url },
          { shouldPlay: true }
        );
        
        soundRef.current = sound;
        sound.setOnPlaybackStatusUpdate((status) => {
          if (status.didJustFinish) {
            setIsPlaying(false);
            setPlayingItemId(null);
          }
        });
        
        setIsPlaying(true);
        setPlayingItemId(index);
      }
    } catch (error) {
      console.error("Error playing sound:", error);
    }
  };

  const handleDeleteFile = () => {
    if (itemToDelete?.filename) {
      dispatch(removeSounds(type, [itemToDelete.filename]));
      setTimeout(() => {
        dispatch(fetchSounds()); 
      }, 1000);
    }
    setIsOpen(false);
    setItemToDelete(null);
  };

  const openDeleteConfirmation = (item) => {
    setItemToDelete(item);
    setIsOpen(true);
  };

  const renderSoundItem = ({ item, index }) => {
    const isThisItemPlaying = isPlaying && playingItemId === index;
    const isDefault = item?.filename?.replace(/\.mp3$/, '') === 'Default';
    
    return (
      <TouchableOpacity
        style={styles.dropdownItem}
        testID='SelectFile'
        onPress={() => handleSelectSound(item)}>
        <Text numberOfLines={1} style={styles.dropdownItemText}>
          {item?.filename?.replace(/\.mp3$/, '') || 'Default'}
        </Text>
        
        <View style={styles.actionButtonsContainer}>
          <TouchableOpacity 
           testID= "stopSoundButton"
            onPress={(event) => {
              event.stopPropagation();
              handlePlayPause(item, index, event);
            }}
            style={styles.actionButton}>
            <Ionicons 
              name={isThisItemPlaying ? 'pause-circle' : 'play-circle'} 
              size={24} 
              color="white" 
            />
          </TouchableOpacity>

          {!isDefault && !isThisItemPlaying && (
            <TouchableOpacity 
              onPress={(event) => {
                event.stopPropagation();
                openDeleteConfirmation(item);
              }}
              style={styles.actionButton}>
              <Ionicons 
                name="close" 
                size={24} 
                color="#fff" 
              />
            </TouchableOpacity>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.selectorContainer}>
        <TouchableOpacity
          style={styles.selector}
          testID="selectorButton"
          onPress={() => setDropDown(!dropDown)}
          >
          <Text style={styles.selectorText} numberOfLines={1}>
            {selectedSound.label}
          </Text>
        </TouchableOpacity>
        
        {dropDown && (
          <View 
          ref={dropdownRef} 
          style={styles.dropdownMenu} testID="dropdownMenu">
            <FlatList
              data={orderScanList}
              renderItem={renderSoundItem}
              keyExtractor={(item, index) => `${type}-${index}`}
              nestedScrollEnabled={true}
              showsVerticalScrollIndicator={true}
              testID="soundListFlatList"
            />
          </View>
        )}
      </View>
      
      <AlertModal 
        handleCancel={() => {
          setIsOpen(false);
          setItemToDelete(null);
        }} 
        handleOK={handleDeleteFile}
        isOpen={isOpen} 
      />
    </View>
  );
};

export default OrderScanSound;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: -10,
    maxWidth: '32%',
    position: 'relative',
  },
  title: {
    color: customColors.white,
    fontSize: 10,
    fontFamily: fontFamily.font500,
    marginBottom: -3,
    marginLeft: 12,
  },
  selectorContainer: {
    position: 'relative',
    zIndex: 10,
  },
  selector: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#fff'
  },
  selectorText: {
    color: customColors.white,
    fontSize: 16,
    fontFamily: fontFamily.font400,
    flex: 1,
    paddingRight: 10
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#2D445F',
    borderRadius: 4,
    maxHeight: 200,
    zIndex: 20,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4
  },
  dropdownItem: {
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderBottomColor: '#3D546F',
    borderBottomWidth: 0.5,
    backgroundColor: '#2D445F',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 50
  },
  dropdownItemText: {
    color: customColors.white,
    fontSize: 14,
    fontFamily: fontFamily.font400,
    flex: 1,
    marginRight: 5
  },
  actionButtonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 60,
  },
  actionButton: {
    marginHorizontal: 3,
    padding: 4,
  }
});