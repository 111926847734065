import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
  ImageBackground,
  Dimensions,
  ScrollView
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RescanPopup from './rescan_popup';
import {connect} from 'react-redux';
import NetInfo from '@react-native-community/netinfo';
import {GetOrderList, ResetOrderList, UpdateOrderStatus} from '../../actions/orderActions';
import {
  SearchScanpackOrder,
  ImportOrder,
  ImportOrderStatus,
  CheckImportOrderProcess,
  ResetSearchOrderData
} from '../../actions/scanpackAction';
import {
  GetBothSettings,
  GetGeneralSetting,
  UserInfo
} from '../../actions/userAction';
import {ShowNotification} from '../../actions/menuActions';
import styles from '../../style/scanpack';
import CommonStyles from '../../style/commonStyles';
import PopUpModel from './pop_up_model';
import {LinearGradient} from 'expo-linear-gradient';
import moment from 'moment';
import i18n from 'i18n-js';
import InterNetChecks from '../internateConnectionCheck';
import InerternetConnectionAlert from '../internetConnectionAlert';
import notReady from '../../../assets/sounds/not_ready.mp3';
import _ from 'lodash';
import ProgressBar from '../../commons/progressBar';
import Clipboard from '@react-native-clipboard/clipboard';
import {AssignOrdersButton} from './assignOrdersButton';
import QuickScanComponent from './QuickScanComponent';
import CartAssignmentPrompt from '../scanToCart/prompt';

const height = Dimensions.get('window').height;
const width = Dimensions.get('window').width;
let progressTimeout = null;
let progressTimeout2 = null;
let orderListSuccess = false;
let searchOrderSuccess = false;
let validateUser = false;
let messageShow = false;
let scannedAlert = false;
let importDates = false;
let attempts = 0;
var notReadySound = new Audio(notReady);
let isToFocus = true;
class ScanPack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderStatusApiCall: true,
      showProgressBar: false,
      importOrderIntiated: '',
      checkStatusButtonSecondCount: 5,
      windowwidth: 0,
      reScanPopupCond: '',
      toolTipVisible: false,
      data: {
        input: '',
        state: 'scanpack.rfo',
        id: null,
        box_id: null,
        store_order_id: null,
        app: 'app'
      },
      popUpModelShow: false,
      reScanPopupShow: false,
      reScanOrderPopupShow: false,
      dataList: {
        filter: '',
        order: 'DESC',
        limit: '1',
        offset: '0',
        app: 'app',
        count: 0
      },
      orderDetail: null,
      rfoTitle: i18n.t('scanPack.rfoTitle'),
      time: new Date(),
      loading: false,
      newLoading: false,
      loaderTitle: '',
      userInfo: '',
      scan_pack_settings: null,
      quickScanToolTip: false,
      refreshToolTip: false,
      toolTipCheck: false,
      importOrderText: '',
      animatingType: false,
      cueOrderStatus: '',
      EtaText: '',
      ShowEtaText: false,
      showQuick: false,
      quickValue: '',
      showStatus: true,
      popInternet: true,
      orderNumberInput: '',
      isImportCompleted: false,
      checkStatus: false,
      importInProgress: false,
      progress: global.progressBarValue,
      checkImportStatus: false,
      isVisibleCheckImportStautsBox: false,
      manageApiStatus: true,
      cartId: '',
      showCartAssignmentPrompt: false,
    };
    this.inputFocus = React.createRef();
    this.searchOrder = this.searchOrder.bind(this);
    window.addEventListener('resize', this.update);
    // importDate = true
  }

  formatDate(dateString) {
    return moment(dateString).format('MMM D h:mm A');
  }

  componentDidMount() {
    global.importButton = false;
    global.isVisibleImportInitiated = true;
    this.props.ImportOrderStatus();
    this.props.route.params.socketFun();

    this.props.UserInfo();
    this.props.GetBothSettings();
    this.props.GetGeneralSetting();
    this.props.ImportOrderStatus();
    this.setState({loading: true, loaderTitle: 'Loading...'});
    this.getOldestUnscannedOrder();
    validateUser = true;
    this.update();
    const sI = setInterval(() => {
      this.test();
    }, 2000);
    if (this.props.showNotification) {
      this.alertBox(
        this.props.isCodeFound
          ? i18n.t('scanpackItem.service_Issue_notification_one')
          : i18n.t('scanpackItem.service_Issue_notification'),
        true,
        true
      );
    }
    notReadySound.load();
    if (global.progressBarValue > 1) {
      this.setState({isImportCompleted: true});
      global.importButton = false;
    }
    if (this.props.route.params.page === 'orderSearchList') {
      const orderNumber = this.props.route.params.orderNumber;
      this.searchOrder(orderNumber);
    }
    this._unsubscribe = this.props.navigation?.addListener('focus', this.handleScreenReturn);
  }
  handleScreenReturn = () => {
    // this.props.ResetOrderList();
    // this.props.ResetSearchOrderData();
    this.setState({
      rfoTitle: i18n.t('scanPack.rfoTitle'),
      loading: true, 
      loaderTitle: 'Loading...',
      orderDetail: null,
    });
    orderListSuccess = true;
    searchOrderSuccess = false;
    messageShow = false;
    this.props.ResetSearchOrderData();
    this.getOldestUnscannedOrder();
    this.props.ImportOrderStatus();
  }
  update = () => {
    this.setState({
      windowwidth: window.innerWidth
    });
  };
  //According to the platform call the function
  getOrder = () => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      this.checkConnection('apiCall');
    } else {
      this.apiCall();
    }
  };

  alertBox = (message, skip, messageTypeSuccess) => {
    if (messageTypeSuccess === true) {
      this.setState({
        popUpModelShow: true,
        message: message,
        messageTypeSuccess: true
      });
    } else {
      if (
        this.props?.searchOrder?.searchOrder?.data?.order[0]?.order_info
          ?.status === 'scanned'
      ) {
        this.setState({message: message, reScanPopupShow: true});
      }
    }
    setTimeout(
      function () {
        this.setState({
          popUpModelShow: false,
          message: '',
          messageTypeSuccess: false
        });
        this.props.ShowNotification({value: false, codeFound: false});
      }.bind(this),
      4000
    );
  };
  //Check the connection for the ios and android
  checkConnection = async (type, input) => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      type === 'callShipmentOrder'
        ? this.callShipmentOrder(input)
        : this.apiCall();
    } else {
      this.setState({rfoTitle: i18n.t('scanPack.checkInternet')});
    }
  };
  //Call the api to fetch the data from backend
  apiCall = () => {
    this.props.GetOrderList(this.state.dataList);
    orderListSuccess = true;
  };

  getOldestUnscannedOrder = () => {
    let dataList = _.cloneDeep(this.state.dataList);
    dataList.oldestUnscanned = true;
    dataList.sort = 'order_date';
    dataList.order = 'ASC';
    this.props.GetOrderList(dataList);
    orderListSuccess = true;
  };

  checkEstimateFun = data => {
    const estimatedTime = i18n.t('scanPack.estimatedTime');
    const hoursForStore = i18n.t('scanPack.hoursForStore');
    const minutesForStore = i18n.t('scanPack.minutesForStore');
    const secondsForStore = i18n.t('scanPack.secondsForStore');
    const hoursLabel = i18n.t('scanPack.hours');
    const minutesLabel = i18n.t('scanPack.minutes');
    data &&
      data.import_items.map(estimate => {
        if (estimate.progress !== null && estimate.progress.status !== false) {
          let storeName = estimate?.store_info?.name;
          if (estimate?.progress?.elapsed_time_remaining) {
            if (
              estimate?.progress?.elapsed_time_remaining.substring(0, 2) ==
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(3, 5) ==
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(6, 8) != '00'
            ) {
              this.setState({
                EtaText: `${estimatedTime}: ${estimate?.progress?.elapsed_time_remaining.substring(
                  6,
                  8
                )} ${secondsForStore} ${storeName}`
              });
            }
            if (
              estimate?.progress?.elapsed_time_remaining.substring(0, 2) ==
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(3, 5) !=
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(6, 8) != '00'
            ) {
              this.setState({
                EtaText: `${estimatedTime}: ${estimate?.progress?.elapsed_time_remaining.substring(
                  3,
                  5
                )} ${minutesLabel} ${estimate?.progress?.elapsed_time_remaining.substring(
                  6,
                  8
                )} ${secondsForStore} ${storeName}`
              });
            }
            if (
              estimate?.progress?.elapsed_time_remaining.substring(0, 2) ==
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(3, 5) !=
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(6, 8) == '00'
            ) {
              this.setState({
                EtaText: `${estimatedTime}: ${estimate?.progress?.elapsed_time_remaining.substring(
                  3,
                  5
                )} ${minutesForStore} ${storeName}`
              });
            }
            if (
              estimate?.progress?.elapsed_time_remaining.substring(0, 2) !==
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(3, 5) ==
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(6, 8) == '00'
            ) {
              this.setState({
                EtaText: `${estimatedTime}: ${estimate?.progress?.elapsed_time_remaining.substring(
                  0,
                  2
                )} ${hoursForStore} ${storeName}`
              });
            }
            if (
              estimate?.progress?.elapsed_time_remaining.substring(0, 2) !==
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(3, 5) !=
                '00' &&
              estimate?.progress?.elapsed_time_remaining.substring(6, 8) == '00'
            ) {
              this.setState({
                EtaText: `${estimatedTime}: ${estimate?.progress?.elapsed_time_remaining.substring(
                  0,
                  2
                )} ${hoursLabel} ${estimate?.progress?.elapsed_time_remaining.substring(
                  3,
                  5
                )} ${minutesForStore} ${storeName}`
              });
            }
            this.setState({ShowEtaText: true});
          } else {
            this.setState({EtaText: estimatedTime, ShowEtaText: true});
          }
        }
      });
  };
  unauth = async () => {
    try {
      const username = await AsyncStorage.getItem('username');
      const tenent = await AsyncStorage.getItem('tenent');
      await AsyncStorage.clear();
      if (username) {
        await AsyncStorage.setItem('username', username);
      }
      await AsyncStorage.setItem('tenent', tenent);
      window.location.reload();

      this.props.navigation.navigate('SignIn', {
        api: 'importstatus',
        status: '401'
      });
    } catch (e) {}
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps?.importOrder?.importstatusError == '401' ||
      nextProps?.importOrder?.importdataError == '401' ||
      nextProps?.userInfo == '401' ||
      nextProps?.ordersList == '401' ||
      nextProps?.searchOrder.searchOrder == '401'
    ) {
      this.unauth();
    }
    if (this.state.checkImportStatus == true || this.state.showStatus) {
      if (
        nextProps?.importOrder?.importstatusData?.summary?.import_info
          ?.status == 'cancelled' ||
        nextProps?.importOrder?.importstatusData?.summary?.import_info
          ?.status == 'completed'
      ) {
        if (
          nextProps.importOrder.importstatusData?.summary?.import_info
            ?.updated_at
        ) {
          const lastImport = i18n.t('scanPack.lastImportLabel');
          const agoLabel = i18n.t('scanPack.ago');
          this.setState({ShowEtaText: false});
          let currentdate = new Date();
          let updatedate = new Date(
            nextProps.importOrder.importstatusData?.summary?.import_info?.updated_at
          );
          let status =
            nextProps?.importOrder?.importstatusData?.summary?.import_info
              ?.status;

          if (currentdate.getFullYear() !== updatedate.getFullYear()) {
            let days = currentdate - updatedate;
            let d = (days / (1000 * 60 * 60 * 24)).toFixed(0);
            let daycheck = d == 1 ? `${d} day` : `${d} days`;
            this.setState({
              importOrderText: `${lastImport} ${status} ${daycheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() == updatedate.getMonth() &&
            currentdate.getDay() !== updatedate.getDay()
          ) {
            let days = currentdate - updatedate;
            let d = (days / (1000 * 60 * 60 * 24)).toFixed(0);
            let daycheck = d == 1 ? `${d} day` : `${d} days`;
            this.setState({
              importOrderText: `${lastImport} ${status} ${daycheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() !== updatedate.getMonth()
          ) {
            let month = currentdate.getMonth() - updatedate.getMonth();
            let monthcheck = month == 1 ? `${month} month` : `${month} months`;
            this.setState({
              importOrderText: `${lastImport} ${status} ${monthcheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() == updatedate.getMonth() &&
            currentdate.getDay() == updatedate.getDay() &&
            currentdate.getHours() !== updatedate.getHours()
          ) {
            let hour =
              moment(currentdate).format('HH') -
              moment(updatedate).format('HH');
            let min =
              moment(currentdate).format('mm') -
              moment(updatedate).format('mm');
            if (min.toString().includes('-')) {
              hour = hour - 1;
              let minc = 60 - parseInt(moment(updatedate).format('mm'));
              let ss = parseInt(moment(currentdate).format('mm')) + minc;
              min = ss;
            }
            let hourCheck =
              hour == 0 ? '' : hour == 1 ? `${hour} hour` : `${hour} hours`;
            let minCheck =
              min == '0' ? '' : min == 1 ? `${min} minute` : `${min} minutes`;
            this.setState({
              importOrderText: `${lastImport} ${status} ${hourCheck} ${minCheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() == updatedate.getMonth() &&
            currentdate.getDay() == updatedate.getDay() &&
            currentdate.getHours() == updatedate.getHours() &&
            currentdate.getMinutes() !== updatedate.getMinutes()
          ) {
            let minut =
              moment(currentdate).format('mm') -
              moment(updatedate).format('mm');
            let minut1 = moment(currentdate).format('m:ss');
            let min2 = moment(updatedate).format('m:ss');
            let sec =
              moment(currentdate).format('ss') -
              moment(updatedate).format('ss');
            if (sec.toString().includes('-')) {
              minut = minut - 1;
              let se = 60 - parseInt(moment(updatedate).format('ss'));
              let ss = parseInt(moment(currentdate).format('ss')) + se;
              sec = ss;
            }
            let minCheck =
              minut == 0
                ? ''
                : minut == 1
                ? `${minut} minute`
                : `${minut} minutes`;
            let secCheck =
              sec == '0' ? '' : sec == 1 ? `${sec} second` : `${sec} seconds`;
            this.setState({
              importOrderText: `${lastImport} ${status} ${minCheck} ${secCheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() == updatedate.getMonth() &&
            currentdate.getDay() == updatedate.getDay() &&
            currentdate.getHours() == updatedate.getHours() &&
            currentdate.getMinutes() == updatedate.getMinutes() &&
            currentdate.getSeconds() !== updatedate.getSeconds()
          ) {
            let sec =
              moment(currentdate).format('ss') -
              moment(updatedate).format('ss');
            let secCheck = sec == 1 ? `${sec} second` : ` ${sec} seconds`;
            this.setState({
              importOrderText: `${lastImport} ${status} ${secCheck} ${agoLabel}`
            });
          }
        }
        this.setState({
          animatingType: false
          // importButton: true
        });
        global.importButton = true;
      }
      if (
        this.state.checkImportStatus ||
        nextProps?.importOrder?.importstatusData?.summary?.import_info
          ?.status == 'in_progress'
      ) {
        global.isVisibleImportInitiated = true;
        global.importButton = false;
        this.checkEstimateFun(nextProps.importOrder.importstatusData?.summary);
        if (
          nextProps.importOrder.importstatusData?.summary?.import_info
            ?.created_at
        ) {
          let currentdate = new Date();
          let createdate = new Date(
            nextProps.importOrder.importstatusData?.summary?.import_info?.created_at
          );
          const agoLabel = i18n.t('scanPack.ago');
          let status = i18n.t('scanPack.importStartedLabel');
          if (currentdate.getFullYear() !== createdate.getFullYear()) {
            let days = currentdate - createdate;
            let d = (days / (1000 * 60 * 60 * 24)).toFixed(0);
            let daycheck = d == 1 ? `${d} day` : `${d} days`;
            this.setState({
              importOrderText: `${status} ${daycheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() == createdate.getMonth() &&
            currentdate.getDay() !== createdate.getDay()
          ) {
            let days = currentdate - createdate;
            let d = (days / (1000 * 60 * 60 * 24)).toFixed(0);
            let daycheck = d == 1 ? `${d} day` : `${d} days`;
            this.setState({
              importOrderText: `${status} ${daycheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() !== createdate.getMonth()
          ) {
            let month = currentdate.getMonth() - createdate.getMonth();
            this.setState({
              importOrderText: `${status} ${month} months ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() == createdate.getMonth() &&
            currentdate.getDay() == createdate.getDay() &&
            currentdate.getHours() !== createdate.getHours()
          ) {
            let hour =
              moment(currentdate).format('H') - moment(createdate).format('H');
            let min =
              moment(currentdate).format('mm') -
              moment(createdate).format('mm');
            if (min.toString().includes('-')) {
              hour = hour - 1;
              let minc = 60 - parseInt(moment(createdate).format('mm'));
              let ss = parseInt(moment(currentdate).format('mm')) + minc;
              min = ss;
            }
            let hourCheck =
              hour == 0 ? '' : hour == 1 ? `${hour} hour` : `${hour} hours`;
            let minCheck =
              min == '0' ? '' : min == 1 ? `${min} minute` : `${min} minutes`;
            this.setState({
              importOrderText: `${status} ${hourCheck} ${minCheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() == createdate.getMonth() &&
            currentdate.getDay() == createdate.getDay() &&
            currentdate.getHours() == createdate.getHours() &&
            currentdate.getMinutes() !== createdate.getMinutes()
          ) {
            let minut =
              moment(currentdate).format('mm') -
              moment(createdate).format('mm');
            let secd =
              moment(currentdate).format('ss') -
              moment(createdate).format('ss');
            if (secd.toString().includes('-')) {
              minut = minut - 1;
              let se = 60 - parseInt(moment(createdate).format('ss'));
              let ss = parseInt(moment(currentdate).format('ss')) + se;
              secd = ss;
            }
            let minCheck =
              minut == 0
                ? ''
                : minut == 1
                ? `${minut} minute`
                : `${minut} minutes`;
            let secCheck =
              secd == '0'
                ? ''
                : secd == 1
                ? `${secd} second`
                : `${secd} seconds`;

            this.setState({
              importOrderText: `${status} ${minCheck} ${secCheck} ${agoLabel}`
            });
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() == createdate.getMonth() &&
            currentdate.getDay() == createdate.getDay() &&
            currentdate.getHours() == createdate.getHours() &&
            currentdate.getMinutes() == createdate.getMinutes() &&
            currentdate.getSeconds() !== createdate.getSeconds()
          ) {
            let sec =
              moment(currentdate).format('ss') -
              moment(createdate).format('ss');
            let secCkeck = sec == 1 ? `${sec} second` : `${sec} seconds`;
            this.setState({
              importOrderText: `${status} ${secCkeck} ${agoLabel}`
            });
          }
        }
      }
      if (
        nextProps?.importOrder?.importstatusData?.summary?.import_info
          ?.status == 'not_started'
      ) {
        global.importButton = false;
      }
    }
  }

  componentDidUpdate(pP) {
    if (
      orderListSuccess === true &&
      this.props &&
      this.props.ordersList &&
      this.props.ordersList !== this.state.list
    ) {
      orderListSuccess = false;
      let list = this.props.ordersList;

      let orderShow = list?.orders?.length === 0 ? true : false;
      this.setState({
        scan_pack_settings: list?.scan_pack_settings,
        general_settings: list?.general_settings,
        rfoTitle:
          list?.orders?.length > 0
            ? list && list?.orders[0] && list?.orders[0]?.ordernum
            : null,
        orderDetail: list && list.orders && list.orders[0],
        loading: false,
        list: this.props.ordersList,
        unClick: orderShow
      });
      if (list?.scan_pack_settings?.scan_by_packing_slip) {
        this.setState({cueOrderStatus: i18n.t('scanPack.packing_slip')});
      }
      if (list?.scan_pack_settings?.scan_by_packing_slip_or_shipping_label) {
        this.setState({
          cueOrderStatus: i18n.t('scanPack.shippingLable_or_packingSlip')
        });
      }
      if (list?.scan_pack_settings?.scan_by_shipping_label) {
        this.setState({cueOrderStatus: i18n.t('scanPack.shippingLable')});
      }
    }
    if (
      messageShow === false &&
      this.props &&
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.showScannedMessage
    ) {
      this.alertBox(i18n.t('scanPack.already_scanned'), true, false);
      this.setState({loading: true, loaderTitle: 'Loading...'});
      this.getOrder();
      messageShow = true;
    }
    if (
      messageShow === false &&
      this.props &&
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.showCancelledMessage
    ) {
      this.alertBox(i18n.t('scanPack.already_cancelled'), true, true);
      this.setState({loading: true, loaderTitle: 'Loading...'});
      this.getOrder();
      messageShow = true;
    }

    if (
      scannedAlert === false &&
      this.props &&
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.showScannedMessageAlert
    ) {
      this.alertBox(i18n.t('scanPack.scanned_one_pass'), true, true);
      scannedAlert = true;
    }
    //Search Order Data
    if (
      searchOrderSuccess === true &&
      this.props &&
      this.props.searchOrder &&
      this?.props?.searchOrder?.time > this?.state?.apiCallTime &&
      this?.props?.searchOrder !== this?.state?.oldOne
    ) {
      let searchResult =
        this?.props?.searchOrder && this?.props?.searchOrder?.searchOrder;
      let order =
        searchResult &&
        searchResult?.data?.order &&
        searchResult?.data?.order[0];
      if (searchResult) {
        if (
          searchResult?.matched &&
          searchResult?.error_messages.length === 0
        ) {
          if (searchResult?.data) {
            this.orderDetails(order);
            searchOrderSuccess = false;
          } else {
            if (searchResult.data.status === 'scanned') {
              let message = i18n.t('scanPack.alreadyScannedMessage', {
                orderNumber: searchResult.data.order_num
              });
              this.statusFunction(message);
              this.setState({unClick: true});
            }
          }
        } else {
          this.alertBox(searchResult?.error_messages, true, true);
          notReadySound.play();
          this.setState({unClick: true});
          searchOrderSuccess = false;
        }
        this.setFocus();
        let data = this.state.data;
        data['input'] = '';
        this.setState({oldOne: this.props.searchOrder, data, loading: false});
      }
    }
    if (
      validateUser &&
      this.props &&
      this.props.userInfo &&
      this.props.userInfo !== this.state.userInfo
    ) {
      if (this.props.userInfo.is_active === false) {
        validateUser = false;
        this.props.UserInfo(false);
        this.redirect();
      }
    }

    if (
      !this.state.isImportCompleted &&
      this.props &&
      this.props.progress &&
      this.props.progress === 100
    ) {
      this.setState({
        isImportCompleted: true,
        progress: this.props.progress
      });
      if (this.progressTimeout) {
        clearTimeout(this.progressTimeout);
      }
    }

    !this.props.isOpen && isToFocus && this.inputFocus.current.focus();

    if (
      global.globalImportOrderStatus == 'in_progress' &&
      this.state.orderStatusApiCall
    ) {
      this.props.ImportOrderStatus();
      this.setState({orderStatusApiCall: false});
    }
  }

  closeAlert = () => {
    this.setState({popUpModelShow: false});
    this.props.ShowNotification({value: false, codeFound: false});
  };
  closePopup = () => {
    this.setState({reScanPopupShow: false});
  };
  redirectToAnother = () => {
    this.setState({reScanPopupShow: false, reScanOrderPopupShow: true});
  };
  closePopupRescan = () => {
    this.setState({reScanOrderPopupShow: false});
  };
  awaitOrderDataFun = async parameter => {
    const data = {
      filter: 'all',
      inverted: false,
      limit: 20,
      offset: 0,
      order: 'DESC',
      orderArray: [{id: this.state?.reScanPopupCond?.id}],
      product_search_toggle: 'true',
      reallocate_inventory: false,
      search: '',
      select_all: false,
      sort: '',
      status: 'awaiting',
      pull_inv: parameter,
      on_ex: 'on GPX'
    };
    this.props.ResetSearchOrderData();
    this.props.UpdateOrderStatus(data, this.callBack);
    await AsyncStorage.setItem('statusChange', 'pending');
  };
  callBack = async () => {
    let time = attempts == 0 ? 3000 : 2000;
    this.closePopupRescan();
    this.setState({newLoading: true, loaderTitle: 'Loading...'});
    setTimeout(() => {
      this.handleCallBack();
    }, time);
  };
  handleCallBack = async () => {
    attempts++;
    const statusChange = await AsyncStorage.getItem('statusChange');
    if (statusChange === 'completed') {
      this.searchOrder(this.state.orderNumberInput);
      this.setState({orderNumberInput: '', newLoading: false});
      await AsyncStorage.removeItem('statusChange');
      attempts = 0;
    } else {
      attempts <= 3 && (await this.callBack());
    }
  };

  redirect = async () => {
    // await AsyncStorage.removeItem("access_token")
    await AsyncStorage.clear();
    this.props.navigation.navigate('SignIn', {
      status: 'Inactive',
      time: new Date()
    });
    this.setState({userInfo: this.props.userInfo});
  };
  //After the apis call update the status (scanned , order  not present)
  statusFunction = message => {
    let data = this.state.data;
    data['input'] = '';
    this.setState({rfoTitle: message, data});
  };

  //To set the focus
  setFocus = () => {
    this.inputFocus.current.focus();
  };

  //Redirect to the scanpackItem page with order details
  orderDetails = order => {
    const order_data = order?.scan_hash.data.order;
    this.setState({
      reScanPopupCond: this.props?.searchOrder?.searchOrder?.data?.order[0]
    });
    if (this?.props?.userInfo?.role.access_scanpack) {
      if (
        order &&
        !order_data.unscanned_items[0] &&
        order_data.status != 'scanned' &&
        !order_data.scanned_items[0]
      ) {
        this.alertBox(i18n.t('scanPack.empty_order'), true, true);
        this.setState({loading: true, loaderTitle: 'Loading...'});
        this.getOrder();
        return;
      }
      if (order && order.scan_hash && order.scan_hash.data) {
        let packingcamPage =
          order.scan_hash.data.order.increment_id ==
          this?.props?.route?.params?.order_in
            ? this?.props?.route?.params?.page
            : '';
        this.setState({showQuick: false});
        this.props.navigation.navigate('ScanPackItem', {
          data: order.scan_hash.data,
          userdata: this?.props?.userInfo,
          packingcamPage: packingcamPage
        });
        messageShow = false;
      } else if (order?.order_info.status === 'scanned') {
        this.setState({
          reScanPopupShow: true
        });
      }
    } else {
      this.alertBox(
        i18n.t('scanPack.permissionDeniedTitle') +
          '\n' +
          i18n.t('scanPack.permissionDeniedMessage'),
        true,
        true
      );
    }
  };
  //Check the connection for mobile device before call the api
  searchOrder = input => {
    this.setState({
      manageApiStatus: false,
      loading: true,
      loaderTitle: 'Loading...',
      orderNumberInput: input
    });
    if (input !== '') {
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        this.checkConnection('callShipmentOrder', input);
      } else {
        this.callShipmentOrder(input);
        setTimeout(() => {
          this.setState({manageApiStatus: true});
        }, 5000);
      }
    }
  };
  //Api to call the search order
  callShipmentOrder = input => {
    let shipmentOrder = this.state.data;
    let isScanToCartEnabled =
      this.props.scanPackWorkflow === 'scan_to_cart' &&
      this.props.scanToCartOption;
    shipmentOrder['input'] = input;
    shipmentOrder['scan_to_cart_enabled'] = isScanToCartEnabled;
    if (input !== '') {
      shipmentOrder.input = input;
      this.props.SearchScanpackOrder(shipmentOrder,isScanToCartEnabled ? this.handleCartAssignmentCallBack : undefined);
      searchOrderSuccess = true;
      let apiCallTime = new Date();
      this.setState({apiCallTime});
    }
  };

  handleCartAssignmentCallBack = (cartId, message) => {
    if(cartId && message) {
      this.setState({cartId: cartId,showCartAssignmentPrompt: true,loading: false})
    }
  }
  //Handle the search field input
  handleChange = (name, value) => {
    let order = this.state.data;
    if (name === 'searchOrder') {
      order['input'] = value;
    }
    if (this?.props?.userInfo?.role?.import_orders) {
      if (value === 'QUICKSCAN') {
        this.setState({showQuick: true});
        this.callShipmentOrder(this.state.rfoTitle);
      }
    }
    this.setState({order});
  };

  importorderFun = () => {
    global.progressBarValue = 0;
    global.isVisibleImportInitiated = false;
    global.globalImportOrderStatus = 'import_initiate';
    global.importIntiated = 'Import initiated';
    this.setState({
      showProgressBar: true,
      progress: 0,
      importInProgress: true,
      animatingType: true,
      showStatus: true,
      isImportCompleted: false
    });
    global.importButton = false;
    let isCallApi = true;
    let isCheckImportOrderProcess = true;

    const intialInterval = setInterval(() => {
      if (
        global.globalImportOrderStatus == undefined ||
        global.globalImportOrderStatus == 'fetch_api_response' ||
        global.globalImportOrderStatus != 'in_progres'
      ) {
        this.setState(prevState => {
          let newProgress = prevState.progress + 20;
          global.progressBarValue = newProgress;

          if (newProgress >= 105) {
            if (isCallApi == true) {
              isCallApi = false;
              this.props.ImportOrder();
            }
            newProgress = 0;
            global.progressBarValue = 0;
            global.globalImportOrderStatus = 'fetch_api_response';
            global.importIntiated = 'Receiving API Response';
            return {
              progress: newProgress
            };
          }

          if (global.globalImportOrderStatus == 'in_progress') {
            global.importIntiated = 'In Progress';
            if (newProgress == 100 && isCheckImportOrderProcess) {
              isCheckImportOrderProcess = false;
              this.props.CheckImportOrderProcess(importOrderStoreId);
              console.log('Check Import Order Status');
              if (global.globalImportOrderStatus === 'in_progress') {
                setTimeout(() => {
                  this.setState({
                    checkImportStatus: true,
                    isVisibleCheckImportStautsBox: true
                  });
                }, 5000);
              }
              return {
                progress: newProgress,
                isVisibleCheckImportStautsBox: true
              };
            }
            return {progress: newProgress};
          }
          if (
            global.globalImportOrderStatus == 'cancelled' ||
            global.globalImportOrderStatus == 'completed'
          ) {
            newProgress = 0;
            global.progressBarValue = 0;
            clearInterval(intialInterval);
            this.setState({
              checkImportStatus: false,
              isVisibleCheckImportStautsBox: false
            });
          }
          return {progress: newProgress};
        });
      }
    }, 1000);
  };

  componentWillUnmount() {
    this.props.GetOrderList(this.state.dataList, false);
    this.setState({importOrderText: ''});
    if (this._unsubscribe) {
      this._unsubscribe();
    }
  }

  drawerClose = () => {
    this.props.route.params.closeDrawer();
  };

  refreshStatus = () => {
    this.props.ImportOrderStatus();
    this.setState({showStatus: true, CheckImportButton: true});
  };

  tooltipFun = () => {
    this.setState({
      toolTipCheck: !this.state.toolTipCheck,
      quickScanToolTip: !this.state.quickScanToolTip
    });
  };

  refreshTooltipFun = () => {
    this.setState({refreshToolTip: !this.state.refreshToolTip});
  };

  test = async () => {
    let num = await InterNetChecks();
    if (num !== true) {
      this.setState({popInternet: false});
    } else {
      this.setState({popInternet: true});
    }
  };

  closeRescanPopup = () => {
    this.setState({reScanPopupShow: false});
  };

  lastImportOrderDetails = async () => {
    const importedOrders = await AsyncStorage.getItem('imported_orders');
    let totalImported = 'No orders found';
    if (importedOrders) {
      try {
        totalImported = JSON.parse(importedOrders);
      } catch {
        console.error('Error parsing importedOrders:', importedOrders);
      }
    }

    // Retrieve and validate imported date
    const importedDate = await AsyncStorage.getItem('imported_date');
    let lastImportedData = 'No date found';
    if (importedDate) {
      try {
        lastImportedData = JSON.parse(importedDate);
      } catch {
        console.error('Error parsing importedDate:', importedDate);
      }
    }

    // Retrieve and validate import start
    const startData = await AsyncStorage.getItem('import_start');
    let importStart = 'No start time found';
    if (startData) {
      try {
        importStart = JSON.parse(startData);
      } catch {
        console.error('Error parsing importStart:', startData);
      }
    }

    // Retrieve and validate import end
    const endData = await AsyncStorage.getItem('import_end');
    let importEnd = 'No end time found';
    if (endData) {
      try {
        importEnd = JSON.parse(endData);
      } catch {
        console.error('Error parsing importEnd:', endData);
      }
    }

    // Retrieve and validate elapsed time
    const elapsedData = await AsyncStorage.getItem('elapsed_time');
    let elapsedTime = 'No elapsed time found';
    if (elapsedData) {
      try {
        elapsedTime = JSON.parse(elapsedData);
      } catch {
        console.error('Error parsing elapsedTime:', elapsedData);
      }
    }

    // Format the data for copying
    const clipboardData = `
    IMPORTED ORDERS:   ${totalImported}
    IMPORTED DATE:     ${lastImportedData}
    IMPORT START:      ${importStart}
    IMPORT END:        ${importEnd}
    ELAPSED TIME:      ${elapsedTime}
    `;

    // Copy to clipboard
    Clipboard.setString(clipboardData);
  };

  render() {
    const formattedDate = this.props.checkOrderStatus?.last_imported_data
      ? this.formatDate(this.props.checkOrderStatus.last_imported_data)
      : i18n.t('scanPack.noDataAvailable');

    return (
      <>
        <View
          style={{flex: 1, zIndex: '99'}}
          testID="closeTooltip"
          onStartShouldSetResponder={() => {
            this.drawerClose(),
              this.state.toolTipCheck ? this.tooltipFun() : <></>;
            this.state.refreshToolTip ? this.refreshTooltipFun() : <></>;
          }}>
          {
            // Notification Pop Up for messages
            (this.state.popUpModelShow || this.props.showNotification) && (
              <PopUpModel
                closeAlert={this.closeAlert.bind(this)}
                message={this.state.message}
                messageTypeSuccess={
                  this.state.messageTypeSuccess ? true : false
                }
              />
            )
          }

          {this.state.reScanPopupCond && !this.props?.isLoadingActive ? (
            <RescanPopup
              headingmessage={i18n.t('scanPack.alreadyScanned')}
              subHeading={i18n.t('scanPack.howToProceed')}
              cancelButton="Cancel"
              rePrintBtn={i18n.t('scanPack.rePrint')}
              reScanBtn={i18n.t('scanPack.reScan')}
              closePopup={this.closePopup.bind(this)}
              redirectToAnother={this.redirectToAnother.bind(this)}
              reScanOrderPopupShow={this.state.reScanOrderPopupShow}
              reScanPopupShow={this.state.reScanPopupShow}
              closePopupRescan={this.closePopupRescan.bind(this)}
              awaitOrderDataFun={parameter => this.awaitOrderDataFun(parameter)}
              newProps={this.props}
              navigation={this.props?.navigation}
              basicInfo={this.props?.searchOrder?.searchOrder?.data?.order?.[0]}
              generalSetting={this.props?.ordersList?.general_settings}
              userRole={this.props?.userInfo}
              newState={this.state.reScanPopupCond}
              isLoadingHere={this.props?.isLoadingActive}
              ssLabelData={this.props?.ssLabelData}
            />
          ) : (
            <></>
          )}
          {this.state.popInternet === false ? (
            <View style={{position: 'absolute', zIndex: 99, top: '-10%'}}>
              <InerternetConnectionAlert />
            </View>
          ) : null}
          <ImageBackground
            style={{flex: 1}}
            source={require('././../../../assets/background.png')}>
            <ScrollView
              contentContainerStyle={{flex: 1}}
              style={{position: 'relative'}}>
              <ImageBackground
                style={CommonStyles.quickScanImage}
                source={require('././../../../assets/scaning.png')}
                resizeMode="contain">
                <Text
                  style={[
                    CommonStyles.ScanText,
                    {lineHeight: this.state.windowwidth < 768 ? 55 : 90}
                  ]}>
                  {i18n.t('scanPack.scanLabel')}
                </Text>
                <Text style={[CommonStyles.lastImportTextOne, {marginTop: 0}]}>
                  {this?.state?.cueOrderStatus}
                </Text>

                <View
                  style={[
                    CommonStyles.searchContainer,
                    {width: this.state.windowwidth > 900 ? '30%' : '95%'}
                  ]}>
                  <TextInput
                    testID="searchOrder"
                    name="searchOrder"
                    autoFocus={true}
                    editable={this.state.manageApiStatus}
                    dataSet={{componentName: 'searchOrder'}}
                    placeholder={i18n.t('scanPack.inputPlaceHolder')}
                    ref={this.inputFocus}
                    value={
                      this.state.showQuick ? 'QUICKSCAN' : this.state.data.input
                    }
                    onChangeText={text => {
                      this.handleChange('searchOrder', text);
                    }}
                    onSubmitEditing={() => {
                      if (this.state.data.input === '') return;
                      this.searchOrder(
                        this.state.data && this.state.data.input
                      );
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        if (this.state.data.input === '') return;
                        this.searchOrder(
                          this.state.data && this.state.data.input
                        );
                      }
                    }}
                    style={CommonStyles.scanInput}
                  />
                  <TouchableOpacity
                    testID="rfoSearchBtn"
                    style={{paddingVertical: 4, paddingHorizontal: 5}}
                    onPress={() => {
                      if (this.state.data.input === '') return;
                      this.searchOrder(
                        this.state.data && this.state.data.input
                      );
                    }}>
                    <i
                      className="icon-search"
                      style={{
                        color: 'rgb(172,187,199)',
                        fontSize: '23px'
                      }}></i>
                  </TouchableOpacity>
                </View>
                {(this.state.newLoading || this.props.isLoadingActive) && (
                  <View
                    style={{
                      position: 'fixed',
                      left: 0,
                      right: 0,
                      height: '100%',
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#000',
                      opacity: '60%',
                      zIndex: 2147483647
                    }}>
                    <ActivityIndicator size="large" color="#fff" />
                    <Text
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                        textAlign: 'center',
                        color: '#fff'
                      }}>
                      Loading...
                    </Text>
                  </View>
                )}
                {this.state.loading ? (
                  <View style={{flex: 1, justifyContent: 'center'}}>
                    <ActivityIndicator size="large" color="#336599" />
                  </View>
                ) : (
                  <>
                    {this.state.error ? (
                      <Text style={styles.text}>{this.state.error}</Text>
                    ) : null}
                  </>
                )}
                <View
                  style={[
                    CommonStyles.quickScanAlignmentOne,
                    {display: !this.state.rfoTitle && 'none'}
                  ]}>
                  <LinearGradient
                    colors={['#95abbf', '#516b83']}
                    style={CommonStyles.quickScanRfo}>
                    <View />
                    <TouchableOpacity
                      testID="quickSearch"
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                      dataSet={{componentName: 'quickSearch'}}
                      onPress={() => {
                        if (
                          this.state.rfoTitle &&
                          this.state.popInternet === true
                        ) {
                          this?.props?.userInfo?.role?.access_scanpack &&
                            this.searchOrder(this.state.rfoTitle);
                        }
                      }}>
                      <View style={{alignItems: 'center'}}>
                        <Text
                          testID="quickScan"
                          style={CommonStyles.quickScanText}>
                          {i18n.t('scanPack.quickScanLabel')}
                        </Text>
                        {!this.state.loading ? (
                          <Text style={CommonStyles.quickScanLoadingText}>
                            {this.state.rfoTitle
                              ? this.state.rfoTitle
                              : i18n.t('scanPack.noOrderPresent')}
                          </Text>
                        ) : null}
                      </View>
                      <i
                        className="icon-right-circled"
                        style={{
                          fontSize: 24,
                          color: 'white',
                          marginTop: 12
                        }}></i>
                    </TouchableOpacity>
                  </LinearGradient>
                  <TouchableOpacity
                    testID="tooltipFun"
                    onPress={() => this.tooltipFun()}>
                    <i
                      className="icon-info-circled"
                      style={{
                        color: 'rgb(119,143,165)',
                        fontSize: '24px',
                        marginLeft: '10px'
                      }}></i>
                  </TouchableOpacity>
                </View>
                {this.state.quickScanToolTip && (
                  <LinearGradient
                    testID="quickScanToolTip"
                    locations={[0, 1]}
                    colors={['#8a9daf', '#d7e1e9']}
                    style={CommonStyles.quickScanTooltipContainer}>
                    <Text style={CommonStyles.quickScanTextOne}>
                      {i18n.t('scanPack.quickScanTollTip')}
                    </Text>
                    <View style={CommonStyles.quickScanTooltipContainerOne} />
                  </LinearGradient>
                )}
                <AssignOrdersButton
                  setFocus={value => {
                    isToFocus = value;
                  }}
                  getOrder={this.getOldestUnscannedOrder}
                />
                <CartAssignmentPrompt 
                  showPrompt={this.state.showCartAssignmentPrompt}
                  setShowPrompt={value=>this.setState({showCartAssignmentPrompt: value})}
                  cartId={this.state.cartId}
                  payload={this.state.data}
                />
                <View style={styles.importOrderMessage}>
                  {global.isVisibleImportInitiated &&
                    (this.props.importOrder.importstatusData?.summary
                      ?.import_info?.status === 'cancelled' ? (
                      <QuickScanComponent
                        customStyle={{flexDirection: 'row'}}
                        importOrderText={this.state.importOrderText}
                        onCopyPress={() => {
                          this.lastImportOrderDetails();
                        }}
                      />
                    ) : this.props.importOrder.importstatusData?.summary
                        ?.import_info?.status == 'completed' ? (
                      <QuickScanComponent
                        customStyle={{flexDirection: 'row'}}
                        importOrderText={this.state.importOrderText}
                        onCopyPress={() => {
                          this.lastImportOrderDetails();
                        }}
                      />
                    ) : this.props?.importOrder?.importstatusData?.summary
                        ?.import_info?.status == 'not_started' ? (
                      <QuickScanComponent
                        customStyle={[
                          CommonStyles.quickScanAlignment,
                          {flexDirection: 'row'}
                        ]}
                        importOrderText={this.state.importOrderText}
                        onCopyPress={() => {
                          this.lastImportOrderDetails();
                        }}
                      />
                    ) : this.props?.importOrder?.importstatusData?.summary
                        ?.import_info?.status == 'in_progress' ? (
                      <QuickScanComponent
                        customStyle={[
                          CommonStyles.quickScanAlignment,
                          {flexDirection: 'row'}
                        ]}
                        importOrderText={this.state.importOrderText}
                        onCopyPress={() => {
                          this.lastImportOrderDetails();
                        }}
                      />
                    ) : (
                      <View></View>
                    ))}
                </View>
                {/* check import show button */}
                <View
                  style={[
                    CommonStyles.importButtonRfo,
                    {alignSelf: 'center', margintop: 20}
                  ]}>
                  {this.state.checkImportStatus == false && (
                    <>
                      {((global.globalImportOrderStatus != 'cancelled' ||
                        global.globalImportOrderStatus != 'completed') &&
                        global.globalImportOrderStatus == 'import_initiate') ||
                      global.globalImportOrderStatus == 'not_started' ||
                      global.globalImportOrderStatus == 'fetch_api_response' ||
                      global.importIntiated == 'Receiving API Response' ||
                      global.globalImportOrderStatus == 'in_progress' ? (
                        <View style={styles.progressBarDimensionWrapper}>
                          <ProgressBar
                            progress={global.progressBarValue}
                            customStyle={{width: (width * 15) / 100}}
                            actionMsg={''}
                          />
                        </View>
                      ) : null}
                    </>
                  )}

                  {/* Show Import Now when import is not completed */}

                  {global.importButton &&
                    global.importIntiated != 'Receiving API Response' && (
                      <>
                        <TouchableOpacity
                          testID="ImportOrder-testId"
                          onPress={() => {
                            this?.props?.userInfo?.role?.import_orders &&
                            this.state.popInternet === true ? (
                              this.importorderFun()
                            ) : (
                              <></>
                            );
                          }}>
                          <LinearGradient
                            colors={['#95abbf', '#516b83']}
                            style={CommonStyles.quickScanContainer2}>
                            <Text
                              style={[CommonStyles.reFreshText, {width: 150}]}>
                              {i18n.t('scanPack.importLabel')}
                            </Text>
                          </LinearGradient>
                        </TouchableOpacity>
                        <TouchableOpacity
                          testID="refreshTooltipFun"
                          onPress={() => this.refreshTooltipFun()}>
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(119,143,165)',
                              fontSize: '24px',
                              marginTop: '35px',
                              position: 'absolute'
                            }}></i>
                          {this.state.refreshToolTip && (
                            <LinearGradient
                              testID="refreshToolTip"
                              locations={[0, 1]}
                              colors={['#8a9daf', '#d7e1e9']}
                              style={CommonStyles.refreshTextTooltip}>
                              <Text style={CommonStyles.quickScanTextOne}>
                                {i18n.t('scanpackItem.refreshTooltip')}
                              </Text>
                              <View
                                style={CommonStyles.refreshTooltipContainerOne}
                              />
                            </LinearGradient>
                          )}
                        </TouchableOpacity>
                      </>
                    )}
                  <View style={{height: 40, width: '100%'}} />
                </View>
                {global.isVisibleImportInitiated == false && (
                  <View style={CommonStyles.quickScanAlignment}>
                    <Text style={CommonStyles.lastImportText}>
                      {global.importIntiated}
                    </Text>
                  </View>
                )}
                {this.state.checkImportStatus == true && (
                  <TouchableOpacity
                    onPress={async () => {
                      this.props.CheckImportOrderProcess(importOrderStoreId);

                      // Hide the button and reset progress
                      this.setState({checkImportStatus: false, progress: 0});

                      // Re-show the button after 5 seconds
                      setTimeout(() => {
                        this.setState({checkImportStatus: true});
                      }, 5000);
                    }}>
                    <LinearGradient
                      colors={['#95abbf', '#516b83']}
                      style={styles.checkImportStatusLabelWrapper}>
                      <Text style={styles.checkImportStatusLabel}>
                        {i18n.t('scanPack.importStatusLabel')}
                      </Text>
                    </LinearGradient>
                  </TouchableOpacity>
                )}
                {this.state.isVisibleCheckImportStautsBox == true && (
                  <>
                    <View style={styles.importStatusContainer}>
                      <Text style={styles.importStatusLabel}>
                        {i18n.t('scanPack.importStatus.status')}
                      </Text>
                      <View style={styles.importStatusSubContainer}>
                        <Text style={styles.importStatLabel}>{`${i18n.t(
                          'scanPack.importStatus.last'
                        )} : ${
                          this.props.checkOrderStatus?.completed ?? 0
                        }`}</Text>
                        <Text style={styles.importStatLabel}>{`${i18n.t(
                          'scanPack.importStatus.current'
                        )}: ${
                          this.props.checkOrderStatus?.in_progess ?? 0
                        }`}</Text>
                      </View>
                      <View style={styles.importStatusSubContainer}>
                        <Text style={styles.importStatLabel}>{`${i18n.t(
                          'scanPack.importStatus.processed'
                        )}: ${
                          this.props.checkOrderStatus?.total_imported ?? 0
                        }`}</Text>
                        <Text style={styles.importStatLabel}>{`${i18n.t(
                          'scanPack.importStatus.remaining'
                        )}: ${
                          this.props.checkOrderStatus?.remaining_items ?? 0
                        }`}</Text>
                      </View>
                      <View style={styles.importStatusSubContainer}>
                        <Text style={styles.importStatLabel}>{`${i18n.t(
                          'scanPack.importStatus.elapsedTime'
                        )}: ${
                          this.props.checkOrderStatus?.elapsed_time ?? 0
                        }`}</Text>
                        <Text style={styles.importStatLabel}>{`${i18n.t(
                          'scanPack.importStatus.timeRemaining'
                        )}: ${
                          this.props.checkOrderStatus?.elapsed_time_remaining ??
                          0
                        }`}</Text>
                      </View>
                      <Text style={styles.lastImportLabel}>{`${i18n.t(
                        'scanPack.importStatus.lastImport'
                      )}: ${formattedDate ?? 0}`}</Text>
                    </View>
                  </>
                )}
              </ImageBackground>
            </ScrollView>
          </ImageBackground>
        </View>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    ssLabelData: state.order.ssLabelData,
    isLoadingActive: state?.order?.isLoadingActive,
    ordersList: state.order.list,
    searchOrder: state.scanpack,
    userInfo: state.user.userInfo,
    importOrder: state.importOrder,
    showNotification: state.menu.showNotification,
    isCodeFound: state.menu.isCodeFound,
    isOpen: state.menu.isOpen,
    bothSettings: state.user.bothSettings,
    purchaseLoading: state.order.purchaseLoading,
    scanPackWorkflow: state.user.settings?.data?.scan_pack_workflow,
    scanToCartOption:
      state.user.bothSettings?.data?.data?.scanpack_setting
        ?.scan_to_cart_option,
    checkOrderStatus: state.scanpack.checkOrderStatus
  };
};

const mapDispatchToProps = {
  GetOrderList,
  SearchScanpackOrder,
  ShowNotification,
  UserInfo,
  ImportOrder,
  ImportOrderStatus,
  UpdateOrderStatus,
  ResetSearchOrderData,
  GetBothSettings,
  GetGeneralSetting,
  ResetOrderList,
  ResetSearchOrderData,
  CheckImportOrderProcess
};
export default connect(mapStateToProps, mapDispatchToProps)(ScanPack);
