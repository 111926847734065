import { useEffect, useRef } from 'react';
const usePolling = (callback, interval = 5000) => {
  const timeoutRef = useRef(null);
  
  useEffect(() => {
    timeoutRef.current = setTimeout(function runPolling() {
      callback();
      timeoutRef.current = setTimeout(runPolling, interval);
    }, interval);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [callback, interval]);
};

export default usePolling;
