import React, { useEffect, useRef, useState } from 'react';
import { Text, View, TextInput, TouchableOpacity,ActivityIndicator, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import CommonStyles from '../../style/commonStyles';
import { LinearGradient } from 'expo-linear-gradient';
import { useSelector } from 'react-redux';
import i18n from 'i18n-js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AssignOrders } from '../../actions/orderActions';
import I18n from 'i18n-js';

export const AssignOrdersButton = ({setFocus, getOrder}) => {
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Assigning Orders...');
  const [checkingStatus, setCheckingStatus] = useState(false);

  const user = useSelector(state => state.user.userInfo);
  const orderList = useSelector(state => state.order.list?.orders);
  const requiresAssignedOrders = useSelector(
    (state) => state.user?.bothSettings?.data?.data?.scanpack_setting?.requires_assigned_orders
  );
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [inputText, setInputText] = useState('15');

  const handleAssignOrder = async () => {
    try {
      setIsLoading(true);
      setCheckingStatus(false);
      setLoadingMessage('Assigning Orders...');
      const payLoad = {no_of_orders: inputText};
      await AsyncStorage.setItem('assignOrderStatus', 'pending');

      await AssignOrders(payLoad, handleCallBack);
      const storageKey = getStorageKey();
      if (storageKey) {
        await AsyncStorage.setItem(storageKey, inputText);
      }
    } catch (error) {
      console.error('Error saving value:', error);
    }
  };

  const handleCallBack = (message) => {
    if (message === '0' || message === 0) {
      setIsLoading(false);
      setCheckingStatus(false);
      setMessageVisible(true);
      setMessage('0');
      setTimeout(() => {
        setMessage('');
        setMessageVisible(false);
      }, 3000);
      return;
    }

    if (message) {
      AsyncStorage.setItem('assignResponseMessage', message);
    }

    setCheckingStatus(true);
    setMessageVisible(true);
    setMessage('Order assignment initiated');
    setTimeout(() => {
      checkAssignmentStatus();
    }, 2000);
  };

  const checkAssignmentStatus = async () => {
    setLoadingMessage('Checking assignment status...');

    try {
      const status = await AsyncStorage.getItem('rfoAssignment');
      
      if (status === 'completed') {
        await AsyncStorage.removeItem('rfoAssignment');
        processCompletedAssignment();
      } else {
        setTimeout(() => {
          checkAssignmentStatus();
        }, 2000);
      }
    } catch (error) {
      console.error('Error checking assignment status:', error);
      setIsLoading(false);
      setCheckingStatus(false);
    }
  };

  const processCompletedAssignment = async () => {
    setIsLoading(false);
    setCheckingStatus(false);

    const assignedCount = await AsyncStorage.getItem('assignedOrdersCount');
    const responseMessage = await AsyncStorage.getItem('assignResponseMessage');
    const count = assignedCount || responseMessage || '0';

    await AsyncStorage.removeItem('assignedOrdersCount');
    await AsyncStorage.removeItem('assignResponseMessage');

    setMessageVisible(true);
    setMessage(count);

    setTimeout(() => {
      setMessage('');
      setMessageVisible(false);
      getOrder();
    }, 4000);
  };

  const getStorageKey = () => {
    return user?.current_tenant
      ? `assignOrdersCount.${user.current_tenant}`
      : null;
  };

  useEffect(() => {
    const loadSavedValue = async () => {
      try {
        const storageKey = getStorageKey();
        if (storageKey) {
          const savedValue = await AsyncStorage.getItem(storageKey);
          if (savedValue) {
            setInputText(savedValue);
          }
        }
      } catch (error) {
        console.error('Error loading saved value:', error);
      }
    };

    loadSavedValue();
  }, [user?.current_tenant]);

  if (!requiresAssignedOrders || orderList?.length > 0) {
    return null;
  }

  const handleSubmit = () => {
    setFocus(true);
    handleAssignOrder();
  };

  return (
    <View style={[CommonStyles.quickScanAlignmentOne, styles.container]}>
      <View style={styles.row}>
        <LinearGradient colors={['#95abbf', '#516b83']} style={CommonStyles.quickScanRfo}>
          <TouchableOpacity
            testID="quickSearch"
            style={styles.button}
            dataSet={{ componentName: 'quickSearch' }}
            onPress={handleAssignOrder}
            disabled={isLoading || checkingStatus}>
            <View style={styles.buttonContent}>
              <Text testID="quickScan" style={CommonStyles.quickScanText}>
                {I18n.t("assignOrder.assign")}
              </Text>
              <TouchableWithoutFeedback>
                <TextInput
                  ref={inputRef}
                  style={styles.input}
                  autoFocus={false}
                  onFocus={()=>{setFocus(false)}}
                  value={inputText}
                  onChangeText={text => setInputText(text)}
                  onSubmitEditing={handleSubmit}
                  onBlur={()=>{setFocus(true)}}
                  editable={!isLoading && !checkingStatus}
                />
              </TouchableWithoutFeedback>
              <Text testID="quickScan" style={CommonStyles.quickScanText}>
                {I18n.t("assignOrder.orders")}
              </Text>
            </View>
            <i className="icon-right-circled" style={styles.icon} />
          </TouchableOpacity>
        </LinearGradient>

        <TouchableOpacity
          testID="tooltipFun"
          onPress={() => setTooltipVisible(!tooltipVisible)}>
          <i className="icon-info-circled" style={styles.tooltipIcon} />
        </TouchableOpacity>
      </View>
      {(isLoading || checkingStatus) && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="small" color="#fff" style={{ marginRight: 10 }} />
          <Text style={styles.loadingText}>{loadingMessage}</Text>
        </View>
      )}
      {messageVisible && (
        <View style={styles.messageContainer}>
          <Text style={styles.messageText}>{Number(message) > 0
            ? `${message} ${I18n.t("assignOrder.successMessage")}`
            : message || I18n.t("assignOrder.notAssignedMessage")}</Text>
        </View>
      )}
      <View>
        {tooltipVisible && (
          <LinearGradient
            testID="quickScanToolTip"
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={CommonStyles.quickScanTooltipContainer}>
            <Text style={CommonStyles.quickScanTextOne}>
              {i18n.t('scanPack.assignOrderTooltip')}
            </Text>
            <View style={[CommonStyles.quickScanTooltipContainerOne, styles.tooltipContainer]} />
          </LinearGradient>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContent: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  input: {
    height: 25,
    width: 35,
    backgroundColor: '#3A3B3B',
    borderRadius: 10,
    textAlign: 'center',
    color: '#ccc',
    marginHorizontal: 5,
    borderColor: '#717374',
    borderWidth: 1,
  },
  icon: {
    fontSize: 24,
    color: 'white',
    marginTop: 12,
  },
  tooltipIcon: {
    color: 'rgb(119,143,165)',
    fontSize: 24,
    marginLeft: 10,
    marginTop: 12,
  },
  tooltipContainer: {
    right: '10%',
  },
  messageContainer: {
    marginTop: 10,
    padding: 10,
    borderRadius: 5,
  },
  messageText: {
    color: '#fff',
    textAlign: 'center',
  },
  loadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    padding: 5,
  },
  loadingText: {
    color: '#fff',
  },
});



