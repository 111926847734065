import React from 'react';
import {View, Text, TouchableOpacity, Image, StyleSheet} from 'react-native';
import CopyImage from '../../../assets/clipboard-icon.png';
import {fontFamily} from '../../helpers/fontFamily';

const QuickScanComponent = ({customStyle, importOrderText, onCopyPress}) => {
  return (
    <View style={customStyle}>
      <Text style={styles.importText}>{importOrderText}</Text>
      <TouchableOpacity onPress={onCopyPress}>
        <Image
          source={CopyImage}
          style={styles.copyIcon}
          resizeMode="contain"
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  importText: {
    color: '#FFFFFF',
    fontFamily: fontFamily.font300,
    marginBottom: 7,
    fontSize: 16,
    textAlign: 'center'
  },
  copyIcon: {
    marginLeft: 25,
    width: 20,
    height: 20,
    tintColor: '#FFF'
  }
});

export default QuickScanComponent;
