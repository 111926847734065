import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  STATISTICS_LIST,
  STATISTICS_LIST_FAIL,
  STATISTICS_TODAY_VS_YESTERDAY_LIST,
  STATISTICS_TODAY_VS_YESTERDAY_LIST_FAIL,
  STATISTICS_WITHIN15MIN_LIST,
  STATISTICS_WITHIN15MIN_LIST_FAIL,
  STATISTICS_WEEKLY_LIST,
  STATISTICS_WEEKLY_LIST_FAIL
} from '../constants';

export const getStatisticsDetails = () => async dispatch => {
  const access_token = await AsyncStorage.getItem('access_token');
  const tenent = await AsyncStorage.getItem('tenent');
  try {
    const response = await axios.get(
      `https://api-gl.groove-packer.com/dashboard/expo_dashboard?interval=particular_user`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${access_token}`,
          domain: 'groovepackerapi.com',
          tenant: tenent
        }
      }
    );
    dispatch({type: STATISTICS_LIST, payload: response});
  } catch (error) {
    dispatch({type: STATISTICS_LIST_FAIL, payload: []});
  }
};

export const getStatisticsTodayVsYesterdayDetails = () => async dispatch => {
  const access_token = await AsyncStorage.getItem('access_token');
  const tenent = await AsyncStorage.getItem('tenent');
  try {
    const response = await axios.get(
      `https://api-gl.groove-packer.com/dashboard/expo_dashboard?interval=today_vs_yesterday`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${access_token}`,
          domain: 'groovepackerapi.com',
          tenant: tenent
        }
      }
    );
    dispatch({type: STATISTICS_TODAY_VS_YESTERDAY_LIST, payload: response});
  } catch (error) {
    dispatch({type: STATISTICS_TODAY_VS_YESTERDAY_LIST_FAIL, payload: []});
  }
};

export const getStatisticsWithIn15MinDetails = () => async dispatch => {
  const access_token = await AsyncStorage.getItem('access_token');
  const tenent = await AsyncStorage.getItem('tenent');
  const new_time_zone = await AsyncStorage.getItem('new_time_zone');
   
  try {
    const response = await axios.get(
      `https://api-gl.groove-packer.com/dashboard/expo_dashboard?interval=within_15min`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${access_token}`,
          domain: 'groovepackerapi.com',
          tenant: tenent
        },
        params: {
          new_time_zone: new_time_zone 
        }
      }
    );
    dispatch({type: STATISTICS_WITHIN15MIN_LIST, payload: response});
  } catch (error) {
    dispatch({type: STATISTICS_WITHIN15MIN_LIST_FAIL, payload: []});
  }
};

export const getStatisticsWeeklyDetails = () => async dispatch => {
  const access_token = await AsyncStorage.getItem('access_token');
  const tenent = await AsyncStorage.getItem('tenent');
  try {
    const response = await axios.get(
      `https://api-gl.groove-packer.com/dashboard/expo_dashboard?interval=weekly`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${access_token}`,
          domain: 'groovepackerapi.com',
          tenant: tenent
        }
      }
    );
    dispatch({type: STATISTICS_WEEKLY_LIST, payload: response});
  } catch (error) {
    dispatch({type: STATISTICS_WEEKLY_LIST_FAIL, payload: []});
  }
};
