import axios from 'axios';
import { ADD_CART, DELETE_CART, FETCH_CARTS, UPDATE_CART, UPDATE_CART_LOCAL } from '../constants';
import AsyncStorage from '@react-native-async-storage/async-storage';

export function fetchCarts(callback) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.get(`${url}/carts.json`, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: FETCH_CARTS,
        payload: response.data
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Error fetching carts:', error);
      if (callback) {
        callback();
      }
    }
  };
}

export function printToteLabels(cartId, callBack) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios({
        method: 'get',
        url: `${url}/carts/${cartId}/print_tote_labels.pdf`,
        headers: {
          Authorization: `Bearer ${access_token}`
        },
        responseType: 'blob'
      });
      
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(blob);
      window.open(pdfUrl);
      
      if (callBack) {
        callBack(true);
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
      if (callBack) {
        callBack(false);
      }
    }
  };
}

export function addCart(cartData) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.post(`${url}/carts.json`, cartData, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: ADD_CART,
        payload: response.data
      });
    } catch (error) {
      console.error('Error creating carts:', error);
    }
  };
}

export function updateCart(cartId, cartData, callBack) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.patch(`${url}/carts/${cartId}.json`, cartData, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: UPDATE_CART,
        payload: response.data
      });
    } catch (error) {
      let errorMessage = 'Error updating cart';
      errorMessage = error.response.data.error || error.request || error.message || 'Cart is in use by orders';
      console.error('Error updating cart:', error);
      callBack(cartId);
    }
  };
}

export function updateCartDataLocal(cartData) {
  return dispatch => {
    dispatch({
      type: UPDATE_CART_LOCAL,
      payload: cartData
    });
  };
}

export function deleteCart(cartId, callback) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      await axios.delete(`${url}/carts/${cartId}.json`, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      
      dispatch({
        type: DELETE_CART,
        payload: cartId
      });
      
      if (callback) {
        callback('Cart Deleted');
      }
    } catch (error) {
      console.error('Error deleting cart:', error);
      if (callback) {
        callback(cartId);
      }
    }
  };
}
