import React, { useEffect, useRef, useState } from 'react';
import { View, ScrollView, StyleSheet, Animated, Easing, Dimensions } from 'react-native';

const AutoScrollWrapperVertical = ({
  children,
  enabled = true,
  speed = 20,
  pauseAtEnds = 3000,
  initialDelay = 700,
  easing = Easing.linear,
  showIndicators = true,
  style = {},
  contentContainerStyle = {}
}) => {
  const scrollViewRef = useRef(null);
  const scrollAnimatedValue = useRef(new Animated.Value(0)).current;
  const [scrollViewHeight, setScrollViewHeight] = useState(0);
  const [scrollContentHeight, setScrollContentHeight] = useState(0);
  const animationRef = useRef(null);
  const autoScrollEnabled = useRef(enabled);
  const initialScrollRef = useRef(true);
  const shouldScroll = scrollContentHeight > scrollViewHeight;
  const isFirstRenderRef = useRef(true);

  const getScrollDuration = (distance) => {
    return (distance / speed) * 1000;
  };

  const startScrollingDown = () => {
    if (!enabled || !scrollViewRef.current || !autoScrollEnabled.current) return;

    const scrollableDistance = scrollContentHeight - scrollViewHeight;
    if (scrollableDistance <= 0) return;

    const currentPosition = scrollAnimatedValue.__getValue();
    const remainingDistance = scrollableDistance - currentPosition;
    const duration = getScrollDuration(remainingDistance);

    if (animationRef.current) {
      animationRef.current.stop();
    }

    const delayBeforeStart = initialScrollRef.current ? initialDelay : 0;
    initialScrollRef.current = false;

    setTimeout(() => {
      animationRef.current = Animated.timing(scrollAnimatedValue, {
        toValue: scrollableDistance,
        duration: duration,
        easing: easing,
        useNativeDriver: true,
      });

      animationRef.current.start(({ finished }) => {
        if (finished && autoScrollEnabled.current) {
          setTimeout(() => {
            if (autoScrollEnabled.current) {
              startScrollingUp();
            }
          }, pauseAtEnds);
        }
      });
    }, delayBeforeStart);
  };

  const startScrollingUp = () => {
    if (!enabled || !scrollViewRef.current || !autoScrollEnabled.current) return;

    const currentPosition = scrollAnimatedValue.__getValue();
    const duration = getScrollDuration(currentPosition);

    if (animationRef.current) {
      animationRef.current.stop();
    }

    animationRef.current = Animated.timing(scrollAnimatedValue, {
      toValue: 0,
      duration: duration,
      easing: easing,
      useNativeDriver: true,
    });

    animationRef.current.start(({ finished }) => {
      if (finished && autoScrollEnabled.current) {
        setTimeout(() => {
          if (autoScrollEnabled.current) {
            startScrollingDown();
          }
        }, pauseAtEnds);
      }
    });
  };

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    autoScrollEnabled.current = enabled;

    if (!enabled && animationRef.current) {
      animationRef.current.stop();
      return;
    }

    if (
      enabled &&
      scrollViewHeight > 0 &&
      scrollContentHeight > 0 &&
      scrollContentHeight > scrollViewHeight
    ) {
      initialScrollRef.current = true;
      startScrollingDown();
    }

    return () => {
      if (animationRef.current) {
        animationRef.current.stop();
      }
    };
  }, [enabled, scrollViewHeight, scrollContentHeight]);

  const handleScrollViewLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    if (height !== scrollViewHeight) {
      setScrollViewHeight(height);
    }
  };

  const handleContentSizeChange = (width, height) => {
    if (height !== scrollContentHeight) {
      setScrollContentHeight(height);
    }
  };

  useEffect(() => {
    const id = scrollAnimatedValue.addListener(({ value }) => {
      if (scrollViewRef.current && scrollViewRef.current.scrollTo) {
        scrollViewRef.current.scrollTo({ y: value, animated: false });
      }
    });

    return () => {
      scrollAnimatedValue.removeListener(id);
      autoScrollEnabled.current = false;
    };
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      if (animationRef.current) {
        animationRef.current.stop();
      }
      scrollAnimatedValue.setValue(0);
      setScrollViewHeight(0);
      setScrollContentHeight(0);
      isFirstRenderRef.current = true;
      initialScrollRef.current = true;
    };

    const dimensionsHandler = Dimensions.addEventListener('change', updateDimensions);

    return () => {
      if (dimensionsHandler.remove) {
        dimensionsHandler.remove();
      }
    };
  }, []);

  return (
    <View style={[styles.container, style]} onLayout={handleScrollViewLayout}>
      <ScrollView
        ref={scrollViewRef}
        vertical={true}
        showsVerticalScrollIndicator={showIndicators}
        scrollEnabled={!enabled}
        onContentSizeChange={handleContentSizeChange}
        contentContainerStyle={[styles.scrollContent, contentContainerStyle]}
      >
        {children}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
  scrollContent: {
    // Default scroll content styling
  },
  scrollIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 40,
    zIndex: 10,
  },
});

export default AutoScrollWrapperVertical;
